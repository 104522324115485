import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import Loading from "components/Loading";
import BusinessesContextProvider from "contexts/BusinessesContextProvider";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import useBusinesses from "hooks/useBusinesses";

import BusinessApp from "./BusinessApp";

const BusinessesAppRoutes = () => {
	const { locale } = useApp();
	const { loading, businesses } = useBusinesses();
	const { accessToken, loggedOut } = useAuth();

	if (!accessToken) {
		const returnTo = loggedOut ? "" : encodeURIComponent(window.location.pathname);

		console.log("redirecting to", decodeURIComponent(returnTo), "- no access token");

		return <Navigate to={`/${encodeURIComponent(locale)}/login?redirect=${returnTo}`} />;
	}

	if (loading || !businesses || !businesses[0]) {
		return <Loading />;
	}

	const defaultSlug = encodeURIComponent(businesses[0]?.slug);
	const defaultPath = `/${encodeURIComponent(locale)}/${defaultSlug}`;

	return (
		<Routes>
			<Route path=":slug/*" element={<BusinessApp />} />
			<Route path="//*" element={<Navigate to={defaultPath} />} />
		</Routes>
	);
};

const BusinessesApp = () => {
	return (
		<BusinessesContextProvider>
			<BusinessesAppRoutes />
		</BusinessesContextProvider>
	);
};

export default BusinessesApp;
