import React, { useMemo, useState } from "react";

import {
	Box,
	Button,
	ClickAwayListener,
	Container,
	Fade,
	FormControlLabel,
	InputLabel,
	List,
	MenuItem as MaterialUIMenuItem,
	OutlinedInput,
	Popover,
	Radio,
	Select,
	Snackbar,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import GetPresignedUrl from "api/mutations/GetPresignedUrl";
import UpdateMenuItem from "api/mutations/UpdateMenuItem";
import TypicalOptionGroupsQuery from "api/queries/TypicalOptionGroupsQuery";
import { default as MenuItemType } from "api/types/MenuItem";
import { default as MenuItemOptionGroupType } from "api/types/MenuItemOptionGroup";
import useBusiness from "hooks/useBusiness";
import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";
import EditSmallIcon from "icons/EditSmallIcon";

import MenuItemOptionGroupConfig from "./MenuItemOptionGroupConfig";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			height: "100%",
			flexDirection: "column",
			flex: 1,
			margin: 0,
			padding: 0,
			textAlign: "center",
			color: "#4F4F4F",
			[theme.breakpoints.up(480)]: {
				width: "100%",
				maxWidth: "100%"
			},
			[theme.breakpoints.up(769)]: {
				width: "40%",
				maxWidth: "40%"
			}
		},
		drawerOpen: {
			height: "100%",
			overflowY: "scroll",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			"&::-webkit-scrollbar": {
				width: 0,
				height: 0
			},
			scrollbarWidth: "none",
			"-ms-overflow-style": "none"
		},
		options: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "start",
			textAlign: "left",
			alignItems: "left",
			padding: theme.spacing(2),
			paddingRight: theme.spacing(1)
		},
		optionRow: {
			marginBottom: theme.spacing(2)
		},
		title: {
			fontSize: 14,
			fontWeight: 700,
			textTransform: "uppercase",
			height: 28
		},
		flexOptionRow: {
			display: "flex",
			flexDirection: "row",
			flex: 1,
			justifyContent: "start",
			textAlign: "left",
			alignItems: "left",
			marginBottom: theme.spacing(2)
		},
		flexOptionColumn: {
			width: "40%"
		},
		nameInput: {
			borderRadius: 0,
			width: "60%"
		},
		shortInput: {
			borderRadius: 0,
			width: "70%"
		},
		descriptionInput: {
			borderRadius: 0,
			width: "85%",
			height: 80
		},
		imageBox: {
			width: 300,
			height: 300 * 0.75,
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			border: "1px solid #E0E0E0",
			background: "#F0F0F0"
		},
		image: {
			minWidth: "100%",
			maxWidth: "100%",
			height: "auto"
		},
		chooseImageButton: {
			textTransform: "capitalize",
			width: 166,
			height: 32,
			border: "1px solid #808080",
			boxSizing: "border-box",
			borderRadius: 0,
			marginTop: theme.spacing(1)
		},
		formControlLabel: {
			marginRight: theme.spacing(6),
			"& .MuiTypography-body1": {
				fontSize: 14
			},
			textTransform: "capitalize"
		},
		modifierSelect: {
			borderRadius: 0,
			height: 40,
			width: 220,
			fontSize: 14
		},
		modifierSelectOption: {
			fontSize: 14
		},
		addModifierButton: {
			height: 40,
			marginTop: -5,
			marginLeft: theme.spacing(1),
			background: "#A0A0A0",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		optionGroups: {
			width: "100%",
			marginTop: theme.spacing(2)
		},
		optionGroup: {
			display: "flex",
			marginLeft: theme.spacing(2),
			width: "80%",
			marginBottom: theme.spacing(2)
		},
		optionGroupName: {
			fontSize: 16,
			color: "#4F4F4F",
			textOverflow: "ellipsis"
		},
		optionGroupEditIconBg: {
			width: 20,
			height: 20,
			borderRadius: "50%",
			background: "#F2F2F2",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			cursor: "pointer",
			marginLeft: "auto"
		},
		createModifilerText: {
			cursor: "pointer",
			color: "#00B09A",
			fontSize: 16,
			fontWeight: 700,
			marginLeft: theme.spacing(2),
			textTransform: "uppercase"
		},
		popover: {
			"& .MuiPaper-elevation8": {
				boxShadow: "none"
			},
			"& .MuiPaper-rounded": {
				borderRadius: 0
			}
		},
		editBox: {
			width: 100,
			height: 65,
			border: "0.5px solid #4A4A4A",
			boxSizing: "border-box",
			overflow: "hidden"
		},
		editAction: {
			width: 100,
			height: 32,
			display: "flex",
			alignItems: "center",
			textAlign: "center",
			justifyContent: "center",
			fontSize: 14,
			fontWeight: 700,
			textTransform: "capitalize",
			"&:hover": {
				color: "#C0C0C0"
			},
			cursor: "pointer"
		},
		editActionBorderBottom: {
			borderBottom: "0.5px solid #C0C0C0"
		},
		actions: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexDirection: "row",
			marginTop: 56,
			marginRight: "15%"
		},
		cancelButton: {
			color: "#4C4C4C",
			borderRadius: 0,
			marginRight: 8,
			width: 100
		},
		saveButton: {
			background: "#00B09A",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		snackbar: {
			"& .MuiAlert-root": {
				marginTop: 26,
				marginRight: 0,
				borderRadius: 0
			}
		}
	};
});

export interface MenuItemProps {
	menuId: string;
	menuGroupId: string;
	menuItem: MenuItemType;
}

enum OptionGroupActionType {
	none = "NONE",
	add = "ADD",
	edit = "EDIT",
	delete = "DELETE"
}

interface OptionGroupAction {
	type: OptionGroupActionType;
	optionGroup: MenuItemOptionGroupType;
	optionGroupIndex?: number;
}

const MenuItemConfig: React.FC<MenuItemProps> = props => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { refetch: refetchBusiness, business } = useBusiness();
	const [menuItem, setMenuItem] = useState<MenuItemType>();
	const [optionGroups, setOptionGroups] = useState<MenuItemOptionGroupType[]>();
	const [getPresignedUrl] = useMutation(GetPresignedUrl);
	const [imageUploading, setImageUploading] = useState<boolean>();
	const [priceDoller, setPriceDoller] = useState<number | null>();
	const [optionGroupAction, setOptionGroupAction] = useState<OptionGroupAction>();
	const [anchorEl, setAnchorEl] = useState();
	const [typicalOptionGroups, setTypicalOptionGroups] = useState<MenuItemOptionGroupType[]>();
	const [selectedOptionGroupIndex, setSelectedOptionGroupIndex] = useState<number>();
	const [updateMenuItem] = useMutation(UpdateMenuItem);
	const [saving, setSaving] = useState<boolean>();
	const [error, setError] = useState<string>();

	useMemo(() => {
		setMenuItem({
			...props.menuItem
		});
		setOptionGroups([...props.menuItem.menuItemOptionGroups]);

		setPriceDoller(props.menuItem.priceCents ? props.menuItem.priceCents / 100.0 : undefined);
		setImageUploading(false);
	}, [props.menuItem]);

	const { data: typicalOptionGroupsData } = useQuery(TypicalOptionGroupsQuery, {
		variables: {
			queryTypicalOptionGroupsInput: {
				businessId: business?.id,
				menuId: props.menuId
			}
		}
	});

	useMemo(() => {
		if (typicalOptionGroupsData?.typicalOptionGroups) {
			setTypicalOptionGroups(typicalOptionGroupsData.typicalOptionGroups.optionGroups);
		}
	}, [typicalOptionGroupsData]);

	const tryGetPresignedUrl = async (key: string, fileName: string): Promise<string | null> => {
		if (!business) return null;
		const result = await getPresignedUrl({
			variables: {
				getPresignedUrlInput: {
					businessId: business.id,
					key,
					fileName
				}
			}
		});

		if (result.data && result.data.getPresignedUrl) return result.data.getPresignedUrl.url;

		return null;
	};

	const handleImageSelected = async event => {
		if (!menuItem) return;

		setImageUploading(true);
		const file = event.target.files[0];
		const presignedUrl = await tryGetPresignedUrl("menu", file.name);
		if (!presignedUrl) return;

		const response = await fetch(
			new Request(presignedUrl, {
				method: "PUT",
				body: file,
				headers: new Headers({
					"Content-Type": "image/*"
				})
			})
		);

		if (response.status === 200) {
			const url = response.url.split("?AWSAccessKeyId")[0];
			if (menuItem) {
				setMenuItem({
					...menuItem,
					imageUrl: url
				});
			}
		}
	};

	if (!menuItem || !business) return <></>;

	const setPrice = event => {
		setPriceDoller(event.target.value);
	};

	const setValueForItem = (key: string, value: any) => {
		if (!menuItem) return;
		setMenuItem({
			...menuItem,
			[key]: value
		});
	};

	const setOptionGroupActionAnchorEl = (event, optionGroup: MenuItemOptionGroupType, optionGroupIndex: number) => {
		setAnchorEl(event.target);

		setOptionGroupAction({
			type: OptionGroupActionType.none,
			optionGroup: {
				...optionGroup
			},
			optionGroupIndex
		});
	};

	const handleAddOptionGroup = () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const mongoid = require("mongoid-js");
		setOptionGroupAction({
			type: OptionGroupActionType.add,
			optionGroup: {
				__typename: "MenuItemOptionGroupType",
				id: mongoid(),
				name: "",
				description: null,
				localeNames: null,
				minCount: 0,
				maxCount: 0,
				menuItemOptions: []
			},
			optionGroupIndex: undefined
		});
	};

	const handleEditOptionGroup = () => {
		if (!optionGroupAction) return;

		setOptionGroupAction({
			...optionGroupAction,
			type: OptionGroupActionType.edit
		});

		setAnchorEl(undefined);
	};

	const handleDeleteOptionGroup = () => {
		if (!optionGroupAction) return;
		if (!optionGroups) return;

		if (optionGroupAction.optionGroupIndex === undefined) return;
		setOptionGroups([
			...optionGroups.slice(0, optionGroupAction.optionGroupIndex),
			...optionGroups.slice(optionGroupAction.optionGroupIndex + 1)
		]);

		dismissOptionGroupPopover();
	};

	const dismissOptionGroupPopover = () => {
		setOptionGroupAction(undefined);
		setAnchorEl(undefined);
	};

	const selectATypicalOptionGroup = event => {
		setSelectedOptionGroupIndex(event.target.value);
	};

	const disableSaveButton = (): boolean => {
		if (saving) return true;
		const name = menuItem.name;
		if (!name || name.trim().length <= 0) return true;
		return false;
	};

	const save = async () => {
		const newMenuItem: MenuItemType = {
			...menuItem,
			priceCents: priceDoller ? Math.floor(priceDoller * 100) : null,
			menuItemOptionGroups: optionGroups || []
		};

		setSaving(true);
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const omitDeep = require("omit-deep-lodash");
		await updateMenuItem({
			variables: {
				updateMenuItemInput: {
					businessId: business.id,
					menuId: props.menuId,
					menuGroupId: props.menuGroupId,
					menuItem: omitDeep(newMenuItem, ["__typename"])
				}
			}
		})
			.then(() => {
				const fetchBusiness = async () => {
					await refetchBusiness();
				};
				fetchBusiness();

				setSaving(false);
				setError(undefined);
			})
			.catch(e => {
				setError(t("unableToSaveSettings"));
				setSaving(false);
			});
	};

	const addTypicalOptionGroup = () => {
		if (selectedOptionGroupIndex === undefined || !typicalOptionGroups) return;
		const group: MenuItemOptionGroupType = typicalOptionGroups[selectedOptionGroupIndex];
		const options = group.menuItemOptions;
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const mongoid = require("mongoid-js");
		const newOptions = options?.map(option => {
			return {
				...option,
				id: mongoid(),
				priceCents: null,
				outOfStock: false
			};
		});
		const newGroup = {
			...group,
			id: mongoid(),
			menuItemOptions: newOptions
		};

		setOptionGroupAction({
			type: OptionGroupActionType.add,
			optionGroup: newGroup,
			optionGroupIndex: undefined
		});
	};

	const onOptionGroupSaved = (optionGroup: MenuItemOptionGroupType, optionGroupIndex: number | undefined) => {
		if (!optionGroupAction) return;

		switch (optionGroupAction.type) {
			case OptionGroupActionType.add:
				const newOptionGroups = optionGroups || [];
				setOptionGroups([...newOptionGroups, optionGroup]);
				break;
			case OptionGroupActionType.edit:
				if (optionGroupIndex !== undefined && optionGroups && optionGroups[optionGroupIndex]) {
					setOptionGroups([
						...optionGroups.slice(0, optionGroupIndex),
						optionGroup,
						...optionGroups.slice(optionGroupIndex + 1)
					]);
				}
				break;
		}

		setOptionGroupAction(undefined);
	};

	const menuItemOptionGroupsElement = () => {
		if (!optionGroups) return;

		const elements = optionGroups.map((optionGroup: MenuItemOptionGroupType, index: number) => {
			return (
				<Box key={optionGroup.id} className={classes.optionGroup}>
					<Typography className={classes.optionGroupName}>{optionGroup.name}</Typography>
					<Box
						className={classes.optionGroupEditIconBg}
						onClick={event => setOptionGroupActionAnchorEl(event, optionGroup, index)}
					>
						<EditSmallIcon />
					</Box>
				</Box>
			);
		});

		return (
			<Box>
				<List className={classes.optionGroups}>{elements}</List>
				<Typography className={classes.createModifilerText} onClick={handleAddOptionGroup}>
					{t("createModifier")}
				</Typography>
			</Box>
		);
	};

	const typicalOptionGroupsElement = () => {
		if (!typicalOptionGroups) return;

		const element = typicalOptionGroups.map((group, index) => {
			return (
				<MaterialUIMenuItem value={index} key={index} className={classes.modifierSelectOption}>
					{group.name}
				</MaterialUIMenuItem>
			);
		});

		return element;
	};

	return (
		<Container className={classes.root}>
			<Box className={classes.drawerOpen}>
				<Box className={classes.options} key={menuItem.id}>
					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("name")}</Typography>
						<OutlinedInput
							onChange={event => setValueForItem("name", event.target.value)}
							value={menuItem.name}
							className={classes.nameInput}
							autoFocus
						/>
					</Box>
					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("description")}</Typography>
						<OutlinedInput
							onChange={event => setValueForItem("description", event.target.value)}
							multiline={true}
							rows={3}
							value={menuItem.description}
							className={classes.descriptionInput}
						/>
					</Box>
					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("coverImage")}</Typography>
						<Box className={classes.imageBox} style={{ backgroundImage: `url("${menuItem.imageUrl}")` }} />

						<input
							accept="image/*"
							style={{ display: "none" }}
							id="choose-image-button-file"
							multiple
							type="file"
							onChange={async event => {
								await handleImageSelected(event);
								setImageUploading(false);
							}}
							disabled={imageUploading}
						/>
						<label htmlFor="choose-image-button-file">
							<Button component="span" className={classes.chooseImageButton} disabled={imageUploading}>
								{imageUploading && t("uploading...")}
								{!imageUploading && t("chooseImage")}
							</Button>
						</label>
					</Box>
					<Box className={classes.flexOptionRow}>
						<Box className={classes.flexOptionColumn}>
							<Typography className={classes.title}>{t("price$")}</Typography>
							<OutlinedInput onChange={setPrice} value={priceDoller} className={classes.shortInput} type="number" />
						</Box>
						{/* <Box className={classes.flexOptionColumn}>
							<Typography className={classes.title}>{t("tax%")}</Typography>
							<OutlinedInput inputProps={{ min: 1, max: 99 }} className={classes.shortInput} type="number" />
						</Box> */}
					</Box>

					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("outOfStock?")}</Typography>
						<Box>
							<FormControlLabel
								value="top"
								control={
									<Radio
										color="primary"
										onChange={() => setValueForItem("outOfStock", true)}
										checked={!!menuItem.outOfStock}
									/>
								}
								label={t("yes")}
								labelPlacement="start"
								className={classes.formControlLabel}
							/>
							<FormControlLabel
								value="top"
								control={
									<Radio
										color="primary"
										onChange={() => setValueForItem("outOfStock", false)}
										checked={!menuItem.outOfStock}
									/>
								}
								label={t("no")}
								labelPlacement="start"
								className={classes.formControlLabel}
							/>
						</Box>
					</Box>

					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("checkCustomerId?")}</Typography>
						<Box>
							<FormControlLabel
								value="top"
								control={
									<Radio
										color="primary"
										onChange={() => setValueForItem("requiresIDCheck", true)}
										checked={!!menuItem.requiresIDCheck}
									/>
								}
								label={t("yes")}
								labelPlacement="start"
								className={classes.formControlLabel}
							/>
							<FormControlLabel
								value="top"
								control={
									<Radio
										color="primary"
										onChange={() => setValueForItem("requiresIDCheck", false)}
										checked={!menuItem.requiresIDCheck}
									/>
								}
								label={t("no")}
								labelPlacement="start"
								className={classes.formControlLabel}
							/>
						</Box>
					</Box>

					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("enableSpecialInstructions?")}</Typography>
						<Box>
							<FormControlLabel
								value="top"
								control={
									<Radio
										color="primary"
										onChange={() => setValueForItem("disableSpecialInstructions", false)}
										checked={!menuItem.disableSpecialInstructions}
									/>
								}
								label={t("yes")}
								labelPlacement="start"
								className={classes.formControlLabel}
							/>
							<FormControlLabel
								value="top"
								control={
									<Radio
										color="primary"
										onChange={() => setValueForItem("disableSpecialInstructions", true)}
										checked={!!menuItem.disableSpecialInstructions}
									/>
								}
								label={t("no")}
								labelPlacement="start"
								className={classes.formControlLabel}
							/>
						</Box>
					</Box>

					<Box className={classes.optionRow}>
						<Typography className={classes.title}>{t("modifiers")}</Typography>
						<Box>
							<InputLabel shrink id="select-placeholder-label">
								{t("selectAModifier")}
							</InputLabel>
							<Select
								className={classes.modifierSelect}
								onChange={selectATypicalOptionGroup}
								labelId="select-placeholder-label"
								variant="outlined"
							>
								{typicalOptionGroupsElement()}
							</Select>
							<Button
								className={classes.addModifierButton}
								disabled={selectedOptionGroupIndex === undefined}
								onClick={addTypicalOptionGroup}
							>
								{t("add")}
							</Button>
						</Box>
						<Box>{menuItemOptionGroupsElement}</Box>
					</Box>

					<Box className={classes.actions}>
						<Button className={classes.saveButton} disabled={disableSaveButton()} onClick={async () => await save()}>
							{saving && t("saving...")}
							{!saving && t("save")}
						</Button>
					</Box>

					<Snackbar
						className={classes.snackbar}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						open={!!error}
						autoHideDuration={6000}
						onClose={() => setError(undefined)}
					>
						<Alert onClose={() => setError(undefined)} severity="error">
							{error}
						</Alert>
					</Snackbar>
				</Box>

				{optionGroupAction && optionGroupAction.type !== OptionGroupActionType.none && (
					<MenuItemOptionGroupConfig
						menuItemOptionGroup={optionGroupAction.optionGroup}
						optionGroupIndex={optionGroupAction.optionGroupIndex}
						onSaved={onOptionGroupSaved}
					/>
				)}
			</Box>

			<Popover
				className={classes.popover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				anchorEl={anchorEl}
				open={!!anchorEl}
			>
				<ClickAwayListener onClickAway={dismissOptionGroupPopover}>
					<Fade in={!!anchorEl}>
						<Box className={classes.editBox}>
							<Typography
								className={`${classes.editAction} ${classes.editActionBorderBottom}`}
								onClick={handleEditOptionGroup}
							>
								{t("edit")}
							</Typography>
							<Typography className={classes.editAction} onClick={handleDeleteOptionGroup}>
								{t("delete")}
							</Typography>
						</Box>
					</Fade>
				</ClickAwayListener>
			</Popover>
		</Container>
	);
};

export default MenuItemConfig;
