import React from "react";

import ReactHowler from "react-howler";

import config from "config";

const NotificationSound = () => {
	// if (playNotification) {
	// 	play();
	// 	setIsPlaying(true);
	// } else {
	// 	if (isPlaying) {
	// 		stop();
	// 		setPlayNotification(false);
	// 		setIsPlaying(false);
	// 	}
	// }
	return <ReactHowler src={config.notificationSound} loop={true} playing={true} preload={true} autoplay={true} />;
};

export default NotificationSound;
