import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationMoveDownMenuItemInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation MoveDownMenuItem($moveDownMenuItemInput: MutationMoveDownMenuItemInput!) {
	moveDownMenuItem(moveDownMenuItemInput: $moveDownMenuItemInput) {
        success
    }
}
`;

const MoveDownMenuItem: IOperation<MutationUpdateMenusResult, MutationMoveDownMenuItemInput> = { text } as any;

export default MoveDownMenuItem;