import React from "react";

import { Typography } from "@material-ui/core";

import { formatMoney } from "utility/formatMoney";

interface PriceProps {
	priceCents: null | number;
	displayPrice?: undefined | null | string;
	className?: string;
	prefix?: string;
	suffix?: string;
}

const Price: React.FC<PriceProps> = ({ priceCents, displayPrice, className, prefix, suffix }) => {
	if (displayPrice) {
		return <Typography className={className}>{displayPrice}</Typography>;
	} else {
		return (
			<Typography className={className}>
				{(prefix === null || prefix === undefined ? "" : prefix + " ") +
					formatMoney(priceCents!) +
					(suffix === null || suffix === undefined ? "" : " " + suffix)}
			</Typography>
		);
	}
};

export default Price;
