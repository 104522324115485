import React from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

interface PageTitleProps {
	pageTitle: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ pageTitle }) => {
	const { t } = useTranslation();
	return (
		<Helmet>
			<title>{`${t("hyperflyer")} | ${pageTitle}`}</title>
		</Helmet>
	);
};

export default PageTitle;
