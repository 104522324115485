import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { BusinessQuery as BusinessQueryType, BusinessQueryVariables } from "gql/types/queries/BusinessQuery";

const text = gql`query BusinessQuery($businessInput: QueryBusinessInput!) {
	business(businessInput: $businessInput) {
		id
		slug
		name
		localeNames
		locales
		displayBusinessHours
		taxRate
		websiteUrl
		imageUrl
		logoUrl
		pause

		address {
			address1
			address2
			address3
			city
			state
			postalCode
			country
			coordinates {
				latitude
				longitude
			}
		}

		categories {
			id
			name
			localeNames
		}

		orderTypes {
			id
			orderType
			name
			localeNames
			description
			freeAfterOrderTotalCents
			priceCents
		}

		menus {
			id
			slug
			name
			description
			localeNames
			imageUrl
			localeNames
			activateAt
			deactivateAt

			menuGroups {
				id
				slug
				name
				description
				displayOrder
				localeNames
				activateAt
				deactivateAt
				availability {
					hours {
						daysOfWeek
						intervals {
							startTime
							endTime
						}
					}
					exceptions {
						startsAt
						endsAt
					}
				}
				menuItems {
					id
					itemCode
					name
					description
					localeNames
					priceCents
					displayPrice
					outOfStock
					imageUrl
					iconUrl
					preparationTimeMinutes
					activateAt
					deactivateAt
					disableSpecialInstructions
					requiresIDCheck

					menuItemOptionGroups {
						id
						slug
						name
						localeNames
						description
						displayOrder
						minCount
						maxCount
						activateAt
						deactivateAt
						menuItemOptions {
							id
							itemCode
							name
							description
							localeNames
							iconUrl
							imageUrl
							priceCents
							displayPrice
							preparationTimeMinutes
							outOfStock
						}
					}
				}
			}
		}
	}
}
`;

const BusinessQuery: IOperation<BusinessQueryType, BusinessQueryVariables> = { text } as any;

export default BusinessQuery;
