import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationMoveDownMenuGroupInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation MoveDownMenuGroup($moveDownMenuGroupInput: MutationMoveDownMenuGroupInput!) {
	moveDownMenuGroup(moveDownMenuGroupInput: $moveDownMenuGroupInput) {
        success
    }
}
`;

const MoveDownMenuGroup: IOperation<MutationUpdateMenusResult, MutationMoveDownMenuGroupInput> = { text } as any;

export default MoveDownMenuGroup;