import React from "react";

import { Badge, Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import * as timeago from "timeago.js";

import { OrderStatuses } from "gql/types/globals";
import { BusinessOrdersQuery_business_orders } from "gql/types/queries/BusinessOrdersQuery";

const useStyles = makeStyles(theme => {
	return {
		root: {
			position: "relative",
			borderBottom: "1px solid #888",
			padding: 0,
			margin: 0,
			[theme.breakpoints.up(1280)]: {
				maxWidth: "initial"
			}
		},
		orderDetails: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "center",
			paddingLeft: theme.spacing(2),
			paddingTop: theme.spacing(2),
			paddingRight: theme.spacing(7),
			paddingBottom: theme.spacing(2),
			cursor: "pointer"
		},
		orderDetailsNew: {
			background: "#F2F2F2"
		},
		orderDetailsSelected: {
			background: "#ededed"
		},
		orderDetailsInfo: {
			display: "flex",
			flexDirection: "row",
			flex: 1
		},
		orderDeatilsButton: {
			position: "absolute",
			top: "50%",
			right: theme.spacing(0),
			marginTop: -theme.spacing(2)
		},
		orderDeatilsIcon: {
			width: theme.spacing(4),
			height: theme.spacing(4)
		},
		link: {
			color: theme.palette.primary.main
		},
		orderHeader: {
			flexDirection: "row",
			justifyContent: "space-between",
			display: "flex"
		},
		orderBadge: {
			width: "100px",
			background: "#C4C4C4",
			textAlign: "center",
			textTransform: "uppercase",
			display: "block",
			color: "#fff",
			fontWeight: "bold",
			height: "24px",
			fontSize: "14px",
			lineHeight: "13px",
			paddingTop: "5px",
			alignItems: "center",
			borderRadius: "8px",
			textAalign: "center"
		},
		late: {
			background: "#e46565"
		},
		name: {
			textTransform: "uppercase",
			fontWeight: "bold",
			fontSize: "18px",
			lineHeight: "17px",
			paddingBottom: theme.spacing(3)
		},
		icon: {
			position: "absolute",
			top: theme.spacing(4),
			left: theme.spacing(0),
			width: theme.spacing(1),
			height: theme.spacing(1)
		},
		text: {
			textTransform: "uppercase",
			paddingRight: theme.spacing(0.5),
			fontSize: "16px"
		},
		textStrong: {
			textTransform: "uppercase",
			fontWeight: 500,
			paddingRight: theme.spacing(0.5),
			fontSize: "16px"
		},
		textSmall: {
			fontSize: "12px",
			paddingRight: theme.spacing(0.5),
			width: "96px"
		},
		textSmallStrong: {
			fontSize: "12px",
			fontWeight: 500,
			paddingRight: theme.spacing(0.5)
		},
		textDivider: {
			fontSize: "16px",
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(1)
		},
		textSmallDivider: {
			fontSize: "12px",
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(1)
		},
		orderPlaced: {
			textTransform: "uppercase",
			paddingTop: theme.spacing(1),
			display: "flex",
			flex: 1
		},
		orderStatus: {
			textTransform: "uppercase",
			paddingTop: theme.spacing(1),
			fontSize: theme.spacing(2)
		}
	};
});

const OrderListItemTablet = ({ order }: { order: BusinessOrdersQuery_business_orders }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { hash } = useLocation();
	const navigate = useNavigate();

	const openOrder = orderId => {
		navigate(`#orderid:${orderId}`);
	};

	const currentOrderId = hash.substr("#orderid:".length);

	const totalItemsCount = order.orderItems.reduce((a, b) => a + b.quantity, 0);
	const deliveryOrPickupAt = DateTime.fromISO(order.deliveryOrPickupAt);

	const diffDays = parseInt(Math.abs(deliveryOrPickupAt.diffNow("days").days).toFixed());
	const diffHours = parseInt(Math.abs(deliveryOrPickupAt.diffNow("hours").hours).toFixed());
	const diffMins = parseInt(Math.abs(deliveryOrPickupAt.diffNow("minutes").minutes).toFixed());
	const due =
		diffDays !== 0
			? t("day", { count: diffDays })
			: diffHours !== 0
			? t("hour", { count: diffHours })
			: diffMins !== 0
			? t("minute", { count: diffMins })
			: t("now");
	const timeDiff = deliveryOrPickupAt.diffNow("minutes").toObject().minutes;
	const isScheduled =
		deliveryOrPickupAt.startOf("day") > DateTime.local().startOf("day") && order.orderStatus === OrderStatuses.placed;
	const isLate =
		timeDiff < 0 && (order.orderStatus === OrderStatuses.placed || order.orderStatus === OrderStatuses.confirmed);
	const isDue = timeDiff > 0 && order.orderStatus === OrderStatuses.confirmed;
	const isReady = order.orderStatus === OrderStatuses.ready;

	return (
		<Container className={classes.root} onClick={() => openOrder(order.id)}>
			<Box
				className={clsx(
					classes.orderDetails,
					order.orderStatus === OrderStatuses.placed ? classes.orderDetailsNew : "",
					currentOrderId === order.id ? classes.orderDetailsSelected : ""
				)}
			>
				<Box className={classes.orderHeader}>
					<Typography variant={"h3"} className={classes.name}>
						{order.customer?.fullName}
					</Typography>
					{isScheduled && <Badge className={classes.orderBadge}>{t("scheduled")}</Badge>}
					{isLate && <Badge className={clsx(classes.orderBadge, classes.late)}>{t("late")}</Badge>}
					{isReady && <Badge className={classes.orderBadge}>{t("ready")}</Badge>}
				</Box>

				<Box className={classes.orderDetailsInfo}>
					<Typography className={classes.textStrong}>{t("item", { count: totalItemsCount })}</Typography>
					<Typography className={classes.textDivider}>•</Typography>
					{order.orderStatus === OrderStatuses.placed && (
						<Typography className={classes.textStrong}>
							{t("ordered")} {timeago.format(order.placedAt)} {/*DateTime.fromISO(order.placedAt).toFormat("t")*/}
						</Typography>
					)}
					{isDue && (
						<Typography className={classes.textStrong}>
							{t("dueIn")} {due}
						</Typography>
					)}
					{isLate && (
						<Typography className={classes.textStrong}>
							{due} {t("late")}{" "}
						</Typography>
					)}
				</Box>
				{/* <Box className={classes.orderPlaced}>
					<Typography className={classes.textSmall}>{t("orderPlaced")} </Typography>
					<Typography className={classes.textSmallStrong}>
						{DateTime.fromISO(order.placedAt).toFormat("LLL dd")}
					</Typography>
					<Typography className={classes.textSmallDivider}>•</Typography>
					<Typography className={classes.textSmallStrong}>{DateTime.fromISO(order.placedAt).toFormat("t")}</Typography>
				</Box>
				{order.deliveryOrPickupAt && (
					<Box className={classes.orderPlaced}>
						<Typography className={classes.textSmall}>{t("readyFor")} </Typography>
						<Typography className={classes.textSmallStrong}>
							{DateTime.fromISO(order.deliveryOrPickupAt).toFormat("LLL dd")}
						</Typography>
						<Typography className={classes.textSmallDivider}>•</Typography>
						<Typography className={classes.textSmallStrong}>
							{DateTime.fromISO(order.deliveryOrPickupAt).toFormat("t")}
						</Typography>
					</Box>
				)}
				<Typography className={classes.orderStatus} variant="h4">
					{t(order.orderStatus)}
				</Typography> */}
			</Box>
			{/* <IconButton onClick={() => openOrder(order.id)} className={classes.orderDeatilsButton}>
				<OpenDetailsIcon className={classes.orderDeatilsIcon} />
			</IconButton> */}
		</Container>
	);
};

export default OrderListItemTablet;
