import i18n from "i18next";

const validate = values => {
	const errors: any = {};
	if (!values.userName) {
		errors.userName = i18n.t("required");
	}
	return errors;
};

export default validate;
