import React from "react";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import CollapseIcon from "icons/CollapseIcon";
import ExpandIcon from "icons/ExpandIcon";

const useStyles = makeStyles(theme => {
	return {
		root: {},
		paper: {},
		header: {
			display: "flex",
			flex: 1,
			position: "relative",
			cursor: "pointer"
		},
		collapseIcon: {
			alignSelf: "flex-end",
			width: "12px",
			height: "10.5px",
			position: "absolute",
			right: theme.spacing(1)
		},
		expandIcon: {
			alignSelf: "flex-end",
			width: "12px",
			height: "10.5px",
			top: "5px",
			position: "absolute",
			right: theme.spacing(1)
		}
	};
});

interface CollapsiblePanelProps {
	header: string | JSX.Element;
	body: JSX.Element;
	className?: string;
	headerClassName?: string;
	bodyClassName?: string;
	collapsed?: boolean;
}

const CollapsiblePanel = ({
	header,
	body,
	className,
	headerClassName,
	bodyClassName,
	collapsed
}: CollapsiblePanelProps) => {
	const classes = useStyles();

	const [isCollapsed, setCollapsed] = React.useState(!!collapsed);

	return (
		<Box className={clsx(className, classes.root)}>
			<Box className={clsx(headerClassName, classes.header)} onClick={() => setCollapsed(!isCollapsed)}>
				{header}
				{!isCollapsed && <CollapseIcon className={classes.collapseIcon} />}
				{isCollapsed && <ExpandIcon className={classes.expandIcon} />}
			</Box>
			{!isCollapsed && <Box className={clsx(bodyClassName, classes.paper)}>{body}</Box>}
		</Box>
	);
};

export default CollapsiblePanel;
