import React from "react";

import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import PageTitle from "components/PageTitle";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			height: "100vh",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(10),
			[theme.breakpoints.up(480)]: {
				left: "auto",
				width: "440px"
			}
		},
		titleContainer: {
			margin: theme.spacing(4)
		},
		title: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px"
		}
	};
});

const NoOrderDetailScreen = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Container className={classes.root}>
			<PageTitle pageTitle={t("orderDetail")} />
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>{t("noOrderSelected")}</Typography>
			</Box>
		</Container>
	);
};

export default NoOrderDetailScreen;
