import React from "react";

import { Helmet } from "react-helmet";

import AuthContextProvider from "contexts/AuthContextProvider";

import useTracking from "hooks/useTracking";

import AppRoutes from "./AppRoutes";

import "styles/App.css";

const App = () => {
	const { trackPageEvent } = useTracking();

	const onChangeClientState = ({ title }) => {
		trackPageEvent(title);
	};
	return (
		<AuthContextProvider>
			<Helmet onChangeClientState={onChangeClientState} />

			<AppRoutes />
		</AuthContextProvider>
	);
};

export default App;
