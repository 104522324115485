import React, { useMemo } from "react";

import {
	Box,
	Button,
	Container,
	Fab,
	Fade,
	List,
	ListItem,
	Menu,
	MenuItem,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import CancelOrder from "api/mutations/CancelOrder";
import CompleteOrder from "api/mutations/CompleteOrder";
import ConfirmOrder from "api/mutations/ConfirmOrder";
import UpdateOrderReady from "api/mutations/UpdateOrderReady";
import OrderQuery from "api/queries/OrderQuery";
import CollapsiblePanel from "components/CollapsiblePanel";
import Loading from "components/Loading";
import OrderItemListItem from "components/OrderItemListItem";
import PageTitle from "components/PageTitle";
import Price from "components/Price";
import { AvailableOrderTypes, OrderStatuses } from "gql/types/globals";

import useApp from "hooks/useApp";
import useBusiness from "hooks/useBusiness";
import useMutation from "hooks/useMutation";
import useQuery from "hooks/useQuery";
import BackIcon from "icons/BackIcon";
import CarIcon from "icons/CarIcon";
import ConfirmIcon from "icons/ConfirmIcon";
import MenuIcon from "icons/MenuIcon";
import OrderIcon from "icons/OrderIcon";
import UserIcon from "icons/UserIcon";

const fontSize = 14;

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			height: "100vh",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			paddingBottom: theme.spacing(10),
			[theme.breakpoints.up(480)]: {
				left: "auto",
				padding: theme.spacing(4),
				paddingTop: theme.spacing(6),
				paddingBottom: theme.spacing(14)
			},
			[theme.breakpoints.up(1280)]: {
				maxWidth: "initial"
			}
		},
		link: {
			color: theme.palette.primary.main
		},
		items: {
			listStyle: "none"
		},
		item: {
			marginTop: theme.spacing(1)
		},
		totals: {
			marginTop: theme.spacing(1),
			listStyle: "none",
			borderTop: "1px solid #bdbdbd"
		},
		totalsItem: {
			margin: `${theme.spacing(1)}px 0`,
			padding: 0,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between"
		},
		totalsLabel: {
			fontSize,
			minWidth: 0,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			textTransform: "uppercase",
			color: "#424242"
		},
		totalsPrice: {
			fontSize,
			color: "#424242"
		},
		totalsTotalItem: {
			margin: `${theme.spacing(2)}px 0`,
			padding: 0,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between"
		},
		totalsTotalLabel: {
			fontSize: "16px",
			fontWeight: "bold",
			textTransform: "uppercase"
		},
		totalsTotalPrice: {
			fontSize: "16px",
			fontWeight: "bold"
		},
		summary: {
			marginTop: theme.spacing(1),
			listStyle: "none",
			borderTop: "1px solid #bdbdbd"
		},
		summaryItem: {
			margin: 0,
			padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
			display: "flex",
			flexDirection: "row",
			alignItems: "baseline",
			justifyContent: "space-between"
		},
		summaryLabel: {
			fontSize,
			minWidth: 0,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			textTransform: "uppercase"
		},
		summaryValue: {
			fontSize,
			minWidth: 0,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			textTransform: "uppercase",
			textAlign: "right"
		},
		backButtonContainer: {
			display: "none",
			[theme.breakpoints.down(769)]: {
				display: "initial"
			}
		},
		backButton: {
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
			padding: 0
		},
		backButtonTitle: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px",
			marginLeft: theme.spacing(2)
		},
		backButtonIcon: {
			width: theme.spacing(4),
			height: theme.spacing(4)
		},
		orderDetailsContainer: {
			paddingBottom: theme.spacing(2),
			borderBottom: "0.5px solid #828282"
		},
		orderDetailsRow: {
			display: "flex",
			flexDirection: "row"
		},
		colLeft: {
			maxWidth: "50%",
			minWidth: "50%",
			alignContent: "left",
			textAlign: "left"
		},
		colRight: {
			maxWidth: "50%",
			minWidth: "50%",
			textAlign: "right"
		},
		orderNumber: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px",
			fontWeight: 600
		},
		orderStatus: {
			textTransform: "uppercase",
			paddingTop: theme.spacing(1),
			fontSize: theme.spacing(2),
			fontWeight: 500
		},
		orderDetails: {
			display: "flex",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			[theme.breakpoints.up(769)]: {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2)
			}
		},
		orderDetailsText: {
			display: "flex",
			marginBottom: theme.spacing(1)
		},
		orderDeliveryAddr: {
			display: "flex",
			flexDirection: "column",
			marginBottom: theme.spacing(1)
		},
		pickupTime: {
			flexDirection: "column",
			paddingLeft: "50%",
			textAlign: "left"
		},
		orderBlockContainer: {
			paddingBottom: theme.spacing(2),
			borderBottom: "0.5px solid #828282",
			marginTop: theme.spacing(2)
		},
		noBorder: {
			borderBottom: "0"
		},
		orderBlockHeader: {
			display: "flex",
			flex: 1,
			position: "relative"
		},
		orderBlockTitle: {
			lineHeight: "14px",
			fontSize: "12px",
			textTransform: "uppercase",
			marginLeft: theme.spacing(1),
			[theme.breakpoints.up(769)]: {
				fontWeight: "bold",
				fontSize: "16px",
				lineHeight: "15px"
			}
		},
		icon: {
			fill: "#4F4F4F",
			height: "14px"
		},
		orderBlockContent: {},
		text: {
			fontSize: "12px",
			textTransform: "uppercase",
			paddingRight: theme.spacing(0.5),
			[theme.breakpoints.up(769)]: {
				fontSize: "14px",
				lineHeight: "15px"
			}
		},
		textStrong: {
			fontSize: "12px",
			fontWeight: 500,
			textTransform: "uppercase",
			paddingRight: theme.spacing(0.5),
			[theme.breakpoints.up(769)]: {
				fontWeight: "bold",
				fontSize: "16px",
				lineHeight: "15px"
			}
		},
		textDivider: {
			fontSize: "12px",
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(1)
		},
		actionButtonsContainer: {
			minHeight: theme.spacing(12),
			position: "fixed",
			bottom: theme.spacing(6.5),
			left: 0,
			right: 0,
			[theme.breakpoints.up(480)]: {
				position: "absolute"
			}
		},
		completeActionsButtonsContainer: {
			right: "25%",
			marginRight: "-160px",
			minHeight: theme.spacing(12),
			textAlign: "center",
			position: "fixed",
			left: 0,
			width: "100%",
			bottom: theme.spacing(6.5),
			[theme.breakpoints.up(480)]: {
				position: "fixed",
				right: theme.spacing(6),
				left: "auto",
				width: "400px",
				marginRight: 0
			}
		},
		completeRejectButton: {
			height: theme.spacing(6),
			background: "#F2F2F2",
			borderRadius: theme.spacing(3),
			padding: theme.spacing(1.5),
			margin: theme.spacing(2)
		},
		completeRejectButtonTitle: {
			color: "#828282",
			fontSize: "18px",
			lineHeight: "22px",
			textTransform: "uppercase",
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4)
		},
		completeButton: {
			height: theme.spacing(8),
			background: "#333333",
			borderRadius: theme.spacing(9),
			margin: theme.spacing(2),
			"&:hover": {
				background: "#333333"
			}
		},
		completeButtonTitle: {
			color: "#fff",
			fontSize: "18px",
			lineHeight: "22px",
			textTransform: "uppercase",
			paddingLeft: theme.spacing(8),
			paddingRight: theme.spacing(4)
		},
		confirmButton: {
			position: "fixed",
			right: theme.spacing(6),
			width: theme.spacing(12),
			height: theme.spacing(12),
			background: "transparent",
			boxShadow: "none",
			"&:hover": {
				background: "#9e9e9e"
			}
		},
		confirmButtonIcon: {
			width: theme.spacing(8),
			height: theme.spacing(8)
		},
		completeConfirmButtonIcon: {
			"& path": {
				stroke: "#00e676"
			}
		},
		readyConfirmButtonIcon: {
			"& path": {
				stroke: "#ffea00"
			}
		},
		cancelButton: {
			position: "absolute",
			left: theme.spacing(6),
			width: theme.spacing(12),
			height: theme.spacing(12),
			background: "transparent",
			boxShadow: "none"
		},
		menuButton: {
			background: "transparent",
			border: "0",
			boxShadow: "none"
		},
		menuIcon: {
			width: "6px"
		},
		menuItem: {
			fontSize: "18px",
			fontWeight: "bold",
			lineHeight: "22px",
			textTransform: "uppercase",
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4)
		},
		cancelButtonIcon: {
			width: theme.spacing(8),
			height: theme.spacing(8)
		},
		actionButtonsPlaceholder: {
			minHeight: theme.spacing(16)
		},
		modal: {
			position: "absolute",
			width: 420,
			height: 300,
			backgroundColor: "rgba(51, 51, 51, 0.9)",
			padding: theme.spacing(2, 4, 3),
			color: "#F2F2F2",
			borderRadius: theme.spacing(1),
			outline: "none",
			top: "300px",
			left: "50%",
			marginLeft: "-210px",
			textAlign: "center"
		},
		modalBody: {
			textAlign: "center"
		},
		modalHeader: {
			textTransform: "uppercase",
			fontSize: "28px",
			lineHeight: "28px",
			fontWeight: 600,
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3)
		},
		modalCustomerName: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "17px",
			fontWeight: "normal",
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3)
		},
		modalDetails: {
			textTransform: "uppercase",
			fontSize: "16px",
			lineHeight: "17px",
			fontWeight: "bold",
			marginBottom: theme.spacing(3),
			justifyContent: "center"
		}
	};
});

interface Props {
	onClose?: () => void;
	onUpdate?: () => Promise<void>;
	orderId: string;
}

const OrderDetailScreen = ({ onClose, onUpdate, orderId }: Props) => {
	const { locale } = useApp();
	const { business } = useBusiness();
	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data, loading, refetch } = useQuery(OrderQuery, {
		variables: {
			orderInput: {
				orderId
			}
		}
	});

	const [isConfirming, setConfirming] = React.useState(false);
	const [isCancelling, setCancelling] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorEl);

	useMemo(() => {
		setConfirming(false);
	}, [data?.order]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [cancelOrder] = useMutation(CancelOrder);
	const [confirmOrder] = useMutation(ConfirmOrder);
	const [completeOrder] = useMutation(CompleteOrder);

	const [updateOrderReady] = useMutation(UpdateOrderReady);

	const onCancelAction = () => {
		if (isConfirming) setConfirming(false);
		if (isCancelling) setCancelling(false);
	};

	const update = async () => {
		if (onUpdate) {
			await onUpdate();
			await refetch();
		}
	};

	const onCancelClicked = () => {
		cancelOrder({
			variables: {
				cancelOrderInput: {
					orderId,
					cancellationReason: "Because"
				}
			}
		}).then(() => {
			update();
		});
	};

	const onConfirmClicked = () => {
		confirmOrder({
			variables: {
				confirmOrderInput: {
					id: orderId,
					newPreparationTimeMinutes: 0
				}
			}
		}).then(() => {
			update();
		});
	};

	const onReadyClicked = () => {
		updateOrderReady({
			variables: {
				updateOrderReadyInput: {
					id: orderId
				}
			}
		}).then(() =>
			onUpdate
				? update()
				: navigate(`/${encodeURIComponent(locale)}/${encodeURIComponent(business?.slug || "")}/orders`)
		);
	};

	const onCompleteClicked = () => {
		completeOrder({
			variables: {
				updateOrderCompletedInput: {
					id: orderId
				}
			}
		}).then(() =>
			onUpdate
				? update()
				: navigate(`/${encodeURIComponent(locale)}/${encodeURIComponent(business?.slug || "")}/orders`)
		);
	};

	// TODO: should be another mutation
	const onCancelAndRefundClicked = () => {
		cancelOrder({
			variables: {
				cancelOrderInput: {
					orderId,
					cancellationReason: "Because"
				}
			}
		});
	};

	if (loading) {
		return <Loading />;
	}
	if (data?.order) {
		const { order } = data;

		const totalItemsCount = order.orderItems.reduce((a, b) => a + b.quantity, 0);

		return (
			<Container className={classes.root} onClick={onCancelAction}>
				<PageTitle pageTitle={t("orderDetail")} />

				<Box className={classes.backButtonContainer}>
					<Button onClick={onClose} className={classes.backButton}>
						<BackIcon className={classes.backButtonIcon} />
						<Typography className={classes.backButtonTitle}>{t("backToOrdersList")}</Typography>
					</Button>
				</Box>

				<Box className={classes.orderDetailsContainer} onClick={onCancelAction}>
					<Box className={classes.orderDetailsRow}>
						<Box className={classes.colLeft}>
							<Box className={classes.orderDetails}>
								<Typography className={classes.orderNumber} variant="h4">
									{t("orderNumber")}: {order.orderNumber}
								</Typography>
							</Box>
						</Box>
						<Box className={classes.colRight}>
							{(order.orderStatus === OrderStatuses.placed || order.orderStatus === OrderStatuses.confirmed) && (
								<>
									<Fab className={classes.menuButton} onClick={handleClick}>
										<MenuIcon className={classes.menuIcon} />
									</Fab>
									<Menu
										id="fade-menu"
										anchorEl={anchorEl}
										keepMounted
										open={menuOpen}
										onClose={handleClose}
										TransitionComponent={Fade}
									>
										<MenuItem onClick={() => setCancelling(true)} className={classes.menuItem}>
											{t("rejectOrder")}
										</MenuItem>
									</Menu>
								</>
							)}
						</Box>
					</Box>
					<Box className={classes.orderDetailsRow}>
						<Box className={classes.colLeft}>
							<Box className={classes.orderDetailsText}>
								<Typography className={classes.text}>{t("orderPlaced")} </Typography>
								<Typography className={classes.textDivider}>•</Typography>
								<Typography className={classes.textStrong}>{DateTime.fromISO(order.placedAt).toFormat("t")}</Typography>
							</Box>
							<Box className={classes.orderDetailsText}>
								<Typography className={classes.text}>{t("orderType")} </Typography>
								<Typography className={classes.textDivider}>•</Typography>
								<Typography className={classes.textStrong}>
									{order.orderType ? order.orderType.orderType : "-"}
								</Typography>
							</Box>
							<Box className={classes.orderDetailsText}>
								<Typography className={classes.text}>{t("orderStatus")} </Typography>
								<Typography className={classes.textDivider}>•</Typography>
								<Typography className={classes.textStrong}>{t(order.orderStatus)}</Typography>
							</Box>
						</Box>
						<Box className={classes.colRight}>
							{order.deliveryOrPickupAt && (
								<Box className={classes.pickupTime}>
									<Box className={classes.orderDetailsText}>
										<Typography className={classes.text}>
											{order.orderType.orderType === AvailableOrderTypes.pickup ? t("pickupTime") : t("deliverBy")}
										</Typography>
									</Box>
									<Box className={classes.orderDetailsText}>
										<Typography className={classes.textStrong}>
											{DateTime.fromISO(order.deliveryOrPickupAt).toFormat("LLL dd")}{" "}
											{DateTime.fromISO(order.deliveryOrPickupAt).toFormat("t")}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				</Box>

				<CollapsiblePanel
					className={classes.orderBlockContainer}
					header={
						<>
							<UserIcon className={classes.icon} />
							<Typography className={classes.orderBlockTitle}>{t("customerInformation")}</Typography>
						</>
					}
					body={
						<>
							<Box className={classes.orderDetails}>
								<Typography className={classes.text}>{order.customer?.fullName} </Typography>
							</Box>
							<Box className={classes.orderDetails}>
								<Typography className={classes.text}>{order.displayPhoneNumber} </Typography>
							</Box>
						</>
					}
				/>

				<CollapsiblePanel
					className={clsx(classes.orderBlockContainer, classes.noBorder)}
					header={
						<>
							<OrderIcon className={classes.icon} />
							<Typography className={classes.orderBlockTitle}>{t("order")}</Typography>
						</>
					}
					body={
						<>
							<List className={classes.items}>
								{order.orderItems.map(orderItem => (
									<OrderItemListItem key={orderItem.id} className={classes.item} item={orderItem} variant="light" />
								))}
								<ListItem className={classes.totalsItem}>
									<Typography className={classes.totalsLabel}>{t("foodBeverageTotal")}</Typography>
									<Price className={classes.totalsPrice} priceCents={order!.orderSubTotalCents} />
								</ListItem>
								{!!order?.discount && (
									<ListItem className={classes.totalsItem}>
										<Typography className={classes.totalsLabel}>{t("discount")}</Typography>
										<Price className={classes.totalsPrice} priceCents={order.discount} prefix={"-"} />
									</ListItem>
								)}
								{order!.orderType.orderType === AvailableOrderTypes.delivery && (
									<ListItem className={classes.totalsItem}>
										<Typography className={classes.totalsLabel}>{t("deliveryFee")}</Typography>
										<Price className={classes.totalsPrice} priceCents={order!.deliveryChargeCents} />
									</ListItem>
								)}
								<ListItem className={classes.totalsItem}>
									<Typography className={classes.totalsLabel}>{t("tax", { taxRate: order!.taxRate })}</Typography>
									<Price className={classes.totalsPrice} priceCents={order!.taxCents} />
								</ListItem>
								<ListItem className={classes.totalsItem}>
									<Typography className={classes.totalsLabel}>{t("tip")}</Typography>
									<Price className={classes.totalsPrice} priceCents={order!.tipCents} />
								</ListItem>
								<ListItem className={classes.totalsTotalItem}>
									<Typography className={classes.totalsTotalLabel}>{t("total")}</Typography>
									<Price className={classes.totalsTotalPrice} priceCents={order!.orderTotalCents} />
								</ListItem>
							</List>
						</>
					}
				/>

				{order.orderType && order.orderType.orderType !== AvailableOrderTypes.pickup && (
					<CollapsiblePanel
						className={classes.orderBlockContainer}
						header={
							<>
								<CarIcon className={classes.icon} />
								<Typography className={classes.orderBlockTitle}>{t("deliveryInformation")}</Typography>
							</>
						}
						body={
							<>
								<Box className={classes.orderDetails}>
									<Typography className={classes.text}>{order.customer?.fullName} </Typography>
								</Box>
								<Box className={classes.orderDetails}>
									<Typography className={classes.text}>{order.displayPhoneNumber} </Typography>
								</Box>
								{order.deliveryAddress && (
									<Box className={classes.orderDetails}>
										<Typography className={classes.text}>{order.deliveryAddress.address1}</Typography>
										<Typography className={classes.text}>{order.deliveryAddress.address2}</Typography>
										<Typography className={classes.text}>{order.deliveryAddress.address3}</Typography>
										<Typography className={classes.text}>{order.deliveryAddress.city}</Typography>
										<Typography className={classes.text}>{order.deliveryAddress.postalCode}</Typography>
									</Box>
								)}
							</>
						}
					/>
				)}
				<Box className={classes.completeActionsButtonsContainer}>
					{isConfirming && (
						<>
							{order.orderStatus === OrderStatuses.placed && (
								<Fab onClick={onConfirmClicked} className={classes.completeButton} variant="extended">
									<Typography className={classes.completeButtonTitle}>{t("acceptOrder")}</Typography>
									<ConfirmIcon className={classes.confirmButtonIcon} />
								</Fab>
							)}

							{order.orderStatus === OrderStatuses.confirmed && (
								<Fab onClick={onReadyClicked} className={classes.completeButton} variant="extended">
									<Typography className={classes.completeButtonTitle}>{t("readyForPickup")}</Typography>
									<ConfirmIcon className={`${classes.confirmButtonIcon} ${classes.readyConfirmButtonIcon}`} />
								</Fab>
							)}

							{order.orderStatus === OrderStatuses.ready && (
								<Fab onClick={onCompleteClicked} className={classes.completeButton} variant="extended">
									<Typography className={classes.completeButtonTitle}>{t("completed")}</Typography>
									<ConfirmIcon className={`${classes.confirmButtonIcon} ${classes.completeConfirmButtonIcon}`} />
								</Fab>
							)}
						</>
					)}
					{isCancelling && (
						<Modal open={isCancelling} onClose={onCancelAction}>
							<Box className={classes.modal}>
								<Box className={classes.modalBody}>
									<Typography variant="h2" className={classes.modalHeader}>
										{t("rejectOrder")}
									</Typography>

									<Typography variant={"h3"} className={classes.modalCustomerName}>
										{order.customer?.fullName}
									</Typography>

									<Box className={clsx(classes.modalDetails, classes.orderDetailsText)}>
										<Price className={classes.textStrong} priceCents={order.orderTotalCents} />
										<Typography className={classes.textDivider}>•</Typography>
										<Typography className={classes.textStrong}>{t("item", { count: totalItemsCount })}</Typography>
									</Box>
								</Box>
								{order.orderStatus === OrderStatuses.placed && (
									<Fab onClick={onCancelClicked} className={classes.completeRejectButton} variant="extended">
										<Typography className={classes.completeRejectButtonTitle}>{t("confirm")}</Typography>
									</Fab>
								)}

								{order.orderStatus === OrderStatuses.confirmed && (
									<Fab onClick={onCancelAndRefundClicked} className={classes.completeRejectButton} variant="extended">
										<Typography className={classes.completeRejectButtonTitle}>{t("confirm")}</Typography>
									</Fab>
								)}
							</Box>
						</Modal>
					)}
				</Box>
				{!isConfirming && !isCancelling ? (
					<Box className={classes.actionButtonsContainer}>
						<Fab onClick={() => setConfirming(true)} className={classes.confirmButton}>
							{order.orderStatus === OrderStatuses.placed && <ConfirmIcon className={classes.confirmButtonIcon} />}

							{order.orderStatus === OrderStatuses.confirmed && (
								<ConfirmIcon className={`${classes.confirmButtonIcon} ${classes.readyConfirmButtonIcon}`} />
							)}

							{order.orderStatus === OrderStatuses.ready && (
								<ConfirmIcon className={`${classes.confirmButtonIcon} ${classes.completeConfirmButtonIcon}`} />
							)}
						</Fab>

						{/* {(order.orderStatus === OrderStatuses.placed || order.orderStatus === OrderStatuses.confirmed) && (
							<Fab onClick={() => setCancelling(true)} className={classes.cancelButton}>
								<CancelIcon className={classes.cancelButtonIcon} />
							</Fab>
						)} */}
					</Box>
				) : null}
				<Box className={classes.actionButtonsPlaceholder}></Box>
			</Container>
		);
	} else {
		return (
			<Container className={classes.root}>
				<PageTitle pageTitle={t("orderDetail")} />
				ORDER NOT FOUND
			</Container>
		);
	}
};

export default OrderDetailScreen;
