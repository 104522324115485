/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AvailableOrderTypes {
  batchDelivery = "batchDelivery",
  delivery = "delivery",
  pickup = "pickup",
}

export enum OrderStatuses {
  awaitingPayment = "awaitingPayment",
  cancelledByBusiness = "cancelledByBusiness",
  cancelledByCustomer = "cancelledByCustomer",
  completed = "completed",
  confirmed = "confirmed",
  new = "new",
  outForDelivery = "outForDelivery",
  paymentFailed = "paymentFailed",
  placed = "placed",
  ready = "ready",
  scheduled = "scheduled",
}

export enum OrdersSort {
  deliveryOrPickupAtAscNullsFirst = "deliveryOrPickupAtAscNullsFirst",
  deliveryOrPickupAtDescNullsLast = "deliveryOrPickupAtDescNullsLast",
  placedAtAscNullsFirst = "placedAtAscNullsFirst",
  placedAtDescNullsLast = "placedAtDescNullsLast",
  preparationStartAtAscNullsFirst = "preparationStartAtAscNullsFirst",
  preparationStartAtDescNullsLast = "preparationStartAtDescNullsLast",
}

export interface BusinessOrdersInput {
  current?: boolean | null;
  orderStatuses?: OrderStatuses[] | null;
  placedAt?: BusinessOrdersPlacedAtInput | null;
  skip: number;
  take: number;
  sortBy: OrdersSort[];
}

export interface BusinessOrdersPlacedAtInput {
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
}

export interface MutationAuthStartOtpLoginInput {
  userName: string;
}

export interface MutationAuthLoginType {
  authType: string;
}

export interface MutationAuthValidateOtpLoginInput {
  userName: string;
  token: string;
}

export interface MutationCancelOrderInput {
  orderId: string;
  cancellationReason: string;
}

export interface MutationConfirmOrderInput {
  id: string;
  newPreparationTimeMinutes?: number | null;
}

export interface MutationUpdateOrderCompletedInput {
  id: string;
}

export interface MutationUpdateOrderReadyInput {
  id: string;
}

export interface QueryBusinessInput {
  businessId?: string | null;
  businessSlug?: string | null;
}

export interface QueryBusinessesInput {
  skip: number;
  take: number;
}

export interface QueryOrderInput {
  orderId: string;
  refreshStatus?: boolean | null;
  tempAccessToken?: string | null;
}

export interface QueryOrdersInput {
  businessId?: string | null;
  orderStatuses?: OrderStatuses[] | null;
  placedAt?: QueryOrdersPlacedAtInput | null;
  skip: number;
  take: number;
}

export interface QueryOrdersPlacedAtInput {
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
}

export interface SubscriptionOrderUpdatesInput {
  orderId?: string | null;
  businessId?: string | null;
  tempAccessToken?: string | null;
  orderStatuses?: OrderStatuses[] | null;
}

export enum AuthTypes {
	email = "EMAIL",
	phone = "PHONE NUMBER",
	notFound = "NOT_FOUND",
  serverError="SERVER_ERROR"
}

export enum DaysOfWeek {
	mon = "MON",
	tue = "TUE",
	wed = "WED",
	thu = "THU",
	fri = "FRI",
	sat = "SAT",
	sun = "SUN"
}

//==============================================================
// END Enums and Input Objects
//==============================================================
