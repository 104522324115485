import React from "react";

import { AppBar, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import BusinessHeader from "components/BusinessHeader";
import NavMenu from "components/NavMenu";
import useBusiness from "hooks/useBusiness";
import useLocalize from "hooks/useLocalize";

const useStyles = makeStyles(theme => {
	return {
		pageRoot: {
			display: "grid",
			gridTemplateRows: "auto 1fr",
			margin: 0,
			padding: 0,
			overflowX: "hidden",
			overflowY: "hidden"
		},
		header: {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary,
			boxShadow: "none",
			minHeight: 50,
			padding: 0,
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			display: "flex",
			flex: 1,
			alignItems: "center",
			justifyContent: "space-between",
			flexDirection: "row",
			[theme.breakpoints.up(769)]: {
				zIndex: 1300,
				position: "fixed",
				borderBottom: "solid 1px #e7e7e7"
			}
		},
		content: {
			margin: 0,
			padding: 0,
			marginTop: 50,
			height: "calc(100vh - 50px)",
			display: "flex",
			overflowX: "hidden",
			overflowY: "scroll"
		}
	};
});

interface LayoutProps {
	children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const { localize } = useLocalize();
	const { business } = useBusiness();
	const classes = useStyles();
	const businessName = business ? localize(business.localeNames, business.name) : "HYPERFLYER";
	return (
		<Container className={classes.pageRoot} maxWidth={false}>
			<AppBar className={classes.header}>
				<NavMenu businessName={businessName} logoUrl={business ? business?.logoUrl : ""} />
				<BusinessHeader businessName={businessName} />
			</AppBar>
			<Box className={classes.content}>{children}</Box>
		</Container>
	);
};

export default Layout;
