import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationMoveMenuItemInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation MoveMenuItem($moveMenuItemInput: MutationMoveMenuItemInput!) {
	moveMenuItem(moveMenuItemInput: $moveMenuItemInput) {
        success
    }
}
`;

const MoveMenuItem: IOperation<MutationUpdateMenusResult, MutationMoveMenuItemInput> = { text } as any;

export default MoveMenuItem;