import React from "react";

import { Drawer, DrawerProps, SwipeableDrawer, SwipeableDrawerProps } from "@material-ui/core";
import { Location } from "history";
import { useLocation, useMatch, useParams, useSearchParams } from "react-router-dom";

export type RouteDrawerProps = {
	swipeable?: undefined | boolean;
	matchRoute: (context: {
		location: Location;
		params: Record<string, string>;
		search: URLSearchParams;
		match: typeof useMatch;
	}) => boolean;
	hash?: undefined | string;
} & (DrawerProps | SwipeableDrawerProps);

const RouteDrawer = ({ swipeable, matchRoute, hash, ...otherProps }: RouteDrawerProps) => {
	const location = useLocation();
	const params = useParams();
	const [search] = useSearchParams();
	const match = useMatch;

	let open = true;

	if (hash) {
		if (hash !== location.hash) {
			open = false;
		}
	}

	if (open) {
		if (matchRoute) {
			open = matchRoute({
				location,
				params,
				search,
				match
			});
		}
	}

	const props = Object.assign({ open }, otherProps) as SwipeableDrawerProps;

	return swipeable ? <SwipeableDrawer {...props} /> : <Drawer {...props} />;
};

export default RouteDrawer;
