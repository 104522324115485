import { useSubscription as useSubscriptionApollo } from "@apollo/client";

import { SubscriptionHookOptions, SubscriptionResult } from "@apollo/client/react/types/types";

import IOperation from "api/IOperation";

const useSubscription = <D, V>(
	subscription: IOperation<D, V>,
	options?: SubscriptionHookOptions<D, V>
): SubscriptionResult<D> => {
	const res = useSubscriptionApollo(subscription.text, options);
	// console.log("res", res);
	return subscription.map && res.data ? { ...res, data: subscription.map(res.data) } : res;
};

export default useSubscription;
