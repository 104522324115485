let uid = "unknown";
let sessionId = "defaultSession";
let deviceType = "web";
let deviceId = "unknown";
let url = "";

export const setEnv = (
	userId: string,
	trackingSessionId: string,
	trackingdeviceType: string,
	trackingDeviceId: string
): void => {
	uid = userId;
	sessionId = trackingSessionId;
	deviceType = trackingdeviceType;
	deviceId = trackingDeviceId;
	url = process.env.REACT_APP_EVENT_TRACKING_URL || "";
	console.log("init tracking env with url", url);
};

export const trackingEvent = (
	eventType: string,
	eventTargetType = "default",
	eventTargetId = "default",
	meta: string[] = []
): void => {
	const data = {
		uid: uid,
		session_id: sessionId,
		device_type: deviceType,
		device_id: deviceId,
		event_type: eventType,
		event_target_type: eventTargetType,
		event_target_id: eventTargetId,
		timestamp: Date.now(),
		meta: meta
	};

	if (url === "") {
		console.log("track event", data);
	} else {
		fetch(url, {
			method: "POST",
			mode: "no-cors",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		});
	}
};
