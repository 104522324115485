import React, { createContext, useReducer } from "react";

import { getDeviceToken } from "utility/deviceToken"; // for in-memory only access token

const ACTIONS = {
	tokenLoaded: "TOKEN_LOADED",
	setLocale: "SET_LOCALE"
};

const initialState = {
	tokenLoaded: false,
	locale: "en",
	setLocale: (_locale: string) => {}
};

interface AppContextProps {
	locale: string;
	setLocale: (locale: string) => void;
}

export const AppContext = createContext<AppContextProps>(initialState);

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.tokenLoaded:
			console.log("TOKEN_LOADED", getDeviceToken());
			return {
				...state,
				tokenLoaded: true
			};

		case ACTIONS.setLocale:
			return {
				...state,
				locale: action.payload
			};

		default:
			console.warn("app reducer fall thru");
			return state;
	}
};

interface AppContextProviderProps {
	children: React.ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
	const [{ tokenLoaded, ...state }, dispatch] = useReducer(reducer, initialState);

	const setLocale = React.useCallback(
		(locale: string) => {
			dispatch({
				type: ACTIONS.setLocale,
				payload: locale
			});
		},
		[dispatch]
	);

	if (!tokenLoaded) {
		return null;
	}

	return (
		<AppContext.Provider
			value={{
				...state,
				setLocale
			}}
		>
			{children}
		</AppContext.Provider>
	);
};
