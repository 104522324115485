import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationRemoveMenuItemInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation RemoveMenuItem($removeMenuItemInput: MutationRemoveMenuItemInput!) {
	removeMenuItem(removeMenuItemInput: $removeMenuItemInput) {
        success
    }
}
`;

const RemoveMenuItem: IOperation<MutationUpdateMenusResult, MutationRemoveMenuItemInput> = { text } as any;

export default RemoveMenuItem;