import {
	Namespace,
	UseTranslationOptions,
	UseTranslationResponse,
	useTranslation as useTranslationReact
} from "react-i18next";

const useTranslation = (ns?: Namespace, options?: UseTranslationOptions): UseTranslationResponse =>
	useTranslationReact(ns, options);

export default useTranslation;
