import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

interface DesktopContainerDrawerProps {
	props?: any;
	children: React.ReactNode;
}

const MobileContainerDrawer: React.FC<DesktopContainerDrawerProps> = ({ children }) => {
	const matches = useMediaQuery("(max-width:768px)");

	return <>{matches ? children : null}</>;
};

export default MobileContainerDrawer;
