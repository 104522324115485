import React, { useEffect } from "react";

import { useParams } from "react-router";

import { Route, Routes } from "react-router-dom";

import LoginScreen from "containers/LoginScreen";

import useApp from "hooks/useApp";

import BusinessesApp from "./BusinessesApp";

const LocalizedApp = () => {
	const { locale, setLocale } = useApp();
	const params = useParams();

	useEffect(() => {
		if (locale !== params.locale) {
			setLocale(params.locale);
		}
	}, [locale, setLocale, params]);

	return (
		<Routes>
			<Route path="login" element={<LoginScreen />} />
			<Route path="//*" element={<BusinessesApp />} />
		</Routes>
	);
};

export default LocalizedApp;
