import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { CancelOrder as CancelOrderType, CancelOrderVariables } from "gql/types/mutations/CancelOrder";

const text = gql`mutation CancelOrder($cancelOrderInput: MutationCancelOrderInput!) {
	cancelOrder(cancelOrderInput: $cancelOrderInput)
}
`;

const CancelOrder: IOperation<CancelOrderType, CancelOrderVariables> = { text } as any;

export default CancelOrder;
