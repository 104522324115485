import { ApolloLink, Observable } from "@apollo/client";

import { getAccessToken } from "utility/accessToken";

const request = async operation => {
	// const token = await jwtStorageService.getItem(config.TOKEN_STORAGE_KEY);
	const token = getAccessToken();

	if (token) {
		operation.setContext({
			headers: {
				Authorization: token ? `Bearer ${token}` : "",
				"x-access-token": token
			}
		});
		// } else {
		// 	console.error("APOLLO request token is null!!", operation, token);
	}
};

export const observerLink = new ApolloLink(
	(operation, forward) =>
		new Observable(observer => {
			let handle;
			try {
				Promise.resolve(operation)
					.then(oper => request(oper))
					.then(() => {
						handle = forward(operation).subscribe({
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer)
						});
					})
					.catch(err => {
						console.error("gql subscription err:", err);
						observer.error.bind(observer);
					});
				return () => {
					try {
						if (handle) {
							handle.unsubscribe();
						}
					} catch (e) {
						console.error("error in unsubscribe", e);
					}
				};
			} catch (error) {
				console.error("observable err", error);
			}
		})
);
