import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import LocalizedApp from "./LocalizedApp";

const AppRoutes = () => {
	return (
		<Routes>
			<Route path=":locale/*" element={<LocalizedApp />} />
			<Route path="//*" element={<Navigate to="/en" />} />
		</Routes>
	);
};

export default AppRoutes;
