import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationGetPresignedUrlInput, MutationGetPresignedUrlResult} from "gql/types/mutations/PresignedUrl";

const text = gql`mutation GetPresignedUrl($getPresignedUrlInput: MutationGetPresignedUrlInput!) {
	getPresignedUrl(getPresignedUrlInput: $getPresignedUrlInput) {
        url
    }
}
`;

const GetPresignedUrl: IOperation<MutationGetPresignedUrlResult, MutationGetPresignedUrlInput> = { text } as any;

export default GetPresignedUrl;
