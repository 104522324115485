import React, { useMemo, useState } from "react";

import {
	Backdrop,
	Box,
	Button,
	Fade,
	MenuItem as MaterialUIMenuItem,
	Modal,
	OutlinedInput,
	Select,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { default as MenuItemOptionType } from "api/types/MenuItemOption";
import { default as MenuItemOptionGroupType } from "api/types/MenuItemOptionGroup";
import useTranslation from "hooks/useTranslation";
import CancelIcon from "icons/CancelIcon";

const useStyles = makeStyles(theme => {
	return {
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		fade: {
			borderWidth: 0,
			boxShadow: "none",
			borderRadius: 0
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(2),
			boxShadow: "none",
			outline: 0,
			border: "0.5px solid #4A4A4A",
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 512,
			maxHeight: "90%",
			overflowY: "scroll",
			"&::-webkit-scrollbar": {
				width: 0,
				height: 0
			},
			scrollbarWidth: "none",
			"-ms-overflow-style": "none"
		},
		paperRow: {
			width: "100%"
		},
		modalTitle: {
			fontSize: 14,
			fontWeight: 700,
			textTransform: "uppercase",
			height: 28
		},
		actions: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexDirection: "row",
			marginTop: 56
		},
		cancelButton: {
			color: "#4C4C4C",
			borderRadius: 0,
			marginRight: 8,
			width: 100
		},
		addButton: {
			background: "#00B09A",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		options: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "start",
			textAlign: "left",
			alignItems: "left",
			padding: theme.spacing(2),
			paddingRight: theme.spacing(1)
		},
		optionRow: {
			marginBottom: theme.spacing(2)
		},
		title: {
			fontSize: 14,
			fontWeight: 700,
			textTransform: "uppercase",
			height: 28
		},
		flexOptionRow: {
			display: "flex",
			flexDirection: "row",
			flex: 1,
			justifyContent: "start",
			textAlign: "left",
			alignItems: "center",
			marginBottom: theme.spacing(2)
		},
		flexOptionColumn: {
			width: "40%"
		},
		nameInput: {
			borderRadius: 0,
			width: 224
		},
		shortInput: {
			borderRadius: 0,
			width: "70%"
		},
		descriptionInput: {
			borderRadius: 0,
			width: "80%",
			height: 80
		},
		rangeInput: {
			width: 60,
			borderRadius: 0,
			marginRight: theme.spacing(1)
		},
		rangeInputTitle: {
			fontSize: 14,
			marginRight: theme.spacing(1)
		},
		optionList: {},
		itemOption: {
			display: "flex",
			justifyContent: "start",
			marginBottom: theme.spacing(1),
			alignItems: "center",
			alignContent: "center"
		},
		optionInputName: {
			borderRadius: 0,
			width: "40%",
			marginRight: theme.spacing(2),
			fontSize: 14
		},
		optionInputPrice: {
			borderRadius: 0,
			width: "15%",
			marginRight: theme.spacing(2),
			fontSize: 14
		},
		optionInputAvail: {
			borderRadius: 0,
			width: "25%",
			marginRight: theme.spacing(2),
			fontSize: 14,
			textTransform: "capitalize"
		},
		optionAvailText: {
			textTransform: "capitalize"
		},
		optionInputNameTitle: {
			width: "40%",
			marginRight: theme.spacing(2),
			fontSize: 14,
			textTransform: "capitalize"
		},
		optionInputPriceTitle: {
			width: "15%",
			marginRight: theme.spacing(2),
			fontSize: 14,
			textTransform: "capitalize"
		},
		optionInputAvailTitle: {
			width: "25%",
			marginRight: theme.spacing(2),
			fontSize: 14,
			textTransform: "capitalize"
		},
		addOption: {
			color: "#00B09A",
			fontSize: 16,
			marginTop: 12,
			fontWeight: 700,
			marginLeft: theme.spacing(1),
			cursor: "pointer",
			textTransform: "capitalize"
		},
		removeOptionIcon: {
			width: 20,
			height: 20,
			cursor: "pointer"
		}
	};
});

interface MenuItemOptionGroupConfigProps {
	menuItemOptionGroup: MenuItemOptionGroupType;
	optionGroupIndex?: number;
	onSaved: (newOptionGroup: MenuItemOptionGroupType, index?: number) => void;
}

const MenuItemOptionGroupConfig: React.FC<MenuItemOptionGroupConfigProps> = props => {
	const [optionGroup, setOptionGroup] = useState<MenuItemOptionGroupType>();
	const [options, setOptions] = useState<MenuItemOptionType[]>();
	const { t } = useTranslation();
	const classes = useStyles();

	useMemo(() => {
		setOptionGroup({
			...props.menuItemOptionGroup
		});
		const groups = props.menuItemOptionGroup;
		if (groups && groups.menuItemOptions) {
			const newOptions = groups.menuItemOptions.map(option => {
				return {
					...option,
					priceCents: option.priceCents ? option.priceCents / 100.0 : null
				};
			});
			setOptions(newOptions);
		}
	}, [props.menuItemOptionGroup]);

	const dismiss = () => {
		setOptionGroup(undefined);
	};

	if (!optionGroup) return <></>;

	const setName = event => {
		setOptionGroup({
			...optionGroup,
			name: event.target.value
		});
	};

	const setDescription = event => {
		setOptionGroup({
			...optionGroup,
			description: event.target.value
		});
	};

	const setMinCount = event => {
		setOptionGroup({
			...optionGroup,
			minCount: parseInt(event.target.value)
		});
	};

	const setMaxCount = event => {
		setOptionGroup({
			...optionGroup,
			maxCount: parseInt(event.target.value)
		});
	};

	const save = () => {
		const newOptions = options?.map(option => {
			return {
				...option,
				priceCents: option.priceCents ? Math.floor(option.priceCents * 100) : null
			};
		});

		const newOptionGroup: MenuItemOptionGroupType = {
			...optionGroup,
			menuItemOptions: newOptions || []
		};

		props.onSaved(newOptionGroup, props.optionGroupIndex);
		setOptionGroup(undefined);
	};

	const disableSaveButton = () => {
		if (optionGroup.name.trim().length <= 0) return true;

		if (optionGroup.minCount) {
			if (optionGroup.minCount < 0) return true;
			if (!optionGroup.maxCount) return true;
			if (optionGroup.minCount > optionGroup.maxCount) return true;
		}

		if (optionGroup.maxCount) {
			if (optionGroup.minCount === undefined || optionGroup.minCount === null) return true;
		}

		let disable = false;
		if (options) {
			for (const option of options) {
				if (!option.name || option.name.trim().length <= 0) disable = true;
			}
		}

		return disable;
	};

	const addOption = () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const mongoid = require("mongoid-js");
		const newOption: MenuItemOptionType = {
			__typename: "MenuItemOptionType",
			name: "",
			id: mongoid(),
			outOfStock: false,
			description: null,
			localeNames: null,
			imageUrl: null,
			priceCents: null,
			displayPrice: null
		};

		if (!options) {
			setOptions([newOption]);
		} else {
			setOptions([...options, newOption]);
		}
	};

	const setValueForOption = (optionIndex: number, key: string, value: any) => {
		if (!options) return;
		const option = options[optionIndex];
		if (!option) return;
		const newOption = {
			...option,
			[key]: value
		};
		setOptions([...options.slice(0, optionIndex), newOption, ...options.slice(optionIndex + 1)]);
	};

	const removeOption = (optionIndex: number) => {
		if (!options) return;
		const option = options[optionIndex];
		if (!option) return;
		setOptions([...options.slice(0, optionIndex), ...options.slice(optionIndex + 1)]);
	};

	const optionsElement = () => {
		const element = options?.map((option, index) => {
			return (
				<Box key={option.id} className={classes.itemOption}>
					<OutlinedInput
						className={classes.optionInputName}
						placeholder={t("name")}
						value={option.name}
						onChange={event => setValueForOption(index, "name", event.target.value)}
					></OutlinedInput>
					<OutlinedInput
						className={classes.optionInputPrice}
						placeholder="$"
						value={option.priceCents}
						type="number"
						inputProps={{ min: 0.01 }}
						onChange={event => setValueForOption(index, "priceCents", event.target.value)}
					></OutlinedInput>
					<Select
						className={classes.optionInputAvail}
						value={option.outOfStock ? 1 : 0}
						variant="outlined"
						onChange={event => setValueForOption(index, "outOfStock", !!event.target.value)}
					>
						<MaterialUIMenuItem value={0} className={classes.optionAvailText}>
							{t("avaliable")}
						</MaterialUIMenuItem>
						<MaterialUIMenuItem value={1} className={classes.optionAvailText}>
							{t("outOfStock")}
						</MaterialUIMenuItem>
					</Select>

					<CancelIcon className={classes.removeOptionIcon} onClick={() => removeOption(index)} />
				</Box>
			);
		});
		return (
			<Box className={classes.optionList}>
				<Box className={classes.flexOptionRow}>
					<Typography className={classes.optionInputNameTitle}>{t("name")}</Typography>
					<Typography className={classes.optionInputPriceTitle}>{t("price$")}</Typography>
					<Typography className={classes.optionInputAvailTitle}>{t("avail.")}</Typography>
				</Box>
				{element}
				<Typography className={classes.addOption} onClick={addOption}>
					{t("addItem")}
				</Typography>
			</Box>
		);
	};

	return (
		<Modal
			className={classes.modal}
			open={!!optionGroup}
			onClose={dismiss}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={!!optionGroup}>
				<Box className={classes.paper}>
					<Box className={classes.options}>
						<Box className={classes.optionRow}>
							<Typography className={classes.title}>{t("name")}</Typography>
							<OutlinedInput onChange={setName} value={optionGroup.name} className={classes.nameInput} autoFocus />
						</Box>
						<Box className={classes.optionRow}>
							<Typography className={classes.title}>{t("description")}</Typography>
							<OutlinedInput
								onChange={setDescription}
								value={optionGroup.description}
								className={classes.descriptionInput}
								multiline={true}
								rows={3}
							/>
						</Box>
						<Box className={classes.optionRow}>
							<Typography className={classes.title}>{t("howManyItemsCanBeChosen")}</Typography>
							<Box className={classes.flexOptionRow}>
								<Typography className={classes.rangeInputTitle}>{t("selectARange")}</Typography>
								<OutlinedInput
									className={classes.rangeInput}
									value={optionGroup.minCount}
									onChange={setMinCount}
									placeholder="1"
									type="number"
								/>
								<Typography className={classes.rangeInputTitle}>{t("to")}</Typography>
								<OutlinedInput
									className={classes.rangeInput}
									onChange={setMaxCount}
									inputProps={{ min: optionGroup.minCount }}
									value={optionGroup.maxCount}
									placeholder="1"
									type="number"
								/>
							</Box>
						</Box>
						<Box className={classes.optionRow}>
							<Typography className={classes.title}>{t("items")}</Typography>
							<Box>{optionsElement()}</Box>
						</Box>

						<Box className={classes.actions}>
							<Button className={classes.cancelButton} onClick={dismiss}>
								{t("cancel")}
							</Button>
							<Button className={classes.addButton} disabled={disableSaveButton()} onClick={save}>
								{t("save")}
							</Button>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default MenuItemOptionGroupConfig;
