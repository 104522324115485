import { useEffect, useState } from "react";

interface Props {
	onChange: (e) => void;
	onSubmit: (e) => void;
	isSubmitting: boolean;
	setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
	hasChanges: boolean;
	setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
	values: Record<string, any>;
	setValue: React.Dispatch<React.SetStateAction<any>>;
	setErrors: React.Dispatch<React.SetStateAction<any>>;
	resetValues: (e) => void;
	errors: Record<string, string>;
}

const useForm = <T, E>(callback: () => void, initialState: T, validate: (values: T) => E): Props => {
	const [hasChanges, setHasChanges] = useState(false);
	const [values, setValues] = useState<T>(initialState);
	const [errors, setErrors] = useState<any>({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (errors && Object.keys(errors).length === 0 && isSubmitting && callback) {
			callback();
		}
	}, [callback, errors, isSubmitting]);

	const onChange = event => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
		if (!hasChanges) {
			setHasChanges(true);
		}
		setIsSubmitting(false);
	};

	const setValue = value => {
		setValues({
			...values,
			...value
		});
	};

	const resetValues = newValue => {
		if (newValue) {
			setValues(newValue);
			setErrors(undefined);
		} else {
			setValues(initialState);
			setErrors(undefined);
		}
	};

	const onSubmit = event => {
		if (event) {
			event.preventDefault();
		}
		setIsSubmitting(true);
		const errs = validate(values);
		setErrors(errs);
		if (errs && Object.keys(errs).length > 0) {
			setIsSubmitting(false);
		}
	};

	return {
		onChange,
		onSubmit,
		isSubmitting,
		setIsSubmitting,
		hasChanges,
		setHasChanges,
		values,
		setValue,
		setErrors,
		resetValues,
		errors
	};
};

export default useForm;
