import { ContextType, useContext, useEffect, useMemo } from "react";

import jwtDecode from "jwt-decode";

import AuthStartOtpLogin from "api/mutations/AuthStartOtpLogin";
import AuthValidateOtpLogin from "api/mutations/AuthValidateOtpLogin";
import AuthContext from "contexts/AuthContext";
import { MutationAuthLoginType } from "gql/types/globals";
import StateOf from "utility/StateOf";

import { getAccessToken, setAccessToken } from "utility/accessToken";

import useMutation from "./useMutation";

type Context = ContextType<typeof AuthContext>;
type State = StateOf<Context>;

const useAuth = () => {
	const [authStartOtpLogin] = useMutation(AuthStartOtpLogin);
	const [authValidateOtpLogin] = useMutation(AuthValidateOtpLogin);

	const context = useContext(AuthContext);

	const updateState = useMemo(
		() => (changes: (state: State) => Partial<State>) =>
			context.setState(state => ({
				...state,
				...changes(state)
			})),
		[context]
	);

	// sync context changes into local storage
	useEffect(() => {
		localStorage.setItem("hfa", JSON.stringify(context));
	}, [context]);

	return useMemo(
		() => ({
			...context,

			accessToken: getAccessToken(),

			async startOtpLogin(userName: string): Promise<MutationAuthLoginType | null | undefined> {
				// console.log("startOtpLogin userName", userName);
				const { data } = await authStartOtpLogin({
					variables: { authStartOtpLoginInput: { userName } }
				});
				return data?.authStartOtpLogin;
			},

			async validateOtpLogin(userName: string, token: string) {
				const { data } = await authValidateOtpLogin({ variables: { authValidateOtpLoginInput: { userName, token } } });
				if (data?.authValidateOtpLogin && data?.authValidateOtpLogin?.length > 0) {
					const decodedToken: any = jwtDecode(data?.authValidateOtpLogin);
					setAccessToken(data?.authValidateOtpLogin);
					context.setState({
						userId: decodedToken._id,
						user: null,
						businessRoles: decodedToken.businessRoles,
						loggedOut: false
					});
				}
				return data;
			},

			logout() {
				context.setState({ userId: null, user: null, businessRoles: null, loggedOut: true });
				setAccessToken(null);
			}
		}),
		[context, authStartOtpLogin, authValidateOtpLogin]
	);
};

export default useAuth;
