import { createContext } from "react";

interface UserProps {
	id: string;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	displayPhoneNumber?: string;
}

interface BusinessRole {
	businessId: string;
	roles: string[];
}

interface AuthContextProps {
	userId: string | null;
	user: UserProps | null;
	businessRoles?: BusinessRole[] | null;
	loggedOut?: boolean;
}

interface Methods {
	setState(state: AuthContextProps | ((prevState: AuthContextProps) => AuthContextProps)): void;
}

const AuthContext = createContext<AuthContextProps & Methods>({} as any);

export default AuthContext;
