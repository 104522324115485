import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationAddMenuItemInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation AddMenuItem($addMenuItemInput: MutationAddMenuItemInput!) {
	addMenuItem(addMenuItemInput: $addMenuItemInput) {
        success
    }
}
`;

const AddMenuItem: IOperation<MutationUpdateMenusResult, MutationAddMenuItemInput> = { text } as any;

export default AddMenuItem;