import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationMoveUpMenuItemInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation MoveUpMenuItem($moveUpMenuItemInput: MutationMoveUpMenuItemInput!) {
	moveUpMenuItem(moveUpMenuItemInput: $moveUpMenuItemInput) {
        success
    }
}
`;

const MoveUpMenuItem: IOperation<MutationUpdateMenusResult, MutationMoveUpMenuItemInput> = { text } as any;

export default MoveUpMenuItem;