import React from "react";

import { Box, Fab, SwipeableDrawer, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import NotificationSound from "components/NotificationSound";
import Price from "components/Price";
import { OrderUpdatesSubscription_orderUpdates_order } from "gql/types/subscriptions/OrderUpdatesSubscription";
import makeStyles from "hooks/makeStyles";
import NewOrderIcon from "icons/NewOrderIcon";

const useStyles = makeStyles(theme => ({
	paper: {
		background: "transparent",
		borderRadius: "8px 0px 0px 8px",
		width: "100%"
	},
	root: {
		background: "transparent",
		boxShadow: "0"
	},
	backdrop: {
		backgroundColor: "transparent"
	},
	icon: {
		width: "20px",
		fill: "#fff"
	},
	splashRoot: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		backdropFilter: "blur(2px)",
		height: "100%",
		background: "rgba(51, 51, 51, 0.7)",
		paddingTop: "200px"
	},
	block: {
		marginBottom: theme.spacing(4),
		color: "#fff",
		textAlign: "center"
	},
	blockContent: {
		flexDirection: "row",
		display: "flex",
		justifyContent: "center"
	},
	footer: {
		borderBottom: "none",
		position: "absolute",
		bottom: 0,
		borderTop: "solid 1px #f2f2f2",
		right: 0,
		left: 0,
		display: "flex",
		justifyContent: "center"
	},
	newOrderIcon: {
		width: "32px",
		fill: "#fff",
		paddingBottom: theme.spacing(2)
	},
	menuButton: {
		padding: "13px 4px"
	},
	text: {
		textAlign: "center",
		textTransform: "uppercase",
		fontSize: "18px",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},
	textDivider: {
		fontSize: "18px",
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(1)
	},
	title: {
		textAlign: "center",
		textTransform: "uppercase",
		fontSize: "28px",
		whiteSpace: "initial"
	},
	actionButton: {
		height: theme.spacing(8),
		background: "#333333",
		borderRadius: theme.spacing(9),
		padding: theme.spacing(1.5),
		margin: theme.spacing(2),
		textAlign: "center"
	},
	actionButtonTitle: {
		color: "#fff",
		fontSize: "18px",
		lineHeight: "22px",
		textTransform: "uppercase",
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4)
	}
}));

interface SplashScreenProps {
	newOrders: OrderUpdatesSubscription_orderUpdates_order[] | null;
	onClose: () => void;
}

const SplashScreen = ({ newOrders, onClose }: SplashScreenProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};
	const onOrdersClicked = () => handleClose();

	const totalItemsCount = newOrders
		? newOrders.reduce((total, order) => total + order.orderItems.reduce((a, b) => a + b.quantity, 0), 0)
		: 0;
	const totalAmount = newOrders ? newOrders.reduce((total, order) => total + order.orderTotalCents!, 0) : 0;

	console.log("rendering splash");
	return (
		<>
			<NotificationSound />
			<SwipeableDrawer
				anchor={"right"}
				open={!!newOrders}
				onClose={handleClose}
				onOpen={() => {}}
				classes={{ paper: classes.paper, root: classes.root }}
				BackdropProps={{ className: classes.backdrop }}
			>
				<Box className={classes.splashRoot}>
					<Box className={classes.block}>
						<Typography className={classes.title} variant="h2">
							{t("newOrder")}
						</Typography>
					</Box>
					<Box className={classes.block}>
						<NewOrderIcon className={classes.newOrderIcon} />
					</Box>

					<Box className={classes.block}>
						{newOrders &&
							newOrders.map((a, index) => (
								<Typography key={`customer_${index}`} className={classes.text}>
									{a.customer?.fullName}
								</Typography>
							))}
					</Box>

					<Box className={classes.block}>
						<Box className={classes.blockContent}>
							<Typography className={classes.text}>{t("item", { count: totalItemsCount })}</Typography>
							<Typography className={classes.textDivider}>•</Typography>
							<Price className={classes.text} priceCents={totalAmount} />
						</Box>
					</Box>

					<Box className={classes.footer}>
						<Fab onClick={onOrdersClicked} className={classes.actionButton} variant="extended">
							<Typography className={classes.actionButtonTitle}>{t("viewNewOrders")}</Typography>
						</Fab>
					</Box>
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default SplashScreen;
