import React from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
	return {
		root: {
			display: "flex",
			height: "100vh",
			flex: 1,
			alignContent: "center",
			alignItems: "center",
			justifyContent: "center"
		}
	};
});

interface LoadingProps {
	props?: any;
}

const Loading: React.FC<LoadingProps> = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress />
		</div>
	);
};

export default Loading;
