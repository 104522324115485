import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { AuthValidateOtpLogin as AuthValidateOtpLoginType, AuthValidateOtpLoginVariables } from "gql/types/mutations/AuthValidateOtpLogin";

const text = gql`mutation AuthValidateOtpLogin($authValidateOtpLoginInput: MutationAuthValidateOtpLoginInput!) {
	authValidateOtpLogin(authValidateOtpLoginInput: $authValidateOtpLoginInput)
}
`;

const AuthValidateOtpLogin: IOperation<AuthValidateOtpLoginType, AuthValidateOtpLoginVariables> = { text } as any;

export default AuthValidateOtpLogin;
