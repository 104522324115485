import React, { useReducer, useState } from "react";

import {
	Backdrop,
	Box,
	Button,
	ClickAwayListener,
	Container,
	Fade,
	List,
	Modal,
	OutlinedInput,
	Popover,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Trans } from "react-i18next";

import AddMenu from "api/mutations/AddMenu";
import AddMenuGroup from "api/mutations/AddMenuGroup";
import MoveDownMenuGroup from "api/mutations/MoveDownMenuGroup";
import MoveUpMenuGroup from "api/mutations/MoveUpMenuGroup";
import RemoveMenuGroup from "api/mutations/RemoveMenuGroup";
import UpdateMenuGroup from "api/mutations/UpdateMenuGroup";
import Menu from "api/types/Menu";
import MenuGroup from "api/types/MenuGroup";
import MenuItem from "api/types/MenuItem";
import Loading from "components/Loading";
import PageTitle from "components/PageTitle";
import { ScheduleInput } from "gql/types/mutations/BusinessUpdate";
import useBusiness from "hooks/useBusiness";

import useMutation from "hooks/useMutation";
import useTranslation from "hooks/useTranslation";
import ArrowDownIcon from "icons/ArrowDownIcon";
import EditIcon from "icons/EditIcon";
import ExpandIcon from "icons/ForwardIcon";
import PlusIcon from "icons/PlusIcon";

import MenuItemConfig, { MenuItemProps } from "./MenuItemConfig";
import MenuItemList from "./MenuItemList";
import { menuActionsReducer } from "./menuActionsReducer";

const border = "0.5px solid #828282";
const useStyles = makeStyles(theme => {
	return {
		screen: {
			display: "flex",
			width: "100%",
			height: "100%",
			flexDirection: "row",
			alignItems: "left",
			alignContent: "left",
			justifyContent: "start",
			[theme.breakpoints.up(769)]: {},
			[theme.breakpoints.up(1280)]: {
				maxWidth: "initial"
			}
		},
		root: {
			display: "flex",
			height: "100%",
			flexDirection: "column",
			flex: 1,
			margin: 0,
			padding: 0,
			textAlign: "center",
			color: "#4F4F4F",
			[theme.breakpoints.up(480)]: {
				width: "100%",
				maxWidth: "100%"
			},
			[theme.breakpoints.up(769)]: {
				width: "30%",
				maxWidth: "30%",
				borderRight: border
			}
		},
		drawerClose: {
			width: "100%",
			maxWidth: "auto"
		},
		drawerOpen: {
			height: "100%",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			overflowY: "scroll",
			"&::-webkit-scrollbar": {
				width: 0,
				height: 0
			},
			scrollbarWidth: "none",
			"-ms-overflow-style": "none"
		},
		header: {
			background: "#E0E0E0",
			padding: theme.spacing(2),
			flexDirection: "row",
			display: "flex",
			justifyContent: "space-between",
			alignContent: "center",
			alignItems: "center",
			height: 64,
			borderBottom: border
		},
		headerText: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px",
			fontWeight: "bold"
		},
		selected: {
			background: "#F8F8F8",
			fontStyle: "italic"
		},
		menuList: {
			paddingTop: 0,
			paddingBottom: 0
		},
		menuNameBox: {
			height: 46
		},
		menuName: {
			textTransform: "uppercase",
			fontSize: 18,
			fontWeight: 700,
			lineHeight: "22px",
			maxWidth: "75%",
			textAlign: "left",
			overflowWrap: "break-word",
			overflow: "hidden",
			textOverflow: "ellipsis",
			[theme.breakpoints.down(400)]: {
				fontSize: 16
			}
		},
		menu: {
			padding: theme.spacing(2),
			height: 96,
			borderBottom: border,
			"&:hover": {
				background: "#F8F8F8"
			}
		},
		menuDetailBox: {
			marginTop: 0
		},
		menuDetail: {
			display: "flex",
			justifyContent: "start",
			fontSize: 14,
			whiteSpace: "pre-wrap"
		},
		menuRow: {
			flexDirection: "row",
			display: "flex",
			justifyContent: "space-between"
		},
		menuRowDot: {
			width: 4,
			height: 4,
			borderRadius: 2,
			background: "#4F4F4F",
			marginTop: 10,
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1)
		},
		expandIcon: {
			width: 9,
			height: 16,
			marginTop: 2,
			marginRight: 7.5,
			cursor: "pointer"
		},
		expandedIcon: {
			width: 16,
			height: 9,
			marginTop: 6.5,
			marginRight: 3.5,
			cursor: "pointer"
		},
		editMenuIconBg: {
			width: 24,
			height: 24,
			borderRadius: 12,
			background: "#F2F2F2",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			cursor: "pointer"
		},
		editMenuIcon: {
			width: 16,
			height: 16
		},
		menuGroups: {},
		menuGroup: {
			display: "flex",
			justifyContent: "space-between",
			alignContent: "center",
			alignItems: "center",
			width: "100%",
			height: 48,
			borderBottom: border,
			"&:hover": {
				background: "#F8F8F8"
			}
		},
		menuGroupName: {
			fontSize: 16,
			marginLeft: 32,
			maxWidth: "75%",
			overflow: "hidden",
			textOverflow: "ellipsis",
			textAlign: "left"
		},
		editMenuGroupBox: {
			width: 24,
			height: 24,
			marginRight: 16,
			borderRadius: 12,
			background: "#F2F2F2",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			cursor: "pointer"
		},
		editMenuGroupIcon: {
			width: 16,
			height: 16
		},
		addMenuGroup: {
			fontSize: 18,
			fontWeight: 700,
			color: "#00B09A",
			marginLeft: 32,
			textTransform: "uppercase",
			cursor: "pointer"
		},
		footer: {
			flexDirection: "row",
			display: "flex",
			justifyContent: "start",
			alignContent: "center",
			alignItems: "center",
			height: 48,
			minHeight: 48,
			padding: theme.spacing(2),
			backgroundColor: "#E0E0E0",
			width: "100%",
			[theme.breakpoints.up(769)]: {
				borderTop: border,
				borderRight: "none",
				backgroundColor: "#FFFFFF"
			}
		},
		plusIcon: {
			width: 20,
			height: 20,
			cursor: "pointer"
		},
		footerText: {
			textTransform: "uppercase",
			fontWeight: 700,
			fontSize: 18,
			marginLeft: 20,
			cursor: "pointer"
		},
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		fade: {
			borderWidth: 0,
			boxShadow: "none",
			borderRadius: 0
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(2),
			boxShadow: "none",
			outline: 0,
			border: "0.5px solid #4A4A4A"
		},
		paperRow: {
			width: "100%"
		},
		editMenuGroupPaper: {
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 512
		},
		title: {
			fontSize: 14,
			fontWeight: 700,
			textTransform: "uppercase",
			height: 28
		},
		nameInput: {
			borderRadius: 0,
			marginBottom: theme.spacing(1)
		},
		menuGroupDescriptionInput: {
			borderRadius: 0,
			height: 72
		},
		actions: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexDirection: "row",
			marginTop: 56
		},
		cancelButton: {
			color: "#4C4C4C",
			borderRadius: 0,
			marginRight: 8,
			width: 100
		},
		addButton: {
			background: "#00B09A",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		popover: {
			"& .MuiPaper-elevation8": {
				boxShadow: "none"
			},
			"& .MuiPaper-rounded": {
				borderRadius: 0
			}
		},
		editBox: {
			width: 100,
			border: "0.5px solid #4A4A4A",
			boxSizing: "border-box",
			overflow: "hidden"
		},
		editAction: {
			padding: "0 16px",
			height: 32,
			width: "100%",
			display: "flex",
			alignItems: "center",
			textAlign: "center",
			justifyContent: "center",
			fontSize: 14,
			fontWeight: 700,
			textTransform: "capitalize",
			"&:hover": {
				color: "#C0C0C0"
			},
			cursor: "pointer"
		},
		editActionBorderBottom: {
			borderBottom: "0.5px solid #C0C0C0"
		},
		deletePaper: {
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 400
		},
		deleteTitleBox: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			justifyContent: "center",
			width: "100%"
		},
		deleteTitle: {
			width: 240,
			fontSize: 24,
			fontWeight: 700,
			marginTop: 36,
			textTransform: "uppercase"
		},
		deleteActions: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexDirection: "row",
			marginTop: 56
		},
		deleteButton: {
			background: "#E42306",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		editMenuPaper: {
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 512
		}
	};
});

enum DeleteActionType {
	"menu" = "MENU",
	"menuGroup" = "MENU_GROUP"
}

enum MenuGroupActionType {
	"none" = "NONE",
	"add" = "ADD",
	"update" = "UPDATE"
}

interface MenuGroupAction {
	type: MenuGroupActionType;
	menuId?: string;
	menuGroupId?: string;
	menuGroupIndex?: number;
	menuGroupName?: string;
	menuGroupDescription?: string;
}

interface DeleteAction {
	type: DeleteActionType;
	menuId?: string;
	menuGroupId?: string;
}

enum MenuActionType {
	"none" = "NONE",
	"add" = "ADD",
	"update" = "UPDATE"
}

interface MenuAction {
	type: MenuActionType;
	menuId?: string;
	menuName?: string;
	available?: ScheduleInput[];
}

interface SelectedMenuGroup {
	menuId: string;
	menuGroupId: string;
}

const MenusScreen = (): JSX.Element => {
	const { refetch: refetchBusiness, business } = useBusiness();
	const { t } = useTranslation();
	const classes = useStyles();
	const [menuActionsState, dispatch] = useReducer(menuActionsReducer, []);
	const [menuGroupAction, setMenuGroupAction] = useState<MenuGroupAction>();
	const [deleteAction, setDeleteAction] = useState<DeleteAction>();
	const [addMenuGroup] = useMutation(AddMenuGroup);
	const [updateMenuGroup] = useMutation(UpdateMenuGroup);
	const [removeMenuGroup] = useMutation(RemoveMenuGroup);
	const [moveUpMenuGroup] = useMutation(MoveUpMenuGroup);
	const [moveDownMenuGroup] = useMutation(MoveDownMenuGroup);
	const [addMenu] = useMutation(AddMenu);
	const [menuGroupAnchorEl, setMenuGroupAnchorEl] = useState(null);
	const [menuAction, setMenuAction] = useState<MenuAction>();
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [selectedMenuGroup, setSelectedMenuGroup] = useState<SelectedMenuGroup>();
	const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemProps>();

	if (!business) return <Loading />;

	const menus = business.menus;

	const expand = (expanded: boolean, id: string | number) => {
		dispatch({
			type: "EXPAND",
			expanded,
			id
		});
	};

	const handleGroupDescriptionChange = event => {
		if (!menuGroupAction) return;

		setMenuGroupAction({
			...menuGroupAction,
			menuGroupDescription: event.target.value
		});
	};

	const handleGroupNameChange = event => {
		if (!menuGroupAction) return;

		setMenuGroupAction({
			...menuGroupAction,
			menuGroupName: event.target.value
		});
	};

	const handleAddMenuGroup = (menuId: string) => {
		setMenuGroupAction({
			type: MenuGroupActionType.add,
			menuId: menuId
		});
	};

	const dismissMenuGroupActionsMenu = () => {
		setMenuGroupAnchorEl(null);
	};

	const handleCloseAddingMenuGroup = () => {
		setMenuGroupAction(undefined);
	};

	const isOpenMenuGroupEditModal = (): boolean => {
		if (
			menuGroupAction &&
			(menuGroupAction.type === MenuGroupActionType.update || menuGroupAction.type === MenuGroupActionType.add)
		)
			return true;

		return false;
	};

	const saveMenuGroup = async () => {
		if (!menuGroupAction) return;

		if (menuGroupAction.type === MenuGroupActionType.add) {
			await toAddMenuGroup();
			return;
		}

		if (menuGroupAction.type === MenuGroupActionType.update) {
			await toUpdateMenuGroup();
		}
	};

	const toUpdateMenuGroup = async () => {
		if (!menuGroupAction) return;

		if (!menuGroupAction.menuId || !menuGroupAction.menuGroupName || !menuGroupAction.menuGroupId) return;
		const name = menuGroupAction.menuGroupName.trim();
		if (name === "") return;
		if (!business) return;

		await updateMenuGroup({
			variables: {
				updateMenuGroupInput: {
					businessId: business.id,
					menuId: menuGroupAction.menuId,
					menuGroupId: menuGroupAction.menuGroupId,
					newMenuGroupName: name,
					newMenuGroupDescription: menuGroupAction.menuGroupDescription || ""
				}
			}
		})
			.then(() => {
				const fetchBusiness = async () => {
					await refetchBusiness();
				};
				fetchBusiness();
				handleCloseAddingMenuGroup();
			})
			.catch(e => {});
	};

	const toAddMenuGroup = async () => {
		if (!menuGroupAction) return;

		if (!menuGroupAction.menuId || !menuGroupAction.menuGroupName) return;
		const name = menuGroupAction.menuGroupName.trim();
		if (name === "") return;
		if (!business) return;
		await addMenuGroup({
			variables: {
				addMenuGroupInput: {
					businessId: business.id,
					menuId: menuGroupAction.menuId,
					menuGroupName: name,
					menuGroupDescription: menuGroupAction.menuGroupDescription || ""
				}
			}
		})
			.then(() => {
				const fetchBusiness = async () => {
					await refetchBusiness();
				};
				fetchBusiness();
				handleCloseAddingMenuGroup();
			})
			.catch(e => {});
	};

	const setMenuGroupActionAnchorEl = (event, menuId: string, menuGroup: MenuGroup, menuGroupIndex: number) => {
		setMenuGroupAnchorEl(event.currentTarget);
		setMenuGroupAction({
			type: MenuGroupActionType.none,
			menuId: menuId,
			menuGroupId: menuGroup.id,
			menuGroupIndex: menuGroupIndex,
			menuGroupName: menuGroup.name,
			menuGroupDescription: menuGroup.description
		});

		event.stopPropagation();
	};

	const handleEditMenuGroup = () => {
		setMenuGroupAction({
			...menuGroupAction,
			type: MenuGroupActionType.update
		});
	};

	const handleMoveUpMenuGroup = async () => {
		if (!menuGroupAction || !menuGroupAction.menuId || !menuGroupAction.menuGroupId) return;
		await moveUpMenuGroup({
			variables: {
				moveUpMenuGroupInput: {
					businessId: business.id,
					menuId: menuGroupAction.menuId || "",
					menuGroupId: menuGroupAction.menuGroupId || ""
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();

			setMenuGroupAction(undefined);
			setMenuGroupAnchorEl(null);
		});
	};

	const handleMoveDownMenuGroup = async () => {
		if (!menuGroupAction || !menuGroupAction.menuId || !menuGroupAction.menuGroupId) return;
		await moveDownMenuGroup({
			variables: {
				moveDownMenuGroupInput: {
					businessId: business.id,
					menuId: menuGroupAction.menuId || "",
					menuGroupId: menuGroupAction.menuGroupId || ""
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();

			setMenuGroupAction(undefined);
			setMenuGroupAnchorEl(null);
		});
	};

	const handleDeleteMenuGroup = () => {
		if (!menuGroupAction) return;
		setDeleteAction({
			...deleteAction,
			menuId: menuGroupAction.menuId,
			menuGroupId: menuGroupAction.menuGroupId,
			type: DeleteActionType.menuGroup
		});
	};

	const isOpenDeleteModal = (): boolean => {
		return !!deleteAction;
	};

	const handleCloseDeleteModal = () => {
		setDeleteAction(undefined);
	};

	const handleDelete = async () => {
		if (!deleteAction) return;

		if (deleteAction.type === DeleteActionType.menuGroup) {
			await tryDeleteMenuGroup();
		}
	};

	const clearPageAfterMenuGroupBeenDeleted = (menuId, menuGroupId) => {
		if (menuId === selectedMenuGroup?.menuId && menuGroupId === selectedMenuGroup?.menuGroupId) {
			setSelectedMenuGroup(undefined);
			setSelectedMenuItem(undefined);
		}
	};

	const tryDeleteMenuGroup = async () => {
		if (!deleteAction) return;
		if (!deleteAction.menuId || !deleteAction.menuGroupId) return;
		if (!business) return;

		await removeMenuGroup({
			variables: {
				removeMenuGroupInput: {
					businessId: business.id,
					menuId: deleteAction.menuId,
					menuGroupId: deleteAction.menuGroupId
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();

			clearPageAfterMenuGroupBeenDeleted(deleteAction.menuId, deleteAction.menuGroupId);
			handleCloseDeleteModal();
		});
	};

	const isOpenMenuEditModal = (): boolean => {
		if (menuAction && menuAction.type !== MenuActionType.none) return true;

		return false;
	};

	const handleCloseMenuEditModal = () => {
		setMenuAction(undefined);
	};

	// const setMenuActionAnchorEl = (event, menu: Menu) => {
	// 	setMenuAnchorEl(event.target);
	// 	setMenuAction({
	// 		type: MenuActionType.none,
	// 		menuId: menu.id
	// 	});
	// };

	const handleAddMenu = () => {
		setMenuAction({
			...menuAction,
			type: MenuActionType.add
		});
	};

	const handleMenuNameChange = event => {
		if (!menuAction) return;

		setMenuAction({
			...menuAction,
			menuName: event.target.value
		});
	};

	const saveMenu = async () => {
		if (!menuAction) return;

		if (menuAction.type === MenuActionType.add) {
			await tryToAddMenu();
		}
	};

	const tryToAddMenu = async () => {
		if (!menuAction) return;
		if (!menuAction.menuName) return;
		if (!business) return;

		const name = menuAction.menuName.trim();
		if (name.length <= 0) return;

		await addMenu({
			variables: {
				addMenuInput: {
					businessId: business.id,
					name,
					available: menuAction.available
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();
			handleCloseMenuEditModal();
		});
	};

	const handleClickMenuGroup = (menuId: string, menuGroup: MenuGroup) => {
		if (selectedMenuGroup && (selectedMenuGroup.menuId !== menuId || selectedMenuGroup.menuGroupId !== menuGroup.id)) {
			setSelectedMenuItem(undefined);
		}

		setSelectedMenuGroup({
			menuId: menuId,
			menuGroupId: menuGroup.id
		});
	};

	const isMenuGroupSelected = (menuId: string, menuGroupId: string): boolean => {
		if (!selectedMenuGroup) return false;
		if (selectedMenuGroup.menuId === menuId && selectedMenuGroup.menuGroupId === menuGroupId) return true;

		return false;
	};

	const getSelectedMenuGroupData = (): MenuGroup => {
		if (!selectedMenuGroup) throw new Error("please select a menu group");
		const { menus } = business;
		const menu = menus.find(menu => menu.id === selectedMenuGroup.menuId);
		if (!menu) throw new Error("wrong selected menu data");
		const menuGroups = menu.menuGroups;
		if (!menuGroups) throw new Error("wrong selected menu group data");

		const menuGroup = menuGroups.find(group => group.id === selectedMenuGroup.menuGroupId);
		if (!menuGroup) throw new Error("can not find selected menu group");

		return menuGroup;
	};

	const onMenuItemSelected = (menuId: string, menuGroupId: string, menuItem: MenuItem) => {
		setSelectedMenuItem({
			menuId,
			menuGroupId,
			menuItem
		});
	};

	const getMenu = (menuId: string): Menu => {
		const theMenu = business.menus.find((menu: Menu) => menu.id === menuId);
		if (!theMenu) throw new Error(`can not find menu with id: ${menuId}`);

		return theMenu;
	};

	const onMenuItemDeleted = (menuId: string, menuGroupId: string, menuItemId: string) => {
		if (!selectedMenuItem) return;
		if (
			selectedMenuItem.menuId === menuId &&
			selectedMenuItem.menuGroupId === menuGroupId &&
			selectedMenuItem.menuItem.id === menuItemId
		) {
			setSelectedMenuItem(undefined);
		}
	};

	const onMenuItemMoved = (menuId: string, menuGroupId: string, menuItemId: string) => {
		if (!selectedMenuItem) return;
		if (
			selectedMenuItem.menuId === menuId &&
			selectedMenuItem.menuGroupId === menuGroupId &&
			selectedMenuItem.menuItem.id === menuItemId
		) {
			setSelectedMenuItem(undefined);
		}
	};

	const menuGroupsElement = (menuId: string, menuGroups: MenuGroup[]) => {
		if (!menuGroups) return <></>;
		const elements = menuGroups.map((menuGroup, index) => {
			return (
				<Box
					className={`${classes.menuGroup} ${isMenuGroupSelected(menuId, menuGroup.id) ? classes.selected : ""}`}
					key={menuGroup.id}
					onClick={() => handleClickMenuGroup(menuId, menuGroup)}
				>
					<Typography className={classes.menuGroupName}>{menuGroup.name}</Typography>
					<Box
						className={classes.editMenuGroupBox}
						onClick={event => setMenuGroupActionAnchorEl(event, menuId, menuGroup, index)}
					>
						<EditIcon className={classes.editMenuGroupIcon} />
					</Box>
				</Box>
			);
		});

		return <Box className={classes.menuGroups}>{elements}</Box>;
	};

	const menusElement = menus.map(menu => {
		const menuGroups = menu.menuGroups;
		const menuGroupsCount = menuGroups ? menuGroups.length : 0;
		let menuItemsCount = 0;
		const menuActionState = menuActionsState?.find(menuAction => menuAction.id === menu.id);
		if (menuGroups)
			menuItemsCount = menuGroups.reduce((count, menuGroup) => {
				const menuItems = menuGroup.menuItems;
				const menuItemsCount: number = menuItems ? menuItems.length : 0;
				return count + menuItemsCount;
			}, 0);

		return (
			<Box key={menu.id || menu.slug}>
				<Box
					className={classes.menu}
					onClick={() => {
						expand(!menuActionState?.expanded, menu.id);
					}}
				>
					<Box className={`${classes.menuRow} ${classes.menuNameBox}`}>
						<Typography className={classes.menuName}>{menu.name}</Typography>
						{/* <Box className={classes.editMenuIconBg}>
							<EditIcon className={classes.editMenuIcon} onClick={event => setMenuActionAnchorEl(event, menu)} />
						</Box> */}
					</Box>
					<Box className={`${classes.menuRow} ${classes.menuDetailBox}`}>
						<Box className={classes.menuDetail}>
							<Trans>{t("menuCategory", { count: menuGroupsCount })}</Trans>
							<Box className={classes.menuRowDot}></Box>
							<Trans>{t("menuItem", { count: menuItemsCount })}</Trans>
						</Box>
						{!menuActionState?.expanded && <ExpandIcon className={classes.expandIcon} />}
						{menuActionState?.expanded && <ArrowDownIcon className={classes.expandedIcon} />}
					</Box>
				</Box>
				{menuActionState?.expanded && (
					<>
						{menuGroupsElement(menu.id, menuGroups)}
						<Box className={classes.menuGroup} onClick={() => handleAddMenuGroup(menu.id)}>
							<Typography className={classes.addMenuGroup}>{t("addCategory")}</Typography>
						</Box>
					</>
				)}
			</Box>
		);
	});

	return (
		<Box className={classes.screen}>
			<Container className={classes.root}>
				<PageTitle pageTitle={t("menus")} />
				<Box className={classes.drawerOpen}>
					<Box className={classes.header}>
						<Typography variant={"h3"} className={classes.headerText}>
							{t("menus")}
						</Typography>
					</Box>
					<List className={classes.menuList}>{menusElement}</List>
				</Box>
				<footer className={classes.footer}>
					{!business.menus && (
						<>
							<PlusIcon className={classes.plusIcon} onClick={handleAddMenu} />
							<Typography className={classes.footerText} onClick={handleAddMenu}>
								{t("addMenu")}
							</Typography>
						</>
					)}
				</footer>

				<Modal
					className={classes.modal}
					open={isOpenMenuGroupEditModal()}
					onClose={handleCloseAddingMenuGroup}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500
					}}
					disableBackdropClick
				>
					<Fade in={isOpenMenuGroupEditModal()}>
						<Box className={`${classes.paper} ${classes.editMenuGroupPaper}`}>
							<span className={classes.title}>{t("name")}</span>
							<OutlinedInput
								className={classes.nameInput}
								onChange={handleGroupNameChange}
								value={menuGroupAction?.menuGroupName}
								autoFocus
							/>
							<span className={classes.title}>{t("description")}</span>
							<OutlinedInput
								className={classes.menuGroupDescriptionInput}
								onChange={handleGroupDescriptionChange}
								value={menuGroupAction?.menuGroupDescription}
								multiline={true}
								rows={3}
							/>

							<Box className={classes.actions}>
								<Button className={classes.cancelButton} onClick={handleCloseAddingMenuGroup}>
									{t("cancel")}
								</Button>
								<Button
									className={classes.addButton}
									disabled={!menuGroupAction?.menuGroupName}
									onClick={async () => await saveMenuGroup()}
								>
									{t("save")}
								</Button>
							</Box>
						</Box>
					</Fade>
				</Modal>

				<Modal
					className={classes.modal}
					open={isOpenDeleteModal()}
					onClose={handleCloseDeleteModal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500
					}}
				>
					<Fade in={isOpenDeleteModal()}>
						<Box className={`${classes.paper} ${classes.deletePaper}`}>
							<Box className={classes.deleteTitleBox}>
								<Typography className={classes.deleteTitle}>{t("confirmToDeleteMenuGroup")}</Typography>
							</Box>
							<Box className={classes.deleteActions}>
								<Button className={classes.cancelButton} onClick={handleCloseDeleteModal}>
									{t("cancel")}
								</Button>
								<Button className={classes.deleteButton} onClick={async () => await handleDelete()}>
									{t("delete")}
								</Button>
							</Box>
						</Box>
					</Fade>
				</Modal>

				<Popover
					className={classes.popover}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					anchorEl={menuGroupAnchorEl}
					open={!!menuGroupAnchorEl}
				>
					<ClickAwayListener onClickAway={dismissMenuGroupActionsMenu}>
						<Fade in={!!menuGroupAnchorEl}>
							<Box className={classes.editBox}>
								<Typography className={classes.editAction} onClick={handleEditMenuGroup}>
									{t("edit")}
								</Typography>
								<Box className={classes.editActionBorderBottom}></Box>
								{menuGroupAction?.menuGroupIndex !== 0 && (
									<>
										<Typography className={classes.editAction} onClick={async () => await handleMoveUpMenuGroup()}>
											{t("up")}
										</Typography>
										<Box className={classes.editActionBorderBottom}></Box>
									</>
								)}

								{!!menuGroupAction?.menuId &&
									menuGroupAction?.menuGroupIndex != getMenu(menuGroupAction.menuId).menuGroups.length - 1 && (
										<>
											<Typography className={classes.editAction} onClick={async () => await handleMoveDownMenuGroup()}>
												{t("down")}
											</Typography>
											<Box className={classes.editActionBorderBottom}></Box>
										</>
									)}
								<Typography className={classes.editAction} onClick={handleDeleteMenuGroup}>
									{t("delete")}
								</Typography>
							</Box>
						</Fade>
					</ClickAwayListener>
				</Popover>

				<Modal
					className={classes.modal}
					open={isOpenMenuEditModal()}
					onClose={handleCloseMenuEditModal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500
					}}
				>
					<Fade in={isOpenMenuEditModal()}>
						<Box className={`${classes.paper} ${classes.editMenuPaper}`}>
							<span className={classes.title}>{t("name")}</span>
							<OutlinedInput
								className={classes.nameInput}
								onChange={handleMenuNameChange}
								value={menuAction?.menuName}
								autoFocus
							/>
							{/* <span className={classes.title}>{t("availableTime")}</span> */}

							<Box className={classes.actions}>
								<Button className={classes.cancelButton} onClick={handleCloseMenuEditModal}>
									{t("cancel")}
								</Button>
								<Button className={classes.addButton} onClick={saveMenu}>
									{t("save")}
								</Button>
							</Box>
						</Box>
					</Fade>
				</Modal>

				<Popover
					className={classes.popover}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					anchorEl={menuAnchorEl}
					open={!!menuAnchorEl}
				>
					<ClickAwayListener onClickAway={() => setMenuAnchorEl(null)}>
						<Fade in={!!menuAnchorEl}>
							<Box className={classes.editBox}>
								<Typography className={`${classes.editAction} ${classes.editActionBorderBottom}`}>
									{t("edit")}
								</Typography>
								<Typography className={classes.editAction} onClick={handleDeleteMenuGroup}>
									{t("delete")}
								</Typography>
							</Box>
						</Fade>
					</ClickAwayListener>
				</Popover>
			</Container>

			{!!selectedMenuGroup && (
				<MenuItemList
					menu={getMenu(selectedMenuGroup.menuId)}
					menuGroup={getSelectedMenuGroupData()}
					onMenuItemSelected={onMenuItemSelected}
					onMenuItemDeleted={onMenuItemDeleted}
					onMenuItemMoved={onMenuItemMoved}
				/>
			)}

			{!!selectedMenuItem && (
				<MenuItemConfig
					menuId={selectedMenuItem.menuId}
					menuGroupId={selectedMenuItem.menuGroupId}
					menuItem={selectedMenuItem.menuItem}
				/>
			)}
		</Box>
	);
};

export default MenusScreen;
