import React, { useState } from "react";

import qs from "querystring";

import { Box, Container, IconButton, LinearProgress, Paper, Slide, Snackbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";

import { useLocation, useNavigate } from "react-router-dom";

import { AuthTypes } from "api/types/enums";
import PageTitle from "components/PageTitle";
import TextField from "components/TextField";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import useForm from "hooks/useForm";
import ArrowRightIcon from "icons/ArrowRightIcon";
import LogoIcon from "icons/LogoIcon";

import validate from "./validate";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			height: "90vh",
			justifyContent: "center",
			paddingBottom: theme.spacing(5),
			position: "relative"
		},
		paper: {
			boxShadow: "none",
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(5)
		},
		main: {
			marginTop: 5,
			display: "flex",
			flexDirection: "column",
			flex: 1,
			height: "90vh",
			justifyContent: "center",
			paddingBottom: theme.spacing(5)
		},
		formTitle: {
			marginBottom: theme.spacing(10),
			textAlign: "center",
			alignContent: "center"
		},
		formTitleText: {
			textTransform: "uppercase"
		},
		formAction: {
			textAlign: "center",
			alignContent: "center",
			marginTop: theme.spacing(12)
		},
		iconLogo: {
			width: "200px"
		},
		icon: {
			width: "32px"
		},
		iconBack: {
			width: "32px",
			"-webkit-transform": "rotate(180deg)",
			"-ms-transform": "rotate(180deg)",
			transform: "rotate(180deg)"
		},
		username: {
			"::placeholder": {
				textTransform: "uppercase"
			}
		},
		progress: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0
		},
		otpInputContainer: {
			"& div": {
				flex: "1",
				display: "initial!important",
				width: "16.66%"
			}
		},
		otpInputStyle: {
			width: "42px",
			height: "3rem",
			margin: "0 16px",
			fontSize: "2rem",
			borderRadius: 0,
			border: "none",
			borderBottom: "0.5px solid #000000",
			"&:focus": {
				outline: "none"
			}
		}
	};
});

const LoginScreen = () => {
	const { locale } = useApp();
	const classes = useStyles();
	const { t } = useTranslation();
	const { startOtpLogin, validateOtpLogin } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const [isStarted, setIsStarted] = useState(false);
	const [authType, setAuthType] = useState<string | undefined>("");
	const [isUsernamePasted, setUsernamePasted] = React.useState(false);

	const OTP_CHARS_COUNT = 6;

	const { onChange, onSubmit, values, errors, isSubmitting, setIsSubmitting, setValue } = useForm(
		() => {},
		{
			userName: "",
			token: ""
		},
		validate
	);

	const onUsernamePaste = () => {
		setUsernamePasted(true);
	};

	const onUsernameChange = e => {
		onChange(e);
		if (isUsernamePasted) {
			values.userName = e.target.value;
			submitForm(null);
		}
		setUsernamePasted(false);
	};

	const onOtpChange = otp => {
		setValue({ token: otp });
		if (otp.length === 6) {
			values.token = otp;
			submitForm(null);
		}
	};

	const isSubmitDisabled =
		isSubmitting || !values.userName || (isStarted && (!values.token || values.token.length < OTP_CHARS_COUNT));

	function cancelForm() {
		setIsSubmitting(false);
		setIsStarted(false);
		setValue({ userName: "", token: "" });
	}

	async function submitForm(e) {
		onSubmit(e);

		if (!isSubmitting) {
			if (!isStarted) {
				await executeStartOtpLogin();
			} else {
				await executeValidateOtpLogin();
			}
		}
	}

	const executeStartOtpLogin = async () => {
		if (values.userName) {
			const data = await startOtpLogin(values.userName.trim());

			if (!data) {
				setAuthType(AuthTypes.serverError);
				setIsStarted(false);
			} else if (data.authType === AuthTypes.notFound) {
				setAuthType(AuthTypes.notFound);
				setIsStarted(false);
			} else {
				setAuthType(data?.authType);
				setIsStarted(true);
			}
		}
		setIsSubmitting(false);
	};

	const executeValidateOtpLogin = async () => {
		const validateResult = await validateOtpLogin(values.userName, values.token);
		if (validateResult) {
			// valid login
			setIsSubmitting(false);
			const query = qs.parse(location.search);
			const param = query?.redirect;
			const redirect = Array.isArray(param) ? param[0] : param;
			navigate(redirect || `/${locale}`);
		}
	};

	return (
		<Container className={classes.root}>
			<PageTitle pageTitle={t("businessLogin")} />
			{isSubmitting && <LinearProgress className={classes.progress} color={"secondary"} />}
			<form onSubmit={submitForm}>
				<Container className={classes.main}>
					<Slide in={!isStarted} direction="right" mountOnEnter unmountOnExit>
						<Paper className={classes.paper}>
							<Box className={classes.formTitle}>
								<LogoIcon className={classes.iconLogo}></LogoIcon>
								<Typography className={classes.formTitleText}>{t("businessLogin")}</Typography>
							</Box>
							<TextField
								autoFocus
								placeholder={t("userName").toUpperCase()}
								fullWidth
								required
								name="userName"
								id="userName"
								value={values.userName || ""}
								error={errors && errors.userName ? true : false}
								helperText={errors && errors.userName}
								className={classes.username}
								onChange={onUsernameChange}
								onPaste={onUsernamePaste}
							/>
							<Snackbar open={authType === AuthTypes.notFound} autoHideDuration={6000}>
								<Alert
									severity="error"
									onClose={() => {
										setAuthType(undefined);
									}}
								>
									{t("userNameNotExist")}
								</Alert>
							</Snackbar>
						</Paper>
					</Slide>
					<Slide in={isStarted} direction="right" mountOnEnter unmountOnExit>
						<Paper className={classes.paper}>
							<Box className={classes.formTitle}>
								<Typography className={classes.formTitleText}>{t("otpLoginText", { authType })}</Typography>
							</Box>
							<OtpInput
								value={values.token || ""}
								onChange={onOtpChange}
								numInputs={OTP_CHARS_COUNT}
								containerStyle={classes.otpInputContainer}
								inputStyle={classes.otpInputStyle}
								shouldAutoFocus={true}
								isInputNum={true}
								separator={<span></span>}
							/>
							{/* <TextField
								autoFocus
								placeholder={t("oneTimePassword")}
								fullWidth
								required
								name="token"
								id="token"
								value={values.token || ""}
								error={errors && errors.token ? true : false}
								helperText={errors && errors.token}
								onChange={onChange}
							/> */}
						</Paper>
					</Slide>
					<Box className={classes.formAction}>
						{isStarted && (
							<IconButton type="button" onClick={cancelForm}>
								<ArrowRightIcon className={classes.iconBack}></ArrowRightIcon>
							</IconButton>
						)}
						<IconButton type="submit" disabled={isSubmitDisabled} onClick={async e => await submitForm(e)}>
							<ArrowRightIcon className={classes.icon}></ArrowRightIcon>
						</IconButton>
					</Box>
				</Container>
			</form>
		</Container>
	);
};

export default LoginScreen;
