import React, { Suspense } from "react";

import { ApolloProvider } from "@apollo/client";
import { CssBaseline, ThemeProvider } from "@material-ui/core";

import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";

import { setEnv } from "utility/tracking";

import App from "./App";
import apolloClient from "./api/apolloClient";
import Loading from "./components/Loading";
import * as serviceWorker from "./serviceWorker";
import theme from "./styles/theme";

import "./i18n";
// import "./index.css";

setEnv("user", "session", "web", "chrome");

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<Loading />}>
			<ApolloProvider client={apolloClient}>
				<ThemeProvider theme={theme("light")}>
					<CssBaseline />
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</ThemeProvider>
			</ApolloProvider>
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
