import { useMutation as useMutationApollo } from "@apollo/client";

import { MutationHookOptions, MutationTuple } from "@apollo/client/react/types/types";

import IOperation from "api/IOperation";

const useMutation = <D, V>(mutation: IOperation<D, V>, options?: MutationHookOptions<D, V>): MutationTuple<D, V> => {
	return useMutationApollo(mutation.text, options);
	// return mutation.map && res.mutationResult ? { ...res, data: mutation.map(res.data) } : res;
};

export default useMutation;
