import React from "react";

import { TextField as MuiTextField, TextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => {
	return {
		textField: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1)
		}
	};
});

const TextField: React.FC<TextFieldProps> = props => {
	const classes = useStyles();

	return <MuiTextField {...props} className={clsx(classes.textField, props.className)} fullWidth variant="standard" />;
};

export default TextField;
