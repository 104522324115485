import React, { useEffect } from "react";

import { Box, Container, Fab, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import * as R from "ramda";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import BusinessOrdersQuery from "api/queries/BusinessOrdersQuery";
import Loading from "components/Loading";
import PageTitle from "components/PageTitle";
import DesktopContainerDrawer from "containers/DesktopContainerDrawer";
import MobileContainerDrawer from "containers/MobileContainerDrawer";
import { DesktopOrderDetailDrawer, MobileOrderDetailDrawer } from "containers/OrderDetailScreen";
import { OrderStatuses, OrdersSort } from "gql/types/globals";
import { BusinessOrdersQuery_business_orders } from "gql/types/queries/BusinessOrdersQuery";
import useBusiness from "hooks/useBusiness";
import useLazyQuery from "hooks/useLazyQuery";
import useQuery from "hooks/useQuery";

import OrderListItem from "../OrderListScreen/OrderListItem";
import OrderListItemTablet from "../OrderListScreen/OrderListItemTablet";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			margin: 0,
			paddingTop: 0,
			textAlign: "center",
			[theme.breakpoints.up(769)]: {
				paddingLeft: 0,
				paddingRight: 0
			},
			[theme.breakpoints.up(1280)]: {
				maxWidth: "initial"
			}
		},
		drawerClose: {
			width: "100%",
			maxWidth: "auto"
		},
		drawerOpen: {
			// width: "742px",
			maxWidth: "742px",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up(480)]: {
				width: "100%"
			},
			[theme.breakpoints.up(769)]: {
				width: "40%"
			}
			// [theme.breakpoints.up(1580)]: {
			//  	width: "20%",
			//  	maxWidth: "742px"
			// }
		},
		orderGroup: {
			background: "#E0E0E0",
			padding: theme.spacing(2),
			flexDirection: "row",
			display: "flex",
			justifyContent: "space-between"
		},
		orderGroupHeader: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px",
			fontWeight: "bold"
		},
		orderGroupHeaderCounter: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px"
		},
		nextButton: {
			height: theme.spacing(6),
			background: "#828282",
			borderRadius: theme.spacing(3),
			padding: theme.spacing(1.5),
			margin: theme.spacing(2)
		},
		nextButtonTitle: {
			color: "#F2F2F2",
			fontSize: "18px",
			lineHeight: "22px",
			textTransform: "uppercase",
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4)
		},
		titleContainer: {
			margin: theme.spacing(4)
		},
		title: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px"
		}
	};
});

const OrderListScreen = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { business } = useBusiness();
	const navigate = useNavigate();

	const [pageSkip, setPageSkip] = React.useState<number>(0);
	const [orders, setOrders] = React.useState<BusinessOrdersQuery_business_orders[]>([]);
	const pageLength = 50;

	const nextPage = () => {
		setPageSkip(pageSkip + pageLength);
		query();
	};

	// const { data, loading, refetch } = useQuery);

	const [query, { loading }] = useLazyQuery(BusinessOrdersQuery, {
		variables: {
			businessInput: {
				businessId: business?.id
			},
			ordersInput: {
				orderStatuses: [
					OrderStatuses.placed,
					OrderStatuses.confirmed,
					OrderStatuses.ready,
					OrderStatuses.completed,
					OrderStatuses.cancelledByBusiness
				],
				skip: 0,
				take: pageSkip + pageLength,
				placedAt: {
					lt: DateTime.local().startOf("day")
				},
				sortBy: [OrdersSort.placedAtDescNullsLast]
			}
		},
		onCompleted: data => {
			if (data && data.business && data && data.business && data.business.orders.length > 0) {
				setOrders([...data.business!.orders]);
			}
		}
	});

	useEffect(() => {
		query();
	}, []);

	// if (loading) {
	// 	return <Loading />;
	// }

	let orderGroups: { title: string; orders: BusinessOrdersQuery_business_orders[] }[] = [];
	const isEnded = orders.length === 0 || orders.length < pageSkip + pageLength;

	if (orders) {
		const days = R.uniq(orders.map(a => DateTime.fromISO(a.placedAt).startOf("day")));

		orderGroups = days.map(day => ({
			title: day.toFormat("LLL dd"),
			orders: orders.filter(a => +day === +DateTime.fromISO(a.placedAt).startOf("day"))
		}));
	}

	const onUpdateOrder = async () => {
		query();
		navigate("#");
	};

	return (
		<Container className={classes.root}>
			<PageTitle pageTitle={t("orders")} />

			<List className={classes.drawerOpen}>
				{orderGroups.length === 0 ? (
					<Box className={classes.titleContainer}>
						{!loading && <Typography className={classes.title}>{t("noOrders")}</Typography>}
						{loading && <Typography className={classes.title}>{t("loading")}</Typography>}
					</Box>
				) : null}
				{orderGroups.map(group => (
					<Box key={group.title}>
						<Box className={classes.orderGroup}>
							<Typography variant={"h3"} className={classes.orderGroupHeader}>
								{group.title}
							</Typography>
							<Typography variant={"h3"} className={classes.orderGroupHeaderCounter}>
								{t("orderCount", { count: group.orders.length })}
							</Typography>
						</Box>
						{group.orders.map(order => (
							<Box key={order.id}>
								<DesktopContainerDrawer>
									<OrderListItemTablet order={order} />
								</DesktopContainerDrawer>
								<MobileContainerDrawer>
									<OrderListItem order={order} />
								</MobileContainerDrawer>
							</Box>
						))}
					</Box>
				))}
				{!isEnded && (
					<Fab onClick={nextPage} className={classes.nextButton} variant="extended">
						<Typography className={classes.nextButtonTitle}>{t("loadNext")}</Typography>
					</Fab>
				)}
			</List>
			<DesktopContainerDrawer>
				<DesktopOrderDetailDrawer onUpdate={onUpdateOrder} />
			</DesktopContainerDrawer>
			<MobileContainerDrawer>
				<MobileOrderDetailDrawer />
			</MobileContainerDrawer>
		</Container>
	);
};

export default OrderListScreen;
