// Get/Set accessToken In-memory

const ACCESS_TOKEN = "at";

const listeners: ((token: string | null) => void)[] = [];

export const setAccessToken = (token: string | null): void => {
	if (token) {
		localStorage.setItem(ACCESS_TOKEN, token);
	} else {
		localStorage.removeItem(ACCESS_TOKEN);
	}

	listeners.forEach(x => x(token));
};

export const getAccessToken = (): string | null => {
	return localStorage.getItem(ACCESS_TOKEN);
};

export const subscribeToAccessToken = (callback: (token: string | null) => void): void => {
	listeners.push(callback);
};
