import { ContextType, useContext, useMemo } from "react";

import * as R from "ramda";

import BusinessesContext from "contexts/BusinessesContext";

import useLocalize from "./useLocalize";

const useBusinesses = (): ContextType<typeof BusinessesContext> => {
	const context = useContext(BusinessesContext);
	const { localize } = useLocalize();

	const sortedBusinesses = useMemo(() => R.sortBy(x => localize(x.localeNames, x.name), context?.businesses || []), [
		context.businesses,
		localize
	]);

	const memoizedContext = useMemo(
		() => ({
			...context,
			businesses: sortedBusinesses
		}),
		[context, sortedBusinesses]
	);

	return memoizedContext;
};

export default useBusinesses;
