import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationMoveUpMenuGroupInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation MoveUpMenuGroup($moveUpMenuGroupInput: MutationMoveUpMenuGroupInput!) {
	moveUpMenuGroup(moveUpMenuGroupInput: $moveUpMenuGroupInput) {
        success
    }
}
`;

const MoveUpMenuGroup: IOperation<MutationUpdateMenusResult, MutationMoveUpMenuGroupInput> = { text } as any;

export default MoveUpMenuGroup;