import { gql } from "@apollo/client";
import IOperation from "api/IOperation";
import { MutationUpdateBusinessInput , MutationUpdateBusinessResult, BusinessType } from "gql/types/mutations/BusinessUpdate";

const text = gql`mutation UpdateBusiness($updateBusinessInput: MutationUpdateBusinessInput!) {
	updateBusiness(updateBusinessInput: $updateBusinessInput) {
		business {
            id
            defaultPreparationTimeMinutes
            orderHours {
                hours {
                    daysOfWeek
                }
                exceptions {
                    startsAt
                    endsAt
                }
            }
            pause
            menus {
                id
                slug
                name
                description
                localeNames
                imageUrl
                localeNames
                activateAt
                deactivateAt
                menuGroups {
                    id
                    slug
                    name
                    description
                    displayOrder
                    localeNames
                    activateAt
                    deactivateAt
                    availability {
                        hours {
                            daysOfWeek
                            intervals {
                                startTime
                                endTime
                            }
                        }
                        exceptions {
                            startsAt
                            endsAt
                        }
                    }
                    menuItems {
                        id
                        itemCode
                        name
                        description
                        localeNames
                        priceCents
                        displayPrice
                        imageUrl
                        iconUrl
                        preparationTimeMinutes
                        activateAt
                        deactivateAt
                        disableSpecialInstructions
                        requiresIDCheck

                        menuItemOptionGroups {
                            id
                            slug
                            name
                            localeNames
                            description
                            displayOrder
                            minCount
                            maxCount
                            activateAt
                            deactivateAt
                            menuItemOptions {
                                id
                                itemCode
                                name
                                description
                                localeNames
                                iconUrl
                                imageUrl
                                priceCents
                                displayPrice
                                preparationTimeMinutes
                            }
                        }
                    }
                }
			}
        }
	}
}
`;

const UpdateBusiness: IOperation<MutationUpdateBusinessResult, MutationUpdateBusinessInput> = { text } as any;

export default UpdateBusiness;