import React from "react";

import { Box, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import makeStyles from "hooks/makeStyles";
import useBusiness from "hooks/useBusiness";

const minimumSize = 16;
const maximumSize = 20;
const minimumViewportWidth = 140;
const maxViewportWidth = 600;

const useStyles = makeStyles(theme => ({
	title: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		alignItems: "center",
		maxWidth: "calc(100vw - 120px)",
		marginRight: theme.spacing(1)
	},
	titleText: {
		verticalAlign: "center",
		whiteSpace: "nowrap",
		maxWidth: 300,
		fontWeight: 500,
		fontSize: `calc(${minimumSize}px + (${maximumSize} - ${minimumSize}) * ((100vw - ${minimumViewportWidth}px) / (${maxViewportWidth} - ${minimumViewportWidth})))`,
		textTransform: "uppercase"
	},
	pausing: {
		color: theme.palette.warning.main
	}
}));

interface Props {
	businessName: string;
}

const BusinessHeader = ({ businessName }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { business } = useBusiness();

	return (
		<>
			<Helmet>
				<title>{`${businessName} | ${t("pageTitle")}`}</title>
			</Helmet>
			<Box className={classes.title}>
				<Typography className={`${classes.titleText} ${business?.pause ? classes.pausing : ""} `}>
					{businessName}
				</Typography>
			</Box>
		</>
	);
};

export default BusinessHeader;
