import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { OrderQuery as OrderQueryType, OrderQueryVariables } from "gql/types/queries/OrderQuery";

const text = gql`query OrderQuery($orderInput: QueryOrderInput!) {
	order(orderInput: $orderInput) {
		id
		orderStatus
		orderNumber
		placedAt
		preparationStartAt
		deliveryOrPickupAt
		deliveryAddress {
			address1
			address2
			address3
			city
			state
			postalCode
		}
		deliveryChargeCents
		taxRate
		orderSubTotalCents,
		discount,
		taxCents
		tipCents
		orderTotalCents
		phoneNumber
		displayPhoneNumber
		orderType {
			id
			orderType
			name
			description
		}

		business {
			id
		}

		customer {
			fullName
		}

		orderItems {
			id
			quantity
			unitPriceCents
			extendedPriceCents
			specialInstructions

			menuItem {
				id
				itemCode
				name
				localeNames
				description
				priceCents
				displayPrice
				imageUrl
			}

			selectedMenuItemOptionGroups {
				menuItemOptionGroupId
				name
				localeNames

				selectedMenuItemOptions {
					menuItemOptionId
					name
					localeNames
					priceCents
					displayPrice
				}
			}
		}
	}
}
`;

const OrderQuery: IOperation<OrderQueryType, OrderQueryVariables> = { text } as any;

export default OrderQuery;
