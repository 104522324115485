import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { CompleteOrder as CompleteOrderType, CompleteOrderVariables } from "gql/types/mutations/CompleteOrder";

const text = gql`mutation CompleteOrder($updateOrderCompletedInput: MutationUpdateOrderCompletedInput!) {
	updateOrderCompleted(updateOrderCompletedInput: $updateOrderCompletedInput) {
		order {
			id
			orderStatus
		}
	}
}
`;

const CompleteOrder: IOperation<CompleteOrderType, CompleteOrderVariables> = { text } as any;

export default CompleteOrder;
