import React, { ContextType, ReactNode, useMemo, useState } from "react";

import StateOf from "utility/StateOf";

import AuthContext from "./AuthContext";

type Context = ContextType<typeof AuthContext>;
type State = StateOf<Context>;

const initialState: State = {
	userId: null,
	user: null,
	businessRoles: null
};

const initializer = (): State => {
	try {
		const json = localStorage.getItem("hfa");

		if (json) {
			const state = JSON.parse(json);
			return state;
		}
	} catch (exc) {
		console.warn("failed to restore auth state from local storage:\r\n", exc);
	}

	return initialState;
};

interface Props {
	children: ReactNode;
}

const AuthContextProvider = ({ children }: Props) => {
	const [state, setState] = useState(initializer());

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
