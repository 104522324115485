import { gql } from "@apollo/client";

import IOperation from "api/IOperation";

import { QueryTypicalOptionGroupsInput, QueryTypicalOptionGroupsResult} from "gql/types/queries/MenuQuery";

const text = gql` query TypicalOptionGroups($queryTypicalOptionGroupsInput: QueryTypicalOptionGroupsInput!) {
    typicalOptionGroups(queryTypicalOptionGroupsInput: $queryTypicalOptionGroupsInput) {
        optionGroups {
            id
            name
            description
            localeNames
            minCount
            maxCount
            menuItemOptions {
                id
                name
                description
                outOfStock
                priceCents
                imageUrl
            }
        }
    }
}
`;

const TypicalOptionGroupsQuery: IOperation<QueryTypicalOptionGroupsResult, QueryTypicalOptionGroupsInput> = { text } as any;

export default TypicalOptionGroupsQuery;