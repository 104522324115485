import React from "react";

import { Box, Container, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import Price from "components/Price/Price";
import { OrderStatuses } from "gql/types/globals";
import { BusinessOrdersQuery_business_orders } from "gql/types/queries/BusinessOrdersQuery";
import DiscIcon from "icons/DiscIcon";
import OpenDetailsIcon from "icons/OpenDetailsIcon";

const useStyles = makeStyles(theme => {
	return {
		root: {
			position: "relative",
			borderBottom: "1px solid #888"
		},
		orderDetails: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "center",
			paddingLeft: theme.spacing(1),
			paddingTop: theme.spacing(3),
			paddingRight: theme.spacing(7),
			paddingBottom: theme.spacing(3),
			textAlign: "left"
		},
		orderDetailsInfo: {
			display: "flex",
			flexDirection: "row",
			flex: 1
		},
		orderDeatilsButton: {
			position: "absolute",
			top: "50%",
			right: theme.spacing(0),
			marginTop: -theme.spacing(2)
		},
		orderDeatilsIcon: {
			width: theme.spacing(4),
			height: theme.spacing(4)
		},
		link: {
			color: theme.palette.primary.main
		},
		name: {
			textTransform: "uppercase",
			fontWeight: 500,
			fontSize: "18px"
		},
		icon: {
			position: "absolute",
			top: theme.spacing(4),
			left: theme.spacing(0),
			width: theme.spacing(1),
			height: theme.spacing(1)
		},
		text: {
			textTransform: "uppercase",
			paddingRight: theme.spacing(0.5),
			fontSize: "16px"
		},
		textStrong: {
			textTransform: "uppercase",
			fontWeight: 500,
			paddingRight: theme.spacing(0.5),
			fontSize: "16px"
		},
		textSmall: {
			fontSize: "12px",
			paddingRight: theme.spacing(0.5),
			width: "96px"
		},
		textSmallStrong: {
			fontSize: "12px",
			fontWeight: 500,
			paddingRight: theme.spacing(0.5)
		},
		textDivider: {
			fontSize: "16px",
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(1)
		},
		textSmallDivider: {
			fontSize: "12px",
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(1)
		},
		orderPlaced: {
			textTransform: "uppercase",
			paddingTop: theme.spacing(1),
			display: "flex",
			flex: 1
		},
		orderStatus: {
			textTransform: "uppercase",
			paddingTop: theme.spacing(1),
			fontSize: theme.spacing(2)
		}
	};
});

const OrderListItem = ({ order }: { order: BusinessOrdersQuery_business_orders }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const openOrder = (orderId: string) => {
		navigate(`#orderid:${orderId}`);
	};

	const totalItemsCount = order.orderItems.reduce((a, b) => a + b.quantity, 0);

	return (
		<Container className={classes.root}>
			{order.orderStatus === OrderStatuses.placed && <DiscIcon className={classes.icon} />}
			<Box className={classes.orderDetails}>
				<Typography variant={"h3"} className={classes.name}>
					{order.customer?.fullName}
				</Typography>
				<Box className={classes.orderDetailsInfo}>
					<Price className={classes.textStrong} priceCents={order.orderTotalCents} />
					<Typography className={classes.textDivider}>•</Typography>
					<Typography className={classes.textStrong}>{t("item", { count: totalItemsCount })}</Typography>
				</Box>
				<Box className={classes.orderPlaced}>
					<Typography className={classes.textSmall}>{t("orderPlaced")} </Typography>
					<Typography className={classes.textSmallStrong}>
						{DateTime.fromISO(order.placedAt).toFormat("LLL dd")}
					</Typography>
					<Typography className={classes.textSmallDivider}>•</Typography>
					<Typography className={classes.textSmallStrong}>{DateTime.fromISO(order.placedAt).toFormat("t")}</Typography>
				</Box>
				{order.deliveryOrPickupAt && (
					<Box className={classes.orderPlaced}>
						<Typography className={classes.textSmall}>{t("readyFor")} </Typography>
						<Typography className={classes.textSmallStrong}>
							{DateTime.fromISO(order.deliveryOrPickupAt).toFormat("LLL dd")}
						</Typography>
						<Typography className={classes.textSmallDivider}>•</Typography>
						<Typography className={classes.textSmallStrong}>
							{DateTime.fromISO(order.deliveryOrPickupAt).toFormat("t")}
						</Typography>
					</Box>
				)}
				<Typography className={classes.orderStatus} variant="h4">
					{t(order.orderStatus)}
				</Typography>
			</Box>
			<IconButton onClick={() => openOrder(order.id)} className={classes.orderDeatilsButton}>
				<OpenDetailsIcon className={classes.orderDeatilsIcon} />
			</IconButton>
		</Container>
	);
};

export default OrderListItem;
