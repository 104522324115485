import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

declare global {
	interface Window {
		dataLayer?: any;
	}
}

const useTracking = () => {
	const location = useLocation();

	return {
		trackPageEvent: (pageTitle: string) => {
			if (TagManager) {
				window.dataLayer.push({
					event: "customPage",
					pageTitle: pageTitle,
					page_path: location.pathname + location.search + location.hash
				});
			}
		},
		trackEvent: event => {
			if (TagManager) {
				window.dataLayer.push(event);
			}
		}
	};
};

export default useTracking;
