export enum modes {
	DEMO = 1,
	BETA = 2
}

const config = {
	mode: modes.DEMO,
	PASSWORD_MIN_LENGTH: 6,
	PLACES_AUTOCOMPLETE_RADIUS_MILES: process.env.REACT_APP_PLACES_AUTOCOMPLETE_RADIUS_MILES
		? parseFloat(process.env.REACT_APP_PLACES_AUTOCOMPLETE_RADIUS_MILES)
		: 10,
	googleAnalyticsKey: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
	googleTagManagerKey: process.env.REACT_APP_GOOGLE_TAG_MGR_ID,
	TOKEN_STORAGE_KEY: "at",
	FACEBOOK_APP_ID: process.env.REACT_APP_FB_APP_ID || "undefined",
	GOOGLE_APP_ID: process.env.REACT_APP_GOOGLE_APP_ID,

	notificationSound:
		process.env.REACT_APP_NOTIFICATION_SOUND ||
		"https://hyperflyer-ordering-system.s3-us-west-2.amazonaws.com/order-notification.mp3",
	apiUrl: process.env.REACT_APP_API_URI!,
	wsApiUrl: process.env.REACT_APP_GQL_WS_URI!
};

export default config;
