import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { ConfirmOrder as ConfirmOrderType, ConfirmOrderVariables } from "gql/types/mutations/ConfirmOrder";

const text = gql`mutation ConfirmOrder($confirmOrderInput: MutationConfirmOrderInput!) {
	confirmOrder(confirmOrderInput: $confirmOrderInput) {
		order {
			id
			orderStatus
		}
	}
}
`;

const ConfirmOrder: IOperation<ConfirmOrderType, ConfirmOrderVariables> = { text } as any;

export default ConfirmOrder;
