import React, { useState } from "react";

import { Route, Routes, useNavigate, useParams } from "react-router";

import Layout from "components/Layout";
import Loading from "components/Loading";
import NewOrderSplash from "components/NewOrderSplash";
import HistoryOrdersListScreen from "containers/HistoryOrdersListScreen";
import MenusScreen from "containers/MenusScreen";
import OrderListScreen from "containers/OrderListScreen";
import BusinessContextProvider from "contexts/BusinessContextProvider";
import { OrderUpdatesSubscription_orderUpdates_order } from "gql/types/subscriptions/OrderUpdatesSubscription";
import useApp from "hooks/useApp";
import useBusiness from "hooks/useBusiness";
import useBusinesses from "hooks/useBusinesses";

const BusinessAppRoutes = () => {
	const { locale } = useApp();
	const { business } = useBusiness();
	const navigate = useNavigate();
	const [newOrders, setNewOrders] = useState<OrderUpdatesSubscription_orderUpdates_order[] | null>(null);

	//TODO: uncomment me
	// const { data: businessOrders, loading, refetch } = useQuery(BusinessOrdersQuery, {
	// 	variables: {
	// 		businessInput: {
	// 			businessId: business?.id
	// 		},
	// 		ordersInput: {
	// 			orderStatuses: [
	// 				OrderStatuses.placed,
	// 				OrderStatuses.confirmed,
	// 				OrderStatuses.ready,
	// 				OrderStatuses.completed,
	// 				OrderStatuses.cancelledByBusiness
	// 			],
	// 			skip: 0,
	// 			take: 50,
	// 			sortBy: [OrdersSort.placedAtAscNullsFirst]
	// 		}
	// 	}
	// });

	// useSubscription(OrderUpdatesSubscription, {
	// 	variables: {
	// 		orderUpdatesInput: {
	// 			businessId: business?.id
	// 		}
	// 	},
	// 	onSubscriptionData: async options => {
	// 		if (options.subscriptionData) {
	// 			// getting only new orders. maybe we should have separate subscription (OrderAddedSubscription)?
	// 			const newOrders = options.subscriptionData!.data!.orderUpdates.filter(
	// 				a =>
	// 					a.order.orderStatus === OrderStatuses.placed &&
	// 					(!businessOrders ||
	// 						!businessOrders.business ||
	// 						!businessOrders.business?.orders?.find(b => b.id === a.order.id))
	// 			);
	// 			setNewOrders(newOrders.length > 0 ? newOrders.map(a => a.order) : null);
	// 			if (!loading) await refetch();
	// 		}
	// 	}
	// });

	// if (loading) {
	// 	return <Loading />;
	// }

	return (
		<>
			{newOrders && newOrders?.length > 0 && (
				<NewOrderSplash
					newOrders={newOrders}
					onClose={() => {
						setNewOrders(null);
						navigate(`/${encodeURIComponent(locale)}/${encodeURIComponent(business?.slug || "")}/orders`);
					}}
				/>
			)}
			<Routes>
				<Route path="/" element={<OrderListScreen />} />
				<Route path="/orders" element={<OrderListScreen />} />
				<Route path="/menus" element={<MenusScreen />} />
				<Route path="/history" element={<HistoryOrdersListScreen />} />
			</Routes>
		</>
	);
};

const BusinessAppWrapper = () => {
	const { loading, business } = useBusiness();
	const params = useParams();

	if (loading) {
		return <Loading />;
	}

	if (!business) {
		return <h3>Business {params.slug} not found</h3>;
	}

	return <BusinessAppRoutes />;
};

const BusinessApp = () => {
	const { businesses } = useBusinesses();
	const params = useParams();

	const businessId = businesses?.find(x => x.slug === params.slug)?.id;

	return (
		<BusinessContextProvider businessId={businessId}>
			<Layout>
				<BusinessAppWrapper />
			</Layout>
		</BusinessContextProvider>
	);
};

export default BusinessApp;
