import React, { ContextType, ReactNode, useEffect, useMemo } from "react";

import BusinessesQuery from "api/queries/BusinessesQuery";
import useAuth from "hooks/useAuth";
import useQuery from "hooks/useQuery";

import BusinessesContext from "./BusinessesContext";

const BusinessesContextProvider = ({ children }: { children: ReactNode }) => {
	const { userId, user } = useAuth();

	const { loading, data, refetch } = useQuery(BusinessesQuery, {
		variables: {
			businessesInput: {
				skip: 0,
				take: 100
			}
		}
	});

	useEffect(() => {
		refetch();
	}, [userId, user]);

	const context = useMemo<ContextType<typeof BusinessesContext>>(
		() => ({
			loading,
			businesses: data?.businesses || []
		}),
		[data]
	);

	return <BusinessesContext.Provider value={context}>{children}</BusinessesContext.Provider>;
};

export default BusinessesContextProvider;
