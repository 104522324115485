import { createContext } from "react";

import { ApolloQueryResult } from "@apollo/client";

import Business from "api/types/Business";

interface BusinessContextProps {
	loading: boolean;
	business: null | Business;
	refetch: () => Promise<ApolloQueryResult<any>>;
}

interface Methods {}

const BusinessContext = createContext<BusinessContextProps & Methods>(undefined as any);

export default BusinessContext;
