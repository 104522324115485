import React, { useMemo } from "react";

import clsx from "clsx";
import { useLocation, useNavigate } from "react-router";

import RouteDrawer, { RouteDrawerProps } from "components/RouteDrawer";
import makeStyles from "hooks/makeStyles";

import OrderDetailScreen from "./OrderDetailScreen";

const useStyles = makeStyles(theme => ({
	root: {},
	paper: {
		display: "flex",
		height: "100vh",
		// width: "100%",
		[theme.breakpoints.up(480)]: {
			height: "100vh",
			borderRadius: "0",
			// width: "80%",
			maxWidth: "442px",
			left: "auto",
			backgroundColor: theme.palette.background.paper,
			padding: 0,
			color: theme.palette.text.primary
		}
	},
	drawerOpen: {
		width: "100%",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		[theme.breakpoints.up(480)]: {
			width: "80%"
		},
		[theme.breakpoints.up(769)]: {
			width: "40%"
		}
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: 0
	},
	backdrop: {
		// backgroundColor: "rgba(0,0,0,0.1)",
		// [theme.breakpoints.up(480)]: {
		// 	backgroundColor: "transparent",
		// 	WebkitBackdropFilter: "blur(1.8px)",
		// 	backdropFilter: "blur(1.8px)"
		// }
	}
}));

type Props = Omit<RouteDrawerProps, "swipeable" | "matchRoute">;

const OrderDetailDrawer = ({ className, ...otherProps }: Props) => {
	const navigate = useNavigate();
	const { hash } = useLocation();
	const classes = useStyles();

	const close = useMemo(
		() => () => {
			navigate(-1);
		},
		[navigate]
	);

	const props: RouteDrawerProps = useMemo(
		() =>
			Object.assign(
				{
					className: clsx(classes.root, className),
					classes: {
						paper: clsx({
							[classes.paper]: true,
							[classes.drawerOpen]: hash.startsWith("#orderid:"),
							[classes.drawerClose]: !hash.startsWith("#orderid:")
						})
					},
					BackdropProps: {
						className: classes.backdrop
					},
					anchor: "right",
					variant: "permanent",
					matchRoute: ({ location: { hash } }) => hash.startsWith("#orderid:"),
					onClose: close
				},
				otherProps
			),
		[className, classes.backdrop, classes.paper, classes.root, close, otherProps]
	);

	const orderId = hash.substr("#orderid:".length);

	return <RouteDrawer {...props}>{orderId && <OrderDetailScreen onClose={close} orderId={orderId} />}</RouteDrawer>;
};

export default OrderDetailDrawer;
