import React, { useMemo, useState } from "react";

import {
	Backdrop,
	Box,
	Button,
	ClickAwayListener,
	Container,
	Fade,
	InputLabel,
	List,
	MenuItem as MaterialUIMenuItem,
	Modal,
	OutlinedInput,
	Popover,
	Select,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AddMenuItem from "api/mutations/AddMenuItem";
import MoveDownMenuItem from "api/mutations/MoveDownMenuItem";
import MoveMenuItem from "api/mutations/MoveMenuItem";
import MoveUpMenuItem from "api/mutations/MoveUpMenuItem";
import RemoveMenuItem from "api/mutations/RemoveMenuItem";
import Menu from "api/types/Menu";
import MenuGroup from "api/types/MenuGroup";
import MenuItem from "api/types/MenuItem";
import PageTitle from "components/PageTitle";

import useBusiness from "hooks/useBusiness";
import useMutation from "hooks/useMutation";
import useTranslation from "hooks/useTranslation";
import EditIcon from "icons/EditIcon";
import PlusIcon from "icons/PlusIcon";

const border = "0.5px solid #828282";
const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			height: "100%",
			flexDirection: "column",
			flex: 1,
			margin: 0,
			padding: 0,
			textAlign: "center",
			color: "#4F4F4F",
			[theme.breakpoints.up(480)]: {
				width: "100%",
				maxWidth: "100%"
			},
			[theme.breakpoints.up(769)]: {
				width: "30%",
				maxWidth: "30%",
				borderRight: border
			}
		},
		drawerOpen: {
			height: "100%",
			overflowY: "scroll",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			"&::-webkit-scrollbar": {
				width: 0,
				height: 0
			},
			scrollbarWidth: "none",
			"-ms-overflow-style": "none"
		},
		header: {
			background: "#F0F0F0",
			padding: theme.spacing(2),
			flexDirection: "row",
			display: "flex",
			justifyContent: "space-between",
			alignContent: "center",
			alignItems: "center",
			height: 64,
			borderBottom: border
		},
		headerText: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px",
			fontWeight: "bold"
		},
		menuItemList: {
			paddingTop: 0,
			paddingBottom: 0
		},
		selected: {
			background: "#F8F8F8",
			fontStyle: "italic"
		},
		footer: {
			flexDirection: "row",
			display: "flex",
			justifyContent: "start",
			alignContent: "center",
			alignItems: "center",
			height: 48,
			minHeight: 48,
			padding: theme.spacing(2),
			backgroundColor: "#E0E0E0",
			width: "100%",
			[theme.breakpoints.up(769)]: {
				borderTop: border,
				borderRight: "none",
				backgroundColor: "#FFFFFF"
			}
		},
		plusIcon: {
			width: 20,
			height: 20,
			cursor: "pointer"
		},
		footerText: {
			textTransform: "uppercase",
			fontWeight: 700,
			fontSize: 18,
			marginLeft: 20,
			cursor: "pointer"
		},
		menuItems: {},
		menuItem: {
			display: "flex",
			justifyContent: "space-between",
			alignContent: "center",
			alignItems: "center",
			width: "100%",
			height: 48,
			borderBottom: border,
			"&:hover": {
				background: "#F8F8F8"
			}
		},
		menuItemName: {
			fontSize: 14,
			marginLeft: 32,
			maxWidth: "75%",
			overflow: "hidden",
			textOverflow: "ellipsis",
			textAlign: "left"
		},
		editMenuItemBox: {
			width: 24,
			height: 24,
			marginRight: 16,
			borderRadius: 12,
			background: "#F2F2F2",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			cursor: "pointer"
		},
		editMenuItemIcon: {
			width: 16,
			height: 16
		},
		popover: {
			"& .MuiPaper-elevation8": {
				boxShadow: "none"
			},
			"& .MuiPaper-rounded": {
				borderRadius: 0
			}
		},
		editBox: {
			// width: 100,
			border: "0.5px solid #4A4A4A",
			boxSizing: "border-box",
			overflow: "hidden"
		},
		editAction: {
			// width: 100,
			padding: "0 16px",
			height: 32,
			display: "flex",
			alignItems: "center",
			textAlign: "center",
			justifyContent: "center",
			fontSize: 14,
			fontWeight: 700,
			textTransform: "capitalize",
			"&:hover": {
				color: "#C0C0C0"
			},
			cursor: "pointer"
		},
		editActionBorderBottom: {
			borderBottom: "0.5px solid #C0C0C0"
		},
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		fade: {
			borderWidth: 0,
			boxShadow: "none",
			borderRadius: 0
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(2),
			boxShadow: "none",
			outline: 0,
			border: "0.5px solid #4A4A4A"
		},
		paperRow: {
			width: "100%"
		},
		optionRow: {
			marginBottom: theme.spacing(2)
		},
		elementGap: {
			marginBottom: theme.spacing(1)
		},
		editMenuItemPaper: {
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 512
		},
		title: {
			fontSize: 14,
			fontWeight: 700,
			textTransform: "uppercase",
			height: 28
		},
		nameInput: {
			borderRadius: 0,
			marginBottom: theme.spacing(1)
		},
		actions: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexDirection: "row",
			marginTop: 56
		},
		cancelButton: {
			color: "#4C4C4C",
			borderRadius: 0,
			marginRight: 8,
			width: 100
		},
		addButton: {
			background: "#00B09A",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		deletePaper: {
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 400
		},
		deleteTitleBox: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			justifyContent: "center",
			width: "100%"
		},
		deleteTitle: {
			width: 240,
			fontSize: 24,
			fontWeight: 700,
			marginTop: 36,
			textTransform: "uppercase"
		},
		deleteActions: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			flexDirection: "row",
			marginTop: 56
		},
		deleteButton: {
			background: "#E42306",
			color: "#fff",
			borderRadius: 0,
			width: 100,
			"&:hover": {
				color: "#4C4C4C"
			}
		},
		moveMenuItemPaper: {
			display: "flex",
			alignItems: "left",
			flexDirection: "column",
			width: 400
		},
		formControlLabel: {
			marginRight: theme.spacing(6),
			"& .MuiTypography-body1": {
				fontSize: 14
			},
			textTransform: "capitalize"
		},
		modifierSelect: {
			borderRadius: 0,
			height: 40,
			width: "75%",
			fontSize: 14
		},
		modifierSelectOption: {
			fontSize: 14
		}
	};
});

export interface MenuItemListProps {
	menu: Menu;
	menuGroup: MenuGroup;
	onMenuItemSelected: (menuId: string, menuGroupId: string, menuItem: MenuItem) => void;
	onMenuItemDeleted: (menuId: string, menuGroupId: string, menuItemId: string) => void;
	onMenuItemMoved: (menuId: string, menuGroupId: string, menuItemId: string) => void;
}

enum MenuItemActionType {
	"none" = "NONE",
	"add" = "ADD",
	"update" = "UPDATE",
	"delete" = "DELETE",
	"move" = "MOVE",
	"up" = "UP",
	"down" = "DOWN"
}

interface MenuItemAction {
	type: MenuItemActionType;
	menuItemId?: string;
	menuItemName?: string;
	menuItemIndex?: number;
}

const MenuItemList: React.FC<MenuItemListProps> = props => {
	const { t } = useTranslation();
	const classes = useStyles();
	const menuItems = props.menuGroup.menuItems;
	const [menuItemAction, setMenuItemAction] = useState<MenuItemAction>();
	const [anchorEl, setAnchorEl] = useState(null);
	const { refetch: refetchBusiness, business } = useBusiness();
	const [removeMenuItem] = useMutation(RemoveMenuItem);
	const [addMenuItem] = useMutation(AddMenuItem);
	const [moveMenuItem] = useMutation(MoveMenuItem);
	const [moveUpMenuItem] = useMutation(MoveUpMenuItem);
	const [moveDownMenuItem] = useMutation(MoveDownMenuItem);
	const [selectedItemId, setSelectedItemId] = useState<string>();
	const [newItemAdded, setNewItemAdded] = useState<boolean>();
	const [categoryIdForMove, setCategoryIdForMove] = useState<string>();

	const setActionAnchorEl = (event, menuItem: MenuItem, menuItemIndex: number) => {
		setAnchorEl(event.target);
		setMenuItemAction({
			type: MenuItemActionType.none,
			menuItemId: menuItem.id,
			menuItemName: menuItem.name,
			menuItemIndex: menuItemIndex
		});

		event.stopPropagation();
	};

	const dismissActionsMenu = () => {
		setAnchorEl(null);
		handleCloseMenuItemEditModal();
	};

	const handleAddMenuItem = () => {
		setMenuItemAction({
			...menuItemAction,
			type: MenuItemActionType.add
		});
	};

	const handleMoveMenuItem = () => {
		setMenuItemAction({
			...menuItemAction,
			type: MenuItemActionType.move
		});

		setAnchorEl(null);
	};

	const handleMoveUpMenuItem = async () => {
		if (!menuItemAction || !menuItemAction.menuItemId) return;
		if (!business) return;

		await moveUpMenuItem({
			variables: {
				moveUpMenuItemInput: {
					businessId: business.id,
					menuId: props.menu.id,
					menuGroupId: props.menuGroup.id,
					menuItemId: menuItemAction.menuItemId
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();
			setAnchorEl(null);
		});
	};

	const handleMoveDownMenuItem = async () => {
		if (!menuItemAction || !menuItemAction.menuItemId) return;
		if (!business) return;

		await moveDownMenuItem({
			variables: {
				moveDownMenuItemInput: {
					businessId: business.id,
					menuId: props.menu.id,
					menuGroupId: props.menuGroup.id,
					menuItemId: menuItemAction.menuItemId
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();
			setAnchorEl(null);
		});
	};

	const handleDeleteMenuItem = () => {
		setMenuItemAction({
			...menuItemAction,
			type: MenuItemActionType.delete
		});
	};

	const isOpenMenuItemEditModal = (): boolean => {
		if (!menuItemAction) return false;
		if (menuItemAction.type === MenuItemActionType.update || menuItemAction.type === MenuItemActionType.add)
			return true;
		return false;
	};

	const handleCloseMenuItemEditModal = () => {
		setMenuItemAction(undefined);
	};

	const handleItemNameChange = event => {
		if (!menuItemAction) return;

		setMenuItemAction({
			...menuItemAction,
			menuItemName: event.target.value
		});
	};

	const isOpenDeleteModal = (): boolean => {
		return menuItemAction?.type === MenuItemActionType.delete;
	};

	const handleCloseDeleteModal = () => {
		handleCloseMenuItemEditModal();
	};

	const isOpenMoveItemModal = (): boolean => {
		return menuItemAction?.type === MenuItemActionType.move;
	};

	const handleCloseMoveItemModal = () => {
		handleCloseMenuItemEditModal();
	};

	const tryMoveMenuItem = async () => {
		if (!categoryIdForMove) return;
		if (!business) return;
		if (!menuItemAction?.menuItemId) return;

		await moveMenuItem({
			variables: {
				moveMenuItemInput: {
					businessId: business?.id,
					menuId: props.menu.id,
					fromGroupId: props.menuGroup.id,
					toGroupId: categoryIdForMove,
					menuItemId: menuItemAction.menuItemId
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();
			props.onMenuItemMoved(props.menu.id, props.menuGroup.id, menuItemAction.menuItemId || "");
			handleCloseMenuItemEditModal();
			setCategoryIdForMove(undefined);
		});
	};

	const isItemSelected = (itemId: string): boolean => {
		return itemId === selectedItemId;
	};

	const handleItemClick = (menuItem: MenuItem) => {
		setSelectedItemId(menuItem.id);
		if (props.onMenuItemSelected) {
			props.onMenuItemSelected(props.menu.id, props.menuGroup.id, menuItem);
		}
	};

	useMemo(() => {
		if (newItemAdded) {
			const lastItem = menuItems[menuItems.length - 1];
			handleItemClick(lastItem);
			setNewItemAdded(false);
		}
	}, menuItems);

	const handleDelete = async () => {
		if (!menuItemAction || !menuItemAction.menuItemId) return;
		if (!business) return;

		await removeMenuItem({
			variables: {
				removeMenuItemInput: {
					businessId: business.id,
					menuId: props.menu.id,
					menuGroupId: props.menuGroup.id,
					menuItemId: menuItemAction.menuItemId
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();
			props.onMenuItemDeleted(props.menu.id, props.menuGroup.id, menuItemAction.menuItemId || "");
			handleCloseDeleteModal();
		});
	};

	const saveMenuItem = async () => {
		if (!menuItemAction || !menuItemAction.menuItemName) return;
		const name = menuItemAction.menuItemName.trim();
		if (name.length <= 0) return;
		if (!business) return;

		await addMenuItem({
			variables: {
				addMenuItemInput: {
					businessId: business.id,
					menuId: props.menu.id,
					menuGroupId: props.menuGroup.id,
					menuItemName: name
				}
			}
		}).then(() => {
			const fetchBusiness = async () => {
				await refetchBusiness();
			};
			fetchBusiness();
			handleCloseMenuItemEditModal();
			setNewItemAdded(true);
		});
	};

	const selectCategoryForMove = event => {
		setCategoryIdForMove(event.target.value);
	};

	const categorySelectElement = () => {
		const otherGroups = props.menu.menuGroups.filter(group => group.id !== props.menuGroup.id);
		const groupsElement = otherGroups.map(group => {
			return (
				<MaterialUIMenuItem value={group.id} key={group.id} className={classes.modifierSelectOption}>
					{group.name}
				</MaterialUIMenuItem>
			);
		});

		return (
			<>
				<InputLabel shrink id="category-select-placeholder-label">
					{t("selectACategory")}
				</InputLabel>
				<Select
					className={classes.modifierSelect}
					onChange={selectCategoryForMove}
					labelId="category-select-placeholder-label"
					variant="outlined"
				>
					{groupsElement}
				</Select>
			</>
		);
	};

	const menuItemsElement = () => {
		if (!menuItems) return <></>;
		const elements = menuItems.map((menuItem, index) => {
			return (
				<Box
					className={`${classes.menuItem} ${isItemSelected(menuItem.id) ? classes.selected : ""}`}
					key={menuItem.id}
					onClick={() => handleItemClick(menuItem)}
				>
					<Typography className={classes.menuItemName}>{menuItem.name}</Typography>
					<Box className={classes.editMenuItemBox} onClick={event => setActionAnchorEl(event, menuItem, index)}>
						<EditIcon className={classes.editMenuItemIcon} />
					</Box>
				</Box>
			);
		});

		return <Box className={classes.menuItems}>{elements}</Box>;
	};

	return (
		<Container className={classes.root}>
			<PageTitle pageTitle={t("items")} />
			<Box className={classes.drawerOpen}>
				<Box className={classes.header}>
					<Typography variant={"h3"} className={classes.headerText}>
						{t("items")}
					</Typography>
				</Box>
				<List className={classes.menuItemList}>{menuItemsElement()}</List>
			</Box>
			<footer className={classes.footer}>
				<PlusIcon className={classes.plusIcon} onClick={handleAddMenuItem} />
				<Typography className={classes.footerText} onClick={handleAddMenuItem}>
					{t("addItem")}
				</Typography>
			</footer>

			<Popover
				className={classes.popover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				anchorEl={anchorEl}
				open={!!anchorEl}
			>
				<ClickAwayListener onClickAway={dismissActionsMenu}>
					<Fade in={!!anchorEl}>
						<Box className={classes.editBox}>
							<Typography className={classes.editAction} onClick={handleMoveMenuItem}>
								{t("category")}
							</Typography>
							<Box className={classes.editActionBorderBottom}></Box>
							{menuItemAction?.menuItemIndex !== 0 && (
								<>
									<Typography className={classes.editAction} onClick={async () => await handleMoveUpMenuItem()}>
										{t("up")}
									</Typography>
									<Box className={classes.editActionBorderBottom}></Box>
								</>
							)}
							{menuItemAction?.menuItemIndex !== menuItems.length - 1 && (
								<>
									<Typography className={classes.editAction} onClick={async () => await handleMoveDownMenuItem()}>
										{t("down")}
									</Typography>
									<Box className={classes.editActionBorderBottom}></Box>
								</>
							)}
							<Typography className={classes.editAction} onClick={handleDeleteMenuItem}>
								{t("delete")}
							</Typography>
						</Box>
					</Fade>
				</ClickAwayListener>
			</Popover>

			<Modal
				className={classes.modal}
				open={isOpenMenuItemEditModal()}
				onClose={handleCloseMenuItemEditModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={isOpenMenuItemEditModal()}>
					<Box className={`${classes.paper} ${classes.editMenuItemPaper}`}>
						<span className={classes.title}>{t("name")}</span>
						<OutlinedInput
							className={classes.nameInput}
							onChange={handleItemNameChange}
							value={menuItemAction?.menuItemName}
							autoFocus
						/>

						<Box className={classes.actions}>
							<Button className={classes.cancelButton} onClick={handleCloseMenuItemEditModal}>
								{t("cancel")}
							</Button>
							<Button
								className={classes.addButton}
								disabled={!menuItemAction?.menuItemName}
								onClick={async () => await saveMenuItem()}
							>
								{t("save")}
							</Button>
						</Box>
					</Box>
				</Fade>
			</Modal>

			<Modal
				className={classes.modal}
				open={isOpenDeleteModal()}
				onClose={handleCloseDeleteModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={isOpenDeleteModal()}>
					<Box className={`${classes.paper} ${classes.deletePaper}`}>
						<Box className={classes.deleteTitleBox}>
							<Typography className={classes.deleteTitle}>{t("confirmToDeleteItem")}</Typography>
						</Box>
						<Box className={classes.deleteActions}>
							<Button className={classes.cancelButton} onClick={handleCloseDeleteModal}>
								{t("cancel")}
							</Button>
							<Button className={classes.deleteButton} onClick={async () => await handleDelete()}>
								{t("delete")}
							</Button>
						</Box>
					</Box>
				</Fade>
			</Modal>

			<Modal
				className={classes.modal}
				open={isOpenMoveItemModal()}
				onClose={handleCloseMoveItemModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={isOpenMoveItemModal()}>
					<Box className={`${classes.paper} ${classes.moveMenuItemPaper}`}>
						<Box className={classes.optionRow}>
							<span className={classes.title}>{t("chooseCategoryFor")}</span>
							<Typography>{menuItemAction?.menuItemName}</Typography>
						</Box>
						<Box className={classes.optionRow}>
							<span className={classes.title}>{t("category")}</span>
							<Box>{categorySelectElement()}</Box>
						</Box>
						<Box className={classes.actions}>
							<Button className={classes.cancelButton} onClick={handleCloseMoveItemModal}>
								{t("cancel")}
							</Button>
							<Button className={classes.addButton} onClick={async () => await tryMoveMenuItem()}>
								{t("save")}
							</Button>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</Container>
	);
};

export default MenuItemList;
