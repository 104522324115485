import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationAddMenuInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation AddMenu($addMenuInput: MutationAddMenuInput!) {
	addMenu(addMenuInput: $addMenuInput) {
        success
    }
}
`;

const AddMenu: IOperation<MutationUpdateMenusResult, MutationAddMenuInput> = { text } as any;

export default AddMenu;