import { createContext } from "react";

import BusinessListItem from "api/types/BusinessListItem";

interface Businesses {
	loading: boolean;
	businesses: BusinessListItem[];
}

const BusinessesContext = createContext<Businesses>(undefined as any);

export default BusinessesContext;
