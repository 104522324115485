import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

interface DesktopContainerDrawerProps {
	props?: any;
	children: React.ReactNode;
}

const DesktopContainerDrawer: React.FC<DesktopContainerDrawerProps> = ({ children }) => {
	const matches = useMediaQuery("(min-width:769px)");

	return <>{matches ? children : null}</>;
};

export default DesktopContainerDrawer;
