import { useLazyQuery as useLazyQueryApollo } from "@apollo/client";

import { QueryHookOptions, QueryTuple } from "@apollo/client/react/types/types";

import IOperation from "api/IOperation";

const useLazyQuery = <D, V>(query: IOperation<D, V>, options?: QueryHookOptions<D, V>): QueryTuple<D, V> => {
	return useLazyQueryApollo(query.text, options);
	// console.log("res", res);
	// return query.map && res.data ? { ...res, data: query.map(res.data) } : res;
};

export default useLazyQuery;
