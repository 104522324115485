import { useQuery as useQueryApollo } from "@apollo/client";

import { QueryHookOptions, QueryResult } from "@apollo/client/react/types/types";

import IOperation from "api/IOperation";

const useQuery = <D, V>(query: IOperation<D, V>, options?: QueryHookOptions<D, V>): QueryResult<D, V> => {
	const res = useQueryApollo(query.text, options);
	// console.log("res", res);
	return query.map && res.data ? { ...res, data: query.map(res.data) } : res;
};

export default useQuery;
