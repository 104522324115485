import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { OrderUpdatesSubscription as OrderUpdatesSubscriptionType, OrderUpdatesSubscriptionVariables } from "gql/types/subscriptions/OrderUpdatesSubscription";

const text = gql`subscription OrderUpdatesSubscription($orderUpdatesInput: SubscriptionOrderUpdatesInput!) {
	orderUpdates(
		orderUpdatesInput: $orderUpdatesInput
	) {
		order {
			id
			orderStatus
			orderNumber
			placedAt
			taxRate
			orderSubTotalCents
			taxCents
			tipCents
			orderTotalCents
			phoneNumber
			displayPhoneNumber
			orderType {
				id
				orderType
				name
				description
			}

			business {
				id
			}

			customer {
				fullName
			}

			orderItems {
				id
				quantity
				unitPriceCents
				extendedPriceCents

				menuItem {
					id
					itemCode
					name
					localeNames
					description
					priceCents
					displayPrice
					imageUrl
				}

				selectedMenuItemOptionGroups {
					menuItemOptionGroupId
					name
					localeNames

					selectedMenuItemOptions {
						menuItemOptionId
						name
						localeNames
						priceCents
						displayPrice
					}
				}
			}
		}
	}
}
`;

const OrderUpdatesSubscription: IOperation<OrderUpdatesSubscriptionType, OrderUpdatesSubscriptionVariables> = { text } as any;

export default OrderUpdatesSubscription;
