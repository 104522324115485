import { ApolloClient, ApolloLink, DefaultOptions, HttpLink, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import JwtDecode from "jwt-decode";
import { SubscriptionClient } from "subscriptions-transport-ws";

import config from "config";
import useAuth from "hooks/useAuth";

import { getAccessToken, setAccessToken, subscribeToAccessToken } from "utility/accessToken";

import { observerLink } from "./observerLink";

export const cache = new InMemoryCache();

const REFRESH_URL = "/refresh_token";

const httpLink = new HttpLink({
	// credentials: "include",
	uri: config.apiUrl
});

const subscriptionClient = new SubscriptionClient(config.wsApiUrl, {
	reconnect: true,
	connectionParams: () => ({ "x-access-token": getAccessToken() })
});

subscribeToAccessToken(() => subscriptionClient.close(true, true));

const wsLink = new WebSocketLink(subscriptionClient);

// TOKEN REFRESH
const tokenRefreshLink: any = new TokenRefreshLink({
	accessTokenField: "at",
	isTokenValidOrUndefined: () => {
		const token = getAccessToken();
		if (!token) {
			return true;
		}
		try {
			/// check if expired
			const { exp } = JwtDecode(token);
			if (Date.now() >= exp * 1000) {
				return false;
			} else {
				return true;
			}
		} catch {
			return false;
		}
	},
	fetchAccessToken: () => {
		return fetch(REFRESH_URL, {
			method: "POST",
			credentials: "include"
		});
	},
	handleFetch: accessToken => {
		setAccessToken(accessToken);
	},
	handleError: err => {
		if (err) {
			setAccessToken(null);
		}
	}
});

const errorHandler = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		// EventLogging.captureException(graphQLErrors);
		console.error("graphQLErrors in apolloClient: ", graphQLErrors);
		//TODO: Do something useful
		// sendToLoggingService(graphQLErrors);
		graphQLErrors.map(({ message, locations, path }) => {
			console.log("GQL Err message", message);
			if (message === "Not authorized") {
				//redirect?
				const { logout } = useAuth();
				logout();
			} else {
			}
		});
	}
	if (networkError) {
		console.error("NetworkError in apolloClient: ", networkError);
		// EventLogging.captureException(networkError);
		// logoutUser();
		//TODO: Do something useful
	}
});

const requestLink = ApolloLink.from([
	tokenRefreshLink, //TODO:  fix; apollo-link-token-refresh is not working on apollo client v3
	observerLink,
	errorHandler,
	// withClientState({
	// 	defaults: {
	// 		isConnected: true
	// 	},
	// 	resolvers: {
	// 		Mutation: {
	// 			updateNetworkStatus: (_, { isConnected }, { cache }) => {
	// 				cache.writeData({ data: { isConnected } });
	// 				return null;
	// 			}
	// 		}
	// 	},
	// 	cache
	// }),
	split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === "OperationDefinition" && definition.operation === "subscription";
		},
		wsLink,
		httpLink
	)
]);

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore"
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all"
	}
};

const apolloClient = new ApolloClient({
	cache,
	// link: httpLink //requestLink
	link: requestLink,
	defaultOptions: defaultOptions
});

export default apolloClient;
