import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { BusinessesQuery as BusinessesQueryType, BusinessesQueryVariables } from "gql/types/queries/BusinessesQuery";

const text = gql`query BusinessesQuery($businessesInput: QueryBusinessesInput!) {
	businesses(businessesInput: $businessesInput) {
		id
		slug
		name
		localeNames
	}
}
`;

const BusinessesQuery: IOperation<BusinessesQueryType, BusinessesQueryVariables> = { text } as any;

export default BusinessesQuery;
