import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import { MutationAuthLoginType } from "gql/types/globals";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { AuthStartOtpLoginType, AuthStartOtpLoginVariables } from "gql/types/mutations/AuthStartOtpLogin";

const text = gql`mutation AuthStartOtpLogin($authStartOtpLoginInput: MutationAuthStartOtpLoginInput!) {
	authStartOtpLogin(authStartOtpLoginInput: $authStartOtpLoginInput) {
		authType
	}
}
`;

const AuthStartOtpLogin: IOperation<AuthStartOtpLoginType, AuthStartOtpLoginVariables> = { text } as any;

export default AuthStartOtpLogin;
