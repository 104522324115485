import React, { useMemo, useState } from "react";

import { Box, CircularProgress, IconButton, Menu, MenuItem, SwipeableDrawer, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import clsx from "clsx";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Link } from "react-router-dom";

import makeStyles from "hooks/makeStyles";
import useApp from "hooks/useApp";
import useAuth from "hooks/useAuth";
import useBusiness from "hooks/useBusiness";
import useBusinesses from "hooks/useBusinesses";
import NavMenuIcon from "icons/NavMenuIcon";

const useStyles = makeStyles(theme => ({
	paper: {
		background: "transparent",
		borderRadius: "0",
		width: "70%",
		maxWidth: "480px"
	},
	root: {
		background: "transparent",
		boxShadow: "0"
	},
	backdrop: {
		backgroundColor: "transparent"
	},
	icon: {
		width: "20px",
		fill: theme.palette.text.primary
	},
	logo: {
		height: 64,
		textAlign: "left",
		paddingBottom: theme.spacing(3)
	},
	menuButton: {
		padding: "13px 4px"
	},
	menuRoot: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		backdropFilter: "blur(2px)",
		height: "100%",
		background: "rgba(51, 51, 51, 0.7)"
	},
	menuItem: {
		color: "#fff",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignSelf: "right",
		padding: theme.spacing(2),
		paddingRight: theme.spacing(8),
		borderBottom: "solid 1px #f2f2f2"
	},
	lastMenuItem: {
		borderBottom: "none",
		position: "absolute",
		bottom: 0,
		right: 0,
		left: 0,
		display: "flex"
	},
	menuIcon: {
		width: "13px",
		fill: theme.palette.text.primary
	},
	logoMenuItem: {
		background: "#4F4F4F",
		color: "#fff",
		flexDirection: "column",
		alignItems: "flex-start"
	},
	businessSelector: {
		display: "flex",
		flexDirection: "row"
	},
	menuTitle: {
		textAlign: "left",
		textTransform: "uppercase",
		width: "100%",
		fontSize: "18px",
		lineHeight: "21px",
		fontWeight: "bold"
	},
	menuBusinessTitle: {
		textAlign: "left",
		textTransform: "uppercase",
		width: "100%",
		fontSize: "18px",
		lineHeight: "21px",
		fontWeight: "bold",
		whiteSpace: "initial"
	},
	circularProgress: {
		marginRight: "8px"
	},
	pausing: {
		color: theme.palette.warning.main
	}
}));

interface NavMenuProps {
	businessName: string;
	logoUrl?: string | null;
}

const NavMenu = ({ businessName, logoUrl }: NavMenuProps) => {
	const { locale } = useApp();
	const { business, togglePause } = useBusiness();
	const { businesses } = useBusinesses();
	const { t } = useTranslation();
	const classes = useStyles();
	const { logout } = useAuth();
	const navigate = useNavigate();
	const [businessSelectorAnchor, setBusinessSelectorAnchor] = useState<null | Element>();
	const closeBusinessSelector = useMemo(() => () => setBusinessSelectorAnchor(null), []);
	const [pausing, setPausing] = useState<boolean>(false);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		closeBusinessSelector();
		setAnchorEl(null);
	};

	const handleBusinessTitleClick = useMemo(
		() => event => {
			setBusinessSelectorAnchor(event.currentTarget);
		},
		[]
	);

	const logoutClicked = () => {
		logout();
		navigate("/");
		handleClose();
	};

	const menusClicked = () => {
		navigate(`/${encodeURIComponent(locale)}/${encodeURIComponent(business?.slug || "")}/menus`);
		handleClose();
	};

	const onOrdersClicked = () => {
		navigate(`/${encodeURIComponent(locale)}/${encodeURIComponent(business?.slug || "")}/orders`);
		handleClose();
	};

	const onOrderHistorysClicked = () => {
		navigate(`/${encodeURIComponent(locale)}/${encodeURIComponent(business?.slug || "")}/history`);
		handleClose();
	};

	const handleToggleBusinessPause = async () => {
		setPausing(true);
		await togglePause();
		setPausing(false);
	};

	return (
		<>
			<IconButton onClick={handleClick} className={classes.menuButton}>
				<NavMenuIcon className={classes.icon} />
			</IconButton>
			<SwipeableDrawer
				anchor={"left"}
				open={!!anchorEl}
				onClose={handleClose}
				onOpen={handleClick}
				classes={{ paper: classes.paper, root: classes.root }}
				BackdropProps={{ className: classes.backdrop }}
			>
				<Box className={classes.menuRoot}>
					{(businesses?.length || 0) < 2 ? (
						<MenuItem className={clsx(classes.menuItem, classes.logoMenuItem)}>
							{logoUrl && <img src={logoUrl} className={classes.logo} />}
							<div className={classes.businessSelector}>
								<Typography className={classes.menuBusinessTitle} variant="h6">
									{businessName}
								</Typography>
							</div>
						</MenuItem>
					) : (
						<>
							<MenuItem onClick={handleBusinessTitleClick} className={clsx(classes.menuItem, classes.logoMenuItem)}>
								{logoUrl && <img src={logoUrl} className={classes.logo} />}
								<div className={classes.businessSelector}>
									<Typography className={classes.menuBusinessTitle} variant="h6">
										{businessName}
									</Typography>
									<ArrowDropDown />
								</div>
							</MenuItem>

							<Menu
								open={!!businessSelectorAnchor}
								anchorEl={businessSelectorAnchor}
								anchorOrigin={{ vertical: "top", horizontal: "center" }}
								transformOrigin={{ vertical: "top", horizontal: "center" }}
								keepMounted
								onClose={closeBusinessSelector}
							>
								{businesses?.map(each => (
									<MenuItem
										key={each.id}
										component={Link}
										to={`/${encodeURIComponent(locale)}/${encodeURIComponent(each.slug)}`}
										selected={each.id === business?.id}
										onClick={handleClose}
									>
										<Typography className={classes.menuBusinessTitle} variant="h6">
											{each.name}
										</Typography>
									</MenuItem>
								))}
							</Menu>
						</>
					)}

					<MenuItem onClick={onOrdersClicked} className={classes.menuItem}>
						<Typography className={classes.menuTitle}>{t("home")}</Typography>
					</MenuItem>
					<MenuItem onClick={menusClicked} className={clsx(classes.menuItem)}>
						<Typography className={classes.menuTitle}>{t("menus")}</Typography>
					</MenuItem>
					<MenuItem onClick={onOrderHistorysClicked} className={classes.menuItem}>
						<Typography className={classes.menuTitle}>{t("orderHistory")}</Typography>
					</MenuItem>
					{!!business && (
						<MenuItem
							onClick={async () => {
								await handleToggleBusinessPause();
							}}
							className={clsx(classes.menuItem)}
						>
							{pausing && <CircularProgress size="1rem" color="inherit" className={classes.circularProgress} />}
							{business.pause && (
								<Typography className={`${classes.menuTitle} ${classes.pausing}`}>{t("pausing")}</Typography>
							)}

							{!business.pause && <Typography className={classes.menuTitle}>{t("pause")}</Typography>}
						</MenuItem>
					)}
					<MenuItem onClick={logoutClicked} className={clsx(classes.menuItem)}>
						<Typography className={classes.menuTitle}>{t("logout")}</Typography>
					</MenuItem>
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default NavMenu;
