import React, { useMemo } from "react";

import { Drawer, DrawerProps } from "@material-ui/core";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router";

import makeStyles from "hooks/makeStyles";

import NoOrderDetailScreen from "./NoOrderDetailScreen";
import OrderDetailScreen from "./OrderDetailScreen";

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.up(1280)]: {
			maxWidth: "initial"
		}
	},
	paper: {
		display: "flex",
		height: "100vh",
		// width: "100%",
		[theme.breakpoints.up(480)]: {
			height: "100vh",
			borderRadius: "0",
			// width: "80%",
			//maxWidth: "742px",
			left: "auto",
			backgroundColor: theme.palette.background.paper,
			padding: 0,
			color: theme.palette.text.primary
		}
	},
	drawerOpen: {
		width: "100%",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		[theme.breakpoints.up(480)]: {
			width: "80%"
		},
		[theme.breakpoints.up(769)]: {
			width: "60%"
		},
		[theme.breakpoints.up(1867)]: {
			width: "80%",
			maxWidth: "calc(100vw - 742px)"
		}
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: "hidden",
		width: 0
	},
	backdrop: {
		// backgroundColor: "rgba(0,0,0,0.1)",
		// [theme.breakpoints.up(480)]: {
		// 	backgroundColor: "transparent",
		// 	WebkitBackdropFilter: "blur(1.8px)",
		// 	backdropFilter: "blur(1.8px)"
		// }
	}
}));

type Props = { onUpdate: () => Promise<void> } & Omit<DrawerProps, "swipeable" | "matchRoute">;

const OrderDetailDrawer = ({ className, onUpdate, ...otherProps }: Props) => {
	const navigate = useNavigate();
	const { hash } = useLocation();
	const classes = useStyles();

	const close = useMemo(
		() => () => {
			navigate(-1);
		},
		[navigate]
	);

	const props: DrawerProps = useMemo(
		() =>
			Object.assign(
				{
					className: clsx(classes.root, className),
					classes: {
						paper: clsx({
							[classes.paper]: true,
							[classes.drawerOpen]: true
						})
					},
					BackdropProps: {
						className: classes.backdrop
					},
					anchor: "right",
					variant: "permanent",
					onClose: close
				},
				otherProps
			),
		[className, classes.backdrop, classes.paper, classes.root, close, otherProps]
	);

	const orderId = hash.substr("#orderid:".length);

	return (
		<Drawer {...props}>
			{orderId && <OrderDetailScreen onClose={close} onUpdate={onUpdate} orderId={orderId} />}
			{!orderId && <NoOrderDetailScreen />}
		</Drawer>
	);
};

export default OrderDetailDrawer;
