import { Theme, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import type from "@material-ui/lab/themeAugmentation"; // required for material-ui lab

const typography = {
	fontFamily: [
		"Monteserrat",
		"Roboto",
		"-apple-system",
		"BlinkMacSystemFont",
		"Segoe UI",
		"Helvetica Neue",
		"Arial",
		"sans-serif",
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol"
	].join(","),
	h1: {
		fontSize: "2.5rem",
		fontWeight: 500
	},
	h2: {
		fontSize: "1.9rem",
		fontWeight: 500
	},
	h3: {
		fontSize: "1.1rem",
		fontWeight: 500
	},

	body: {
		fontSize: 12
	}
};

const darkPalette = {
	primary: {
		main: "#1a237e",
		light: "rgb(166, 212, 250)",
		dark: "#1a237e",
		contrastText: "#fff"
	},
	secondary: {
		main: "#7986cb",
		light: "#939ed5",
		dark: "#545d8e",
		contrastText: "rgba(255, 255, 255, 0.7)"
	},
	error: {
		light: "#e57373",
		main: "#f44336",
		dark: "#d32f2f",
		contrastText: "#fff"
	},
	warning: {
		light: "#ffb74d",
		main: "#ff9800",
		dark: "#f57c00",
		contrastText: "rgba(0, 0, 0, 0.87)"
	},
	info: {
		light: "#64b5f6",
		main: "#2196f3",
		dark: "#1976d2",
		contrastText: "#fff"
	},
	success: {
		light: "#81c784",
		main: "#4caf50",
		dark: "#388e3c",
		contrastText: "rgba(0, 0, 0, 0.87)"
	},
	grey: {
		50: "#fafafa",
		100: "#f5f5f5",
		200: "#eeeeee",
		300: "#e0e0e0",
		400: "#bdbdbd",
		500: "#9e9e9e",
		600: "#757575",
		700: "#616161",
		800: "#424242",
		900: "#212121",
		A100: "#d5d5d5",
		A200: "#aaaaaa",
		A400: "#303030",
		A700: "#616161"
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	text: {
		primary: "#fff",
		secondary: "rgba(255, 255, 255, 0.7)",
		disabled: "rgba(255, 255, 255, 0.5)",
		hint: "rgba(255, 255, 255, 0.5)"
		// icon: "rgba(255, 255, 255, 0.5)"
	},
	divider: "rgba(255, 255, 255, 0.12)",
	background: {
		paper: "#222230",
		default: "#121222",
		// level2: "#333",
		level1: "#111"
	},
	action: {
		active: "#fff",
		hover: "rgba(255, 255, 255, 0.08)",
		hoverOpacity: 0.08,
		selected: "rgba(255, 255, 255, 0.16)",
		// selectedOpacity: 0.16,
		disabled: "rgba(255, 255, 255, 0.3)",
		disabledBackground: "rgba(255, 255, 255, 0.12)"
		// disabledOpacity: 0.38,
		// focus: "rgba(255, 255, 255, 0.12)",
		// focusOpacity: 0.12,
		// activatedOpaciy: 0.24
	}
};

const lightPalette = {
	primary: {
		light: "#4a54a1",
		main: "#122c72",
		dark: "#3949ab",
		contrastText: "#fff"
	},
	secondary: {
		light: "#3e4470",
		main: "#7986cb",
		dark: "#3949ab",
		contrastText: "#eee"
	},
	error: {
		main: red[900]
	},
	text: {
		primary: "rgba(0, 0, 0, 0.8)",
		secondary: "rgba(0, 0, 0, 0.6)",
		disabled: "rgba(0, 0, 0, 0.4)",
		hint: "rgba(0, 0, 0, 0.5)"
	},
	background: {
		paper: "#fff",
		default: "#fff"
		// level2: "#333",
		// level1: "#212121"
	}
};

const darkOverrides = {
	MuiToolbar: {
		dense: {
			minHeight: 30
		},
		gutters: {
			paddingLeft: 16,
			paddingRight: 16,
			"@media (min-width: 600px)": {
				paddingLeft: 16,
				paddingRight: 16
			}
		}
	},
	MuiInput: {
		underline: {
			"&:hover:not($disabled):after": {
				color: "#ffb900",
				borderTopColor: "#ffb900",
				borderColor: "#ffb900"
			},
			"&:hover:not($disabled):before": {
				color: "#ffb900",
				borderTopColor: "#ffb900",
				borderColor: "#ffb900"
			},
			"&$focused:after": {
				color: "#ffb900",
				borderTopColor: "#ffb900",
				borderBottomColor: "#ffb900"
			}
		},
		outlined: {
			padding: 2
		}
	},
	MuiOutlinedInput: {
		root: {
			padding: 2
		}
	},
	MuiFormLabel: {
		root: {
			fontSize: ".9rem",
			color: "rgba(255, 255, 255, 0.5)",
			"&$focused": {
				color: "#ffb900"
			}
		}
	}
};

const overrides = {
	MuiAppBar: {
		root: {
			// height: 60
		}
	},
	MuiToolbar: {
		dense: {
			minHeight: 30
		},
		gutters: {
			paddingLeft: 16,
			paddingRight: 16,
			"@media (min-width: 600px)": {
				paddingLeft: 16,
				paddingRight: 16
			}
		}
	},

	MuiTextField: {
		root: {}
	},
	MuiOutlinedInput: {
		root: {
			borderColor: "#fff",
			height: 32,
			borderRadius: 8,
			padding: 0
		},
		input: {
			borderRadius: 8,
			paddingTop: 6,
			paddingLeft: 12,
			paddingRight: 12,
			paddingBottom: 6
		}
	},
	MuiFormLabel: {
		root: {
			"&$focused": {
				color: "#0000ff"
			}
		}
	},
	MuiMenuItem: {
		root: {
			fontSize: "0.9rem",
			minWidth: "180px"
		}
	},
	MuiAutocomplete: {
		input: {
			padding: 0
		},
		inputRoot: {
			padding: 0,
			'&[class*="MuiOutlinedInput-root"]': {
				padding: "12 0"
			}
		}
	}
};

const getTheme = (themeType: "light" | "dark") => {
	const palette = themeType === "dark" ? darkPalette : lightPalette;

	return createMuiTheme({
		typography,
		palette: {
			type: themeType,
			...palette
		},
		overrides: {
			...overrides,
			...(themeType === "dark" ? darkOverrides : {})
		}
	});
};
const theme = (
	themeType: "light" | "dark"
): Theme & {
	businessHeaderHeight: number;
} => ({
	...responsiveFontSizes(getTheme(themeType)),
	businessHeaderHeight: 150
});

export default theme;
