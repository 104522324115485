import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationUpdateMenuItemInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation UpdateMenuItem($updateMenuItemInput: MutationUpdateMenuItemInput!) {
	updateMenuItem(updateMenuItemInput: $updateMenuItemInput) {
        success
    }
}
`;

const UpdateMenuItem: IOperation<MutationUpdateMenusResult, MutationUpdateMenuItemInput> = { text } as any;

export default UpdateMenuItem;