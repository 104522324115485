import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationRemoveMenuGroupInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation RemoveMenuGroup($removeMenuGroupInput: MutationRemoveMenuGroupInput!) {
	removeMenuGroup(removeMenuGroupInput: $removeMenuGroupInput) {
        success
    }
}
`;

const RemoveMenuGroup: IOperation<MutationUpdateMenusResult, MutationRemoveMenuGroupInput> = { text } as any;

export default RemoveMenuGroup;