import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { UpdateOrderReady as UpdateOrderReadyType, UpdateOrderReadyVariables } from "gql/types/mutations/UpdateOrderReady";

const text = gql`mutation UpdateOrderReady($updateOrderReadyInput: MutationUpdateOrderReadyInput!) {
	updateOrderReady(updateOrderReadyInput: $updateOrderReadyInput) {
		order {
			id
			orderStatus
		}
	}
}
`;

const UpdateOrderReady: IOperation<UpdateOrderReadyType, UpdateOrderReadyVariables> = { text } as any;

export default UpdateOrderReady;
