import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { BusinessOrdersQuery as BusinessOrdersQueryType, BusinessOrdersQueryVariables } from "gql/types/queries/BusinessOrdersQuery";

const text = gql`query BusinessOrdersQuery($ordersInput: BusinessOrdersInput!, $businessInput: QueryBusinessInput!) {
  business(businessInput: $businessInput) {
		orders(ordersInput: $ordersInput) {
			id
			orderStatus
			orderType {
				orderType
			}

			deliveryAddress {
				address1
				address2
				address3
				state
				city
				postalCode
				country
			}
			orderNumber
			placedAt
			preparationStartAt
			deliveryOrPickupAt
			taxRate
			orderSubTotalCents
			taxCents
			tipCents
			orderTotalCents
			phoneNumber
			displayPhoneNumber

			business {
				id
			}

			orderType {
				id
				orderType
				name
				description
			}

			customer {
				fullName
				phoneNumber
				displayPhoneNumber
			}

			orderItems {
				id
				quantity
				unitPriceCents
				extendedPriceCents
				specialInstructions

				menuItem {
					id
					itemCode
					name
					localeNames
					description
					priceCents
					displayPrice
					imageUrl
				}

				selectedMenuItemOptionGroups {
					menuItemOptionGroupId
					name
					localeNames

					selectedMenuItemOptions {
						menuItemOptionId
						name
						localeNames
						priceCents
						displayPrice
					}
				}
			}
		}
	}
}
`;

const BusinessOrdersQuery: IOperation<BusinessOrdersQueryType, BusinessOrdersQueryVariables> = { text } as any;

export default BusinessOrdersQuery;
