import { useContext, useMemo } from "react";

import UpdateBusiness from "api/mutations/UpdateBusiness";
import BusinessContext from "contexts/BusinessContext";

import useMutation from "./useMutation";

const useBusiness = () => {
	const context = useContext(BusinessContext);
	const { business, refetch } = context;
	const [updateBusiness] = useMutation(UpdateBusiness);
	return useMemo(
		() => ({
			...context,

			async togglePause() {
				if (!business) return;
				await updateBusiness({
					variables: {
						updateBusinessInput: {
							businessId: business.id,
							update: {
								pause: {
									value: !business.pause
								}
							}
						}
					}
				});

				await refetch();
			}
		}),
		[context]
	);
};

export default useBusiness;
