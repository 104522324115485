import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationUpdateMenuGroupInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation UpdateMenuGroup($updateMenuGroupInput: MutationUpdateMenuGroupInput!) {
	updateMenuGroup(updateMenuGroupInput: $updateMenuGroupInput) {
        success
    }
}
`;

const UpdateMenuGroup: IOperation<MutationUpdateMenusResult, MutationUpdateMenuGroupInput> = { text } as any;

export default UpdateMenuGroup;