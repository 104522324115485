import React, { useEffect } from "react";

import { Box, Container, List, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import BusinessOrdersQuery from "api/queries/BusinessOrdersQuery";
import OrderUpdatesSubscription from "api/subscriptions/OrderUpdatesSubscription";
import { OrderStatuses, OrdersSort } from "api/types/enums";
import Loading from "components/Loading";
import PageTitle from "components/PageTitle";
import DesktopContainerDrawer from "containers/DesktopContainerDrawer";
import MobileContainerDrawer from "containers/MobileContainerDrawer";
import { DesktopOrderDetailDrawer, MobileOrderDetailDrawer } from "containers/OrderDetailScreen";
import { BusinessOrdersQuery_business_orders } from "gql/types/queries/BusinessOrdersQuery";
import useBusiness from "hooks/useBusiness";
import useQuery from "hooks/useQuery";

import useSubscription from "hooks/useSubscription";

import OrderListItem from "./OrderListItem";
import OrderListItemTablet from "./OrderListItemTablet";

const useStyles = makeStyles(theme => {
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			margin: 0,
			paddingTop: 0,
			textAlign: "center",
			[theme.breakpoints.up(769)]: {
				paddingLeft: 0,
				paddingRight: 0
			},
			[theme.breakpoints.up(1280)]: {
				maxWidth: "initial"
			}
		},
		drawerClose: {
			width: "100%",
			maxWidth: "auto"
		},
		drawerOpen: {
			// width: "742px",
			maxWidth: "742px",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up(480)]: {
				width: "100%"
			},
			[theme.breakpoints.up(769)]: {
				width: "40%"
			}
			// [theme.breakpoints.up(1580)]: {
			//  	width: "20%",
			//  	maxWidth: "742px"
			// }
		},
		orderGroup: {
			background: "#E0E0E0",
			padding: theme.spacing(2),
			flexDirection: "row",
			display: "flex",
			justifyContent: "space-between"
		},
		orderGroupHeader: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px",
			fontWeight: "bold"
		},
		orderGroupHeaderCounter: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px"
		},
		titleContainer: {
			margin: theme.spacing(4)
		},
		title: {
			textTransform: "uppercase",
			fontSize: "18px",
			lineHeight: "22px"
		}
	};
});

const OrderListScreen = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { business } = useBusiness();

	const { data, loading, refetch } = useQuery(BusinessOrdersQuery, {
		variables: {
			businessInput: {
				businessId: business?.id
			},
			ordersInput: {
				current: true,
				skip: 0,
				take: 200,
				sortBy: [OrdersSort.placedAtAscNullsFirst]
			}
		}
	});

	useSubscription(OrderUpdatesSubscription, {
		variables: {
			orderUpdatesInput: {
				businessId: business?.id
			}
		},
		onSubscriptionData: async () => {
			if (!loading) await refetch();
		}
	});

	useEffect(() => {
		const interval = setInterval(async () => {
			if (refetch && !loading) await refetch();
		}, 10000);

		return () => {
			clearInterval(interval);
		};
	}, [loading]);

	if (loading) {
		console.log("LOADING");
		return <Loading />;
	}

	const orderGroups: Record<
		string,
		{ title: string; orders: BusinessOrdersQuery_business_orders[]; statuses: string[] }
	> = {};
	orderGroups[`${OrderStatuses.placed}`] = { title: t("newOrders"), orders: [], statuses: [OrderStatuses.placed] };
	orderGroups[`${OrderStatuses.confirmed}`] = {
		title: t("currentOrders"),
		orders: [],
		statuses: [OrderStatuses.confirmed]
	};
	orderGroups[`${OrderStatuses.ready}`] = { title: t("readyOrders"), orders: [], statuses: [OrderStatuses.ready] };
	orderGroups[`${OrderStatuses.completed}`] = {
		title: t("completedOrders"),
		orders: [],
		statuses: [OrderStatuses.completed, OrderStatuses.cancelledByBusiness]
	};

	if (data && data.business) {
		const { orders } = data.business!;
		Object.keys(orderGroups).forEach(orderStatus => {
			orderGroups[orderStatus].orders = orders.filter(order =>
				orderGroups[orderStatus].statuses.find(s => s === order.orderStatus)
			);
		});
	} else {
		console.log("loading, data", business, loading, data);
	}

	const onUpdateOrder = async () => {
		if (!loading) await refetch();
	};

	return (
		<Container className={classes.root}>
			<PageTitle pageTitle={t("orders")} />

			<List className={classes.drawerOpen}>
				{Object.keys(orderGroups).map(orderStatus => (
					<Box key={orderStatus}>
						{
							<Box className={classes.orderGroup} onClick={onUpdateOrder}>
								<Typography variant={"h3"} className={classes.orderGroupHeader}>
									{orderGroups[orderStatus].title}
								</Typography>
								<Typography variant={"h3"} className={classes.orderGroupHeaderCounter}>
									{t("orderCount", { count: orderGroups[orderStatus].orders.length })}
								</Typography>
							</Box>
						}
						{orderGroups[orderStatus].orders.length === 0 && (
							<Box className={classes.titleContainer}>
								<Typography className={classes.title}>{t("noOrders")}</Typography>
							</Box>
						)}
						{orderGroups[orderStatus].orders.map(order => (
							<Box key={order.id}>
								<DesktopContainerDrawer>
									<OrderListItemTablet key={`i${order.id}`} order={order} />
								</DesktopContainerDrawer>
								<MobileContainerDrawer>
									<OrderListItem key={`i${order.id}`} order={order} />
								</MobileContainerDrawer>
							</Box>
						))}
					</Box>
				))}
			</List>
			<DesktopContainerDrawer>
				<DesktopOrderDetailDrawer onUpdate={onUpdateOrder} />
			</DesktopContainerDrawer>
			<MobileContainerDrawer>
				<MobileOrderDetailDrawer />
			</MobileContainerDrawer>
		</Container>
	);
};

export default OrderListScreen;
