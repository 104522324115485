import { Styles, makeStyles as makeStylesMui } from "@material-ui/styles";

import { WithStylesOptions } from "@material-ui/styles/withStyles";

import theme from "styles/theme";

const makeStyles = <T = ReturnType<typeof theme>, C extends string = string>(
	style: Styles<T, {}, C>,
	options?: Omit<WithStylesOptions<T>, "withTheme">
) => makeStylesMui(style, options);

export default makeStyles;
