import React from "react";

import { Box, List, ListItem, ListItemProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as R from "ramda";
import { useTranslation } from "react-i18next";

import OrderItem from "api/types/OrderItem";
import Price from "components/Price";
import useLocalize from "hooks/useLocalize";

// NOTE: workaround for weird mui type error
const MyListItem = ListItem as React.FunctionComponent<ListItemProps>;

interface Props extends ListItemProps {
	item: Pick<
		OrderItem,
		| "menuItem"
		| "quantity"
		| "unitPriceCents"
		| "extendedPriceCents"
		| "selectedMenuItemOptionGroups"
		| "specialInstructions"
	>;
	variant: "light" | "dark";
}

const fontSize = "14px";
const lineHeight = "15px";

const useStyles = makeStyles(theme => ({
	root: {
		margin: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		borderBottom: "1px solid #E0E0E0",
		padding: `${theme.spacing(1)}px 0`
	},
	menuItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "baseline",
		fontSize,
		lineHeight,
		fontWeight: 500,
		marginBottom: theme.spacing(1)
	},
	itemCode: {
		textAlign: "right",
		lineHeight: "14px",
		fontWeight: 500,
		marginRight: theme.spacing(1),
		color: "#4F4F4F",
		[theme.breakpoints.up(769)]: {
			marginRight: theme.spacing(2)
		}
	},
	menuItemBody: {
		paddingLeft: theme.spacing(3)
	},
	instructions: {
		minWidth: 0,
		background: "#F2F2F2",
		borderRadius: "8px",
		padding: theme.spacing(2),
		paddingTop: theme.spacing(1.5),
		fontSize,
		lineHeight: "18px",
		color: "#828282",
		flexDirection: "column"
	},
	instructionsHeader: {
		textTransform: "uppercase"
	},
	instructionsBody: {
		paddingTop: theme.spacing(0.5)
	},
	itemName: {
		fontWeight: 500,
		minWidth: 0,
		textTransform: "uppercase",
		color: "#4F4F4F",
		overflowWrap: "break-word"
	},
	itemPriceContainer: {
		flex: 1,
		textAlign: "right"
	},
	itemPrice: {
		fontSize,
		lineHeight
	},
	options: {
		listStyle: "none"
	},
	option: {
		margin: `${theme.spacing(2)}px 0 0 0`,
		padding: 0,
		display: "flex",
		flexDirection: "row",
		alignItems: "baseline",
		justifyContent: "space-between",
		color: theme.palette.text.secondary
	},
	optionName: {
		fontSize,
		lineHeight,
		minWidth: 0,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		textTransform: "uppercase"
	},
	optionPrice: {
		fontSize,
		lineHeight
	},
	total: {
		display: "flex",
		flexDirection: "row",
		alignItems: "baseline",
		justifyContent: "flex-end",
		marginTop: theme.spacing(4)
	},
	itemTotal: {
		fontSize: "16px",
		lineHeight: "19px",
		fontWeight: "bold",
		color: "#4F4F4F"
	}
}));

const OrderItemListItem = (props: Props) => {
	const { className, item, ...otherProps } = props;
	const { t } = useTranslation();
	const { localize } = useLocalize();

	const classes = useStyles(props);

	const { menuItem, selectedMenuItemOptionGroups } = item;

	const options = R.flatten(selectedMenuItemOptionGroups.map(x => x.selectedMenuItemOptions));

	return (
		<MyListItem className={clsx(classes.root, className)} {...otherProps}>
			<Box className={classes.menuItem}>
				<Typography className={classes.itemCode}>x{item.quantity}</Typography>
				<Typography className={classes.itemName}>
					{menuItem.itemCode}. {localize(menuItem.localeNames, menuItem.name)}
				</Typography>
				<Box className={classes.itemPriceContainer}>
					<Price className={classes.itemTotal} priceCents={item.extendedPriceCents} />
					{/* <Price className={classes.itemPrice} priceCents={item.extendedPriceCents} /> */}
				</Box>
			</Box>
			<Box className={classes.menuItemBody}>
				{item.specialInstructions && (
					<Box className={classes.instructions}>
						<Typography className={classes.instructionsHeader} variant="body1">
							{t("specialInstructions")}
						</Typography>
						<Typography className={classes.instructionsBody} variant="body1">
							{item.specialInstructions}
						</Typography>
					</Box>
				)}
				{options.length === 0 ? null : (
					<List className={classes.options}>
						{options.map(option => (
							<ListItem key={option.menuItemOptionId} className={classes.option}>
								<Box className={classes.optionName}>&bull; {localize(option.localeNames, option.name)}</Box>
								<Box>
									<Price
										className={classes.optionPrice}
										priceCents={option.priceCents}
										displayPrice={option.displayPrice}
									/>
								</Box>
							</ListItem>
						))}
					</List>
				)}
			</Box>
		</MyListItem>
	);
};

export default OrderItemListItem;
