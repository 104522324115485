import React, { ContextType, ReactNode, useMemo } from "react";

import BusinessQuery from "api/queries/BusinessQuery";
import useQuery from "hooks/useQuery";

import BusinessContext from "./BusinessContext";

const BusinessContextProvider = ({
	businessId,
	children
}: {
	businessId: undefined | null | string;
	children: ReactNode;
}) => {
	const { loading, data, refetch } = useQuery(BusinessQuery, {
		pollInterval: 20000,
		variables: {
			businessInput: {
				businessId
			}
		}
	});

	const context = useMemo<ContextType<typeof BusinessContext>>(
		() => ({
			loading,
			business: data?.business || null,
			refetch
		}),
		[data]
	);

	return <BusinessContext.Provider value={context}>{children}</BusinessContext.Provider>;
};

export default BusinessContextProvider;
