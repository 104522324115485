export interface MenuAction {
	id: string | number;
	expanded: boolean;
}

export type MenuActionType = { type: "EXPAND"; id: string | number; expanded: boolean } | { type: "INIT" };

export const menuActionsReducer = (menuActions: MenuAction[], action: MenuActionType): MenuAction[] => {
	switch (action.type) {
		case "EXPAND":
			let exists = false;
			const actions = menuActions?.map(menuAction => {
				if (menuAction.id === action.id) {
					exists = true;
					return { ...menuAction, expanded: action.expanded };
				}

				return { ...menuAction };
			});

			if (!exists) return [...actions, { id: action.id, expanded: action.expanded }];
			return actions;
			break;
		case "INIT":
			return [];
			break;
		default:
			return menuActions;
	}
};
