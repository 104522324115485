import { gql } from "@apollo/client";

import IOperation from "api/IOperation";
import {MutationAddMenuGroupInput, MutationUpdateMenusResult} from "gql/types/mutations/MenusUpdate";

const text = gql`mutation AddMenuGroup($addMenuGroupInput: MutationAddMenuGroupInput!) {
	addMenuGroup(addMenuGroupInput: $addMenuGroupInput) {
        success
    }
}
`;

const AddMenuGroup: IOperation<MutationUpdateMenusResult, MutationAddMenuGroupInput> = { text } as any;

export default AddMenuGroup;